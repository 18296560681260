import React from 'react';
import { Skeleton } from '@mui/material';

export const SkeletonEarningsNotPaid = () => {
  return (
    <Skeleton
      variant='rectangular'
      sx={{
        bgcolor: 'grey.900',
        width: '100%',
        borderRadius: '.4rem',
        height: 70,
      }}
    />
  );
};

export const SkeletonEmptyActivity = () => {
  return (
    <Skeleton
      variant='rectangular'
      sx={{
        bgcolor: 'grey.900',
        width: '100%',
        borderRadius: '.4rem',
        height: 110,
      }}
    />
  );
};

export const SkeletonMPAccount = () => {
  return (
    <div className='mx-2 my-3'>
      <Skeleton
        variant='rectangular'
        sx={{
          bgcolor: 'grey.900',
          width: '100%',
          borderRadius: '.4rem',
          height: 42,
        }}
      />
    </div>
  );
};
