import React from 'react';

const NotificationsActiveIcon = ({ size = '25px', color = 'var(--color-primary)' }) => {
  return (
    <svg
      id='b'
      data-name='Capa 2'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 31.25 33.91'
      width={size}
      height={size}
    >
      <g id='c' data-name='Capa 1'>
        <g>
          <path
            d='M31.12,23.63c-.48-1.79-1.1-2.82-1.65-3.73-.69-1.14-1.24-2.04-1.24-4.28,0-3.56-.66-6.64-1.96-9.14C24.15,2.42,20.18,0,15.63,0S7.1,2.42,4.99,6.48c-1.3,2.5-1.96,5.58-1.96,9.14,0,2.24-.55,3.14-1.24,4.28-.55.91-1.18,1.94-1.65,3.73-.3,1.14-.06,2.32.65,3.25.68.88,1.68,1.38,2.75,1.38h24.2c1.07,0,2.07-.5,2.75-1.38.72-.93.95-2.12.65-3.25Z'
            fill={color}
            strokeWidth='0'
          />
          <path
            d='M9.74,30.34h11.77c-1.06,2.11-3.3,3.57-5.88,3.57s-4.82-1.46-5.89-3.57Z'
            fill={color}
            strokeWidth='0'
          />
        </g>
      </g>
    </svg>
  );
};

export default NotificationsActiveIcon;
