import Select, { components } from 'react-select';
import React from 'react';
import SolidChevron from '../../icons/SolidChevron';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const SummarySelector = ({
  toggleCalendarVisibility,
  enableSelect,
  selectDisabled,
  isToday,
  selectedDate,
  locale,
}) => {
  const { t } = useTranslation();
  const kycStatus = useSelector((state) => state.associates.user.kycStatus);

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props} className='mr-3 p-0'>
        <SolidChevron size={15} color='var(--color-primary)' />
      </components.DropdownIndicator>
    );
  };

  const getPlaceholder = () => {
    if (isToday) {
      return <div>{t('today')}</div>;
    } else {
      return format(new Date(selectedDate), 'dd MMM', { locale: locale });
    }
  };

  return (
    <div
      className={`mr-0 pr-0 ${kycStatus !== 'APPROVED' ? 'd-none' : ''}`}
      style={{ position: 'absolute', top: 0, right: 0 }}
    >
      <Select
        className='hide-indicator-separator'
        components={{ DropdownIndicator }}
        styles={{
          control: (provided) => ({
            ...provided,
            border: 'none',
            outline: 0,
            background: 'transparent',
            boxShadow: 'none',
            color: '#000',
            minWidth: 90,
          }),
          menu: (provided, state) => ({
            ...provided,
            background: state.menuIsOpen && 'var(--bg-primary)',
            marginTop: 0,
            minWidth: state.menuIsOpen && 150,
          }),
          option: (provided, state) => ({
            ...provided,
            background: state.isSelected ? 'none' : '',
            color: '#000',
          }),
          singleValue: (provided) => ({
            ...provided,
            color: '#fff',
            padding: 0,
            fontWeight: 'bold',
          }),
          valueContainer: (provided) => ({
            ...provided,
            padding: '0 3px',
            color: 'var(--color-primary)',
          }),
          placeholder: (provided) => ({
            ...provided,
            fontWeight: 'bold',
            color: '#fff',
            textAlign: 'right',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'end',
          }),
        }}
        onMenuOpen={toggleCalendarVisibility}
        onMenuClose={enableSelect}
        isDisabled={selectDisabled}
        placeholder={getPlaceholder()}
        isSearchable={false}
      />
    </div>
  );
};

export default SummarySelector;
