import React, { useContext, useEffect, useState } from 'react';
import Header from '../../components/Common/Header/Header';
import ContentApp from '../../components/ContentApp';
import { useSelector } from 'react-redux';
import { WelletContext } from '../../context/WelletContext';
import { CircleSpinner } from 'react-spinners-kit';
import styles from './SignUp.module.css';
import { getQueryString } from '../../helpers/url';
import { useTranslation } from 'react-i18next';
import ErrorBox from '../../components/Common/ErrorBox/ErrorBox';

const TermsAndConditions = () => {
  const { t } = useTranslation();
  const app = useSelector((state) => state.app);
  const [loading, setLoading] = useState(true);
  const welletContext = useContext(WelletContext);
  const language = useSelector((state) => state.app.language);
  const [termsAndConditions, setTermsAndConditions] = useState('');
  const marketId = getQueryString('marketId');
  const [errorMessage, setErrorMessage] = useState(null);

  const getTermsAndConditions = async () => {
    try {
      const response = await welletContext.apis.tickets.get(
        `/market/${marketId || app.markets[0].marketSetupId}/policy/term?language=${language}`,
      );
      setTermsAndConditions(response.data);
    } catch (error) {
      setErrorMessage(t('callApiError'));
    }
    setLoading(false);
  };

  useEffect(() => {
    getTermsAndConditions();
  }, []);

  return (
    <>
      <Header />
      <ContentApp>
        <div className='text-secondary mt-3'>
          <div className='mt-3'>{errorMessage && <ErrorBox title={errorMessage} />}</div>

          {loading ? (
            <div className='loaderDiv'>
              <CircleSpinner
                className='spinner-app mx-auto'
                style={{ margin: 'auto' }}
                size={40}
                color='var(--color-font-primary)'
              />
            </div>
          ) : (
            <div
              className={`${styles.terms} mt-2`}
              dangerouslySetInnerHTML={{ __html: termsAndConditions }}
            />
          )}
        </div>
      </ContentApp>
    </>
  );
};
export default TermsAndConditions;
