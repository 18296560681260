import { Skeleton } from '@mui/material';

export const SkeletonCashierActivity = () => {
  return (
    <>
      <div className='mt-2'>
        <Skeleton
          variant='rectangular'
          sx={{
            bgcolor: 'grey.900',
            width: '100%',
            borderRadius: '.4rem',
            height: 179,
          }}
        />
      </div>
      <div className='mt-2'>
        <Skeleton
          variant='rectangular'
          sx={{
            bgcolor: 'grey.900',
            width: '100%',
            borderRadius: '.4rem',
            height: 485,
          }}
        />
      </div>
    </>
  );
};
