import React from 'react';

const ErrorIcon = ({ size = '135', color = 'var(--color-primary)' }) => {
  return (
    <svg width={size} height={size} viewBox='0 0 115 106' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M113.231 89.6505L66.7044 5.55589C64.7845 2.07982 61.2648 0 57.2943 0C53.3237 0 49.804 2.07982 47.8842 5.55589L1.35727 89.6505C-0.504391 93.0247 -0.446214 97.0098 1.50271 100.326C3.45163 103.642 6.9277 105.62 10.7674 105.62H103.807C107.661 105.62 111.122 103.642 113.071 100.326C115.02 97.0098 115.078 93.0247 113.217 89.6505H113.231ZM60.3776 89.5923C59.5632 90.4068 58.4433 90.8722 57.2943 90.8722C57.0034 90.8722 56.727 90.8431 56.4362 90.7849C56.1598 90.7267 55.8835 90.6395 55.6217 90.5377C55.3599 90.4213 55.1126 90.2904 54.8654 90.1304C54.6327 89.9704 54.4145 89.7959 54.2109 89.5923C53.3964 88.7778 52.931 87.6579 52.931 86.5089C52.931 86.218 52.9601 85.9271 53.0183 85.6508C53.0765 85.3745 53.1492 85.0981 53.2655 84.8363C53.3673 84.5745 53.5128 84.3127 53.6728 84.08C53.8182 83.8473 54.0073 83.6146 54.2109 83.4255C54.4145 83.2219 54.6327 83.0329 54.8654 82.8729C55.1126 82.7129 55.3599 82.582 55.6217 82.4802C55.8835 82.3638 56.1598 82.2766 56.4362 82.2329C57.847 81.942 59.3595 82.4074 60.3776 83.4255C60.5813 83.6146 60.7558 83.8473 60.9158 84.08C61.0758 84.3127 61.2067 84.5745 61.323 84.8363C61.4394 85.0981 61.5121 85.3745 61.5703 85.6508C61.6284 85.9271 61.6575 86.218 61.6575 86.5089C61.6575 87.6579 61.1921 88.7778 60.3776 89.5923ZM61.6575 71.5575C61.6575 73.9718 59.7086 75.9207 57.2943 75.9207C54.8799 75.9207 52.931 73.9718 52.931 71.5575V30.6446C52.931 28.2303 54.8799 26.2814 57.2943 26.2814C59.7086 26.2814 61.6575 28.2303 61.6575 30.6446V71.5575Z'
        fill={color}
      />
    </svg>
  );
};

export default ErrorIcon;
