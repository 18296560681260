import React, { useContext, useState } from 'react';
import styles from './notification.module.css';
import { useDispatch } from 'react-redux';
import { markNotificationAsRead } from '../../../actions/associatesActions';
import { WelletContext } from '../../../context/WelletContext';

const Notification = ({ date, title, description, isViewed, id }) => {
  const [viewed, setViewed] = useState(isViewed);
  const dispatch = useDispatch();
  const welletContext = useContext(WelletContext);

  const handleClickNotification = async (id) => {
    if (viewed) {
      return;
    }

    dispatch(markNotificationAsRead(id));
    try {
      await welletContext.apis.tickets.patch(`/api/Notification/${id}`);

      setViewed(!viewed);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div
        onClick={() => handleClickNotification(id)}
        className={`d-flex align-items-start mx-2 ${viewed ? styles.read : styles.unread} ${
          styles.container
        } `}
      >
        <div className='col pr-0'>
          <div className='bold'>{title}</div>
          <div
            className='text-secondary font-small'
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </div>
        <div className={`col-auto font-small ${!viewed ? 'bold' : ''}`}>{date}</div>
      </div>
    </>
  );
};

export default Notification;
