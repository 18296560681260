import React, { useContext, useEffect, useState } from 'react';
import { getQueryString } from '../../helpers/url';
import { WelletContext } from '../../context/WelletContext';
import { SkeletonCashierOperation } from '../../components/Skeletons/SkeletonCashier';
import { useTranslation } from 'react-i18next';
import Header from '../../components/Common/Header/Header';
import Money from '../../components/Common/Money/Money';
import Button from '../../components/Common/Button/Button';
import ContentApp from '../../components/ContentApp';
import SuccessScreen from '../Common/SuccessScreen';
import ProcessingScreen from '../Common/ProcessingScreen';
import ErrorScreen from '../Common/ErrorScreen';

const CashierOperation = ({ history }) => {
  const { t } = useTranslation();
  const token = getQueryString('token');
  const welletContext = useContext(WelletContext);
  const [info, setInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingPayment, setLoadingPayment] = useState(false);
  const [response, setResponse] = useState(null);
  const [profileImage, setProfileImage] = useState(null);
  const [error, setError] = useState(null);

  const paymentMethod = info?.paymentMethod?.toLowerCase();
  let amountToPayText = '';
  let payButtonText = '';
  if (paymentMethod === 'cash') {
    amountToPayText = t('cashier.amountToPayInCash');
    payButtonText = t('cashier.payInCash');
  } else if (paymentMethod === 'certificate') {
    amountToPayText = t('cashier.amountToPayInCertificate');
    payButtonText = t('cashier.payInCertificate');
  }

  const getProfileImage = async (image) => {
    setLoading(true);
    try {
      const response = await welletContext.apis.tickets.get(image, {
        responseType: 'blob',
      });
      const fileReaderInstance = new FileReader();
      fileReaderInstance.readAsDataURL(response.data);
      fileReaderInstance.onload = () => {
        setProfileImage(fileReaderInstance.result);
      };
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const getInformation = async () => {
    try {
      const result = await welletContext.apis.tickets.post('/cashier/ValidateToken', {
        token: window.decodeURIComponent(token),
      });
      setInfo(result.data);
    } catch (error) {
      console.error(error);
      setError(error.response.data);
      setLoading(false);
    }
  };

  const pay = async () => {
    setLoadingPayment(true);
    try {
      const result = await welletContext.apis.tickets.post('/cashier/ConfirmPayment', {
        token: window.decodeURIComponent(token),
      });
      if (result.status === 200) {
        setLoadingPayment(false);
        setResponse(true);
      }
    } catch (error) {
      console.error(error);
    }
    setLoadingPayment(false);
  };

  useEffect(() => {
    if (info) getProfileImage(info.affiliateUserPhoto);
  }, [info]);

  useEffect(() => {
    getInformation();
  }, []);

  const errorScreen = () => {
    let title, description;

    switch (error) {
      case 'INVALID_TOKEN':
        title = t('cashierError.INVALID_TOKEN.title');
        description = t('cashierError.INVALID_TOKEN.description');
        break;
      case 'EXPIRED':
        title = t('cashierError.EXPIRED.title');
        description = t('cashierError.EXPIRED.description');
        break;
      case 'INVALID_AMOUNT':
        title = t('cashierError.INVALID_AMOUNT.title');
        description = t('cashierError.INVALID_AMOUNT.description');
        break;
      case 'AFFILIATE_DISABLED':
        title = t('cashierError.AFFILIATE_DISABLED.title');
        description = t('cashierError.AFFILIATE_DISABLED.description');
        break;
      default:
        title = t('cashierError.DEFAULT.title');
        description = t('cashierError.DEFAULT.description');
    }

    return (
      <ErrorScreen
        title={t('somethingWentWrong')}
        subtitle={description}
        buttonText={t('tryAgain')}
        path={'/cashier-qr-scan'}
      />
    );
  };

  return (
    <div>
      {loading ? (
        <>
          <Header title={t('cashier.confirmPayment')} />
          {SkeletonCashierOperation()}
        </>
      ) : response ? (
        <SuccessScreen
          title={`${t('done')}!`}
          subtitle={t('cashier.successfulPayment')}
          path={'/'}
        />
      ) : loadingPayment ? (
        <ProcessingScreen title={t('wait')} subtitle={t('cashier.proccessingPayment')} />
      ) : (
        <>
          {error !== null ? (
            <>
              <Header pathName='/cashier-qr-scan' title={t('cashier.confirmPayment')} />
              {errorScreen(error)}
            </>
          ) : (
            <>
              <Header title={t('cashier.confirmPayment')} />
              <ContentApp>
                <div className='text-white card text-center p-3 '>
                  <div className='cashier-operation-image'>
                    <img src={profileImage} alt='profile' className=' mt-2' />
                  </div>
                  <div className='font-medium mt-3 mb-4'>{info.affiliateUserName}</div>
                  <div
                    style={{
                      borderBottom: '1px solid #212121',
                      margin: 'auto',
                      width: '20%',
                    }}
                  ></div>
                  <div className='font-normal mt-4'>{amountToPayText}:</div>
                  <div className='font-extra-large bold mt-2'>
                    {info.currency}{' '}
                    <Money value={info.amount} lowDecimals showSymbol={false} decimal={false} />
                  </div>
                  <div>
                    <Button
                      text={
                        <div>
                          {payButtonText} {info.currency}{' '}
                          <Money
                            value={info.amount}
                            lowDecimals
                            showSymbol={false}
                            decimal={false}
                          />
                        </div>
                      }
                      size='large'
                      className='btn-primary mt-5 font-medium '
                      onClick={() => pay()}
                    />
                  </div>
                </div>
              </ContentApp>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default CashierOperation;
