import React from 'react';

const SearchIcon = ({ fillColor = '#fff', size = '32px' }) => (
  <svg
    version='1.0'
    xmlns='http://www.w3.org/2000/svg'
    width={size}
    height={size}
    viewBox='0 0 512.000000 512.000000'
    preserveAspectRatio='xMidYMid meet'
  >
    <g
      transform='translate(0.000000,512.000000) scale(0.100000,-0.100000)'
      fill={fillColor}
      stroke='none'
    >
      <path
        d='M2010 4473 c-612 -65 -1128 -491 -1304 -1075 -49 -163 -61 -249 -60
      -448 1 -199 15 -299 69 -466 37 -115 133 -305 206 -409 139 -196 374 -393 590
      -494 299 -140 636 -180 959 -115 170 35 389 125 522 215 31 22 61 39 65 39 4
      0 251 -243 548 -541 l540 -540 167 173 167 173 -541 542 -541 541 53 83 c93
      146 164 322 206 519 29 130 26 458 -4 593 -144 647 -649 1118 -1290 1202 -103
      14 -265 17 -352 8z m395 -338 c528 -115 904 -538 955 -1071 58 -618 -377
      -1183 -995 -1291 -133 -23 -368 -14 -495 20 -346 91 -630 322 -785 641 -357
      732 107 1602 917 1716 87 12 319 4 403 -15z'
      />
    </g>
  </svg>
);

export default SearchIcon;
