import React from 'react';
import './ErrorBox.css';
const ErrorBox = ({ title, description }) => {
  return (
    <div className='error-box'>
      <div className='col-12 card'>
        <div className='row'>
          <div className='col-auto my-auto'>
            <img src='https://cdn1.wellet.fun/images/exclamation-triangle.png' alt='' />
          </div>
          <div className='col pl-0 text-left'>
            <div className='error-title'>{title}</div>
            <p className='error-text' dangerouslySetInnerHTML={{ __html: description }}></p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorBox;
