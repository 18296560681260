import React from 'react';

const HomeActiveIcon = ({ size = '25px', color = 'var(--color-primary)' }) => {
  return (
    <svg
      id='b'
      data-name='Capa 2'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 31.24 31.79'
      width={size}
      height={size}
    >
      <g id='c' data-name='Capa 1'>
        <path
          d='M29.34,9.92L19.03,1.24c-1.98-1.66-4.84-1.66-6.82,0L1.9,9.92c-1.21,1.02-1.9,2.51-1.9,4.1v12.65c0,2.82,2.29,5.12,5.12,5.12h21c2.83,0,5.12-2.3,5.12-5.12v-12.65c0-1.59-.69-3.08-1.9-4.1ZM20.32,26.81h-9.41c-.58,0-1.05-.46-1.05-1.05s.47-1.05,1.05-1.05h9.41c.58,0,1.05.47,1.05,1.05s-.47,1.05-1.05,1.05Z'
          fill={color}
          strokeWidth='0'
        />
      </g>
    </svg>
  );
};

export default HomeActiveIcon;
