import { Skeleton } from '@mui/material';
import styles from './skeleton.module.css';

export const skeletonNotificationConfiguration = () => {
  return (
    <Skeleton
      sx={{ bgcolor: 'grey.900', borderRadius: '5px' }}
      variant='rectangular'
      width={'100%'}
      height={240}
    />
  );
};

export const skeletonNotifications = () => {
  return (
    <div className={`${styles.notificationsContainer}`}>
      <div className='row'>
        <div className='col pr-3'>
          <Skeleton
            sx={{ bgcolor: 'grey.900', borderRadius: '5px' }}
            variant='rectangular'
            width={'80%'}
          />
          <div className='mt-2'>
            <Skeleton
              sx={{ bgcolor: 'grey.900', borderRadius: '5px' }}
              variant='rectangular'
              width={'100%'}
            />
          </div>
          <div className='mt-2'>
            <Skeleton
              sx={{ bgcolor: 'grey.900', borderRadius: '5px' }}
              variant='rectangular'
              width={'100%'}
            />
          </div>
        </div>
        <div className='col-auto pr-3'>
          <Skeleton
            sx={{ bgcolor: 'grey.900', borderRadius: '5px' }}
            variant='circular'
            width={'72px'}
          />
        </div>
      </div>
      <div className='row mt-4'>
        <div className='col pr-3'>
          <Skeleton
            sx={{ bgcolor: 'grey.900', borderRadius: '5px' }}
            variant='rectangular'
            width={'80%'}
          />
          <div className='mt-2'>
            <Skeleton
              sx={{ bgcolor: 'grey.900', borderRadius: '5px' }}
              variant='rectangular'
              width={'100%'}
            />
          </div>
          <div className='mt-2'>
            <Skeleton
              sx={{ bgcolor: 'grey.900', borderRadius: '5px' }}
              variant='rectangular'
              width={'100%'}
            />
          </div>
        </div>
        <div className='col-auto pr-3'>
          <Skeleton
            sx={{ bgcolor: 'grey.900', borderRadius: '5px' }}
            variant='circular'
            width={'72px'}
          />
        </div>
      </div>
      <div className='row mt-4'>
        <div className='col pr-3'>
          <Skeleton
            sx={{ bgcolor: 'grey.900', borderRadius: '5px' }}
            variant='rectangular'
            width={'80%'}
          />
          <div className='mt-2'>
            <Skeleton
              sx={{ bgcolor: 'grey.900', borderRadius: '5px' }}
              variant='rectangular'
              width={'100%'}
            />
          </div>
          <div className='mt-2'>
            <Skeleton
              sx={{ bgcolor: 'grey.900', borderRadius: '5px' }}
              variant='rectangular'
              width={'100%'}
            />
          </div>
        </div>
        <div className='col-auto pr-3'>
          <Skeleton
            sx={{ bgcolor: 'grey.900', borderRadius: '5px' }}
            variant='circular'
            width={'72px'}
          />
        </div>
      </div>
      <div className='row mt-4'>
        <div className='col pr-3'>
          <Skeleton
            sx={{ bgcolor: 'grey.900', borderRadius: '5px' }}
            variant='rectangular'
            width={'80%'}
          />
          <div className='mt-2'>
            <Skeleton
              sx={{ bgcolor: 'grey.900', borderRadius: '5px' }}
              variant='rectangular'
              width={'100%'}
            />
          </div>
          <div className='mt-2'>
            <Skeleton
              sx={{ bgcolor: 'grey.900', borderRadius: '5px' }}
              variant='rectangular'
              width={'100%'}
            />
          </div>
        </div>
        <div className='col-auto pr-3'>
          <Skeleton
            sx={{ bgcolor: 'grey.900', borderRadius: '5px' }}
            variant='circular'
            width={'72px'}
          />
        </div>
      </div>
      <div className='row mt-4'>
        <div className='col pr-3'>
          <Skeleton
            sx={{ bgcolor: 'grey.900', borderRadius: '5px' }}
            variant='rectangular'
            width={'80%'}
          />
          <div className='mt-2'>
            <Skeleton
              sx={{ bgcolor: 'grey.900', borderRadius: '5px' }}
              variant='rectangular'
              width={'100%'}
            />
          </div>
          <div className='mt-2'>
            <Skeleton
              sx={{ bgcolor: 'grey.900', borderRadius: '5px' }}
              variant='rectangular'
              width={'100%'}
            />
          </div>
        </div>
        <div className='col-auto pr-3'>
          <Skeleton
            sx={{ bgcolor: 'grey.900', borderRadius: '5px' }}
            variant='circular'
            width={'72px'}
          />
        </div>
      </div>
    </div>
  );
};
