import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Accordion from '../../../components/Common/Accordion/Accordion';
import { skeletonFrequentQuestions } from '../../../components/Skeletons/SkeletonFrequentQuestions';
import { useSelector } from 'react-redux';
import ContentApp from '../../../components/ContentApp';
import Header from '../../../components/Common/Header/Header';
import { WelletContext } from '../../../context/WelletContext';

const FrequentQuestions = () => {
  const language = useSelector((state) => state.app.language);

  const { t } = useTranslation();
  const welletContext = useContext(WelletContext);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  const getFrequentQuestions = async () => {
    try {
      const response = await welletContext.apis.tickets.get(`/faq?lang=${language}`);
      setData(response.data);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getFrequentQuestions();
  }, []);

  return (
    <>
      <Header title={t('menu.frequentQuestions')} />
      {loading ? (
        <div className='px-3'>{skeletonFrequentQuestions()}</div>
      ) : (
        <>
          <ContentApp>
            <div className='text-center card p-3'>
              <div className='bold'>{t('frequentQuestions.title')}</div>
              <div className='text-secondary mt-2'>{t('frequentQuestions.description')}</div>
            </div>
            {data.map((item, index) => (
              <div className='card mt-2 pr-2' key={index}>
                <Accordion
                  key={item.id}
                  title={item.title}
                  content={item.description}
                  videoUrl={item.videoUrl}
                />
              </div>
            ))}
          </ContentApp>
        </>
      )}
    </>
  );
};

export default FrequentQuestions;
