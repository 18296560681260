import Select, { components } from 'react-select';
import SolidChevron from '../../icons/SolidChevron';
import React from 'react';

const RestaurantSelector = ({
  restaurants,
  selectedRestaurant,
  handleRestaurantChange,
  selectStyles,
  selectClassName,
}) => {
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props} className='mr-1 p-0'>
        <SolidChevron size={15} color='var(--color-primary)' />
      </components.DropdownIndicator>
    );
  };
  return (
    <Select
      className={`${selectClassName} row`}
      classNamePrefix={selectClassName}
      components={{ DropdownIndicator }}
      options={restaurants}
      placeholder={selectedRestaurant ? selectedRestaurant.name : 'Selecciona un restaurante'}
      value={selectedRestaurant}
      onChange={(selectedRestaurant) => handleRestaurantChange(selectedRestaurant)}
      isSearchable={false}
      formatOptionLabel={(r) => (
        <div className='option-inner d-flex'>
          <span>{r.name}</span>
        </div>
      )}
      styles={selectStyles}
    />
  );
};

export default RestaurantSelector;
