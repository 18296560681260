import React, { useContext, useState } from 'react';
import Header from '../../../components/Common/Header/Header';
import ContentApp from '../../../components/ContentApp';
import ButtonProccessing from '../../../components/Common/ButtonProccessing/ButtonProccessing';
import InputFormNew from '../../../components/Common/InputFormNew/InputFormNew';
import { useTranslation } from 'react-i18next';
import useForm from '../../../helpers/useForm';
import { WelletContext } from '../../../context/WelletContext';
import { getPaymentMethod } from '../../../actions/associatesActions';
import { useDispatch } from 'react-redux';
import Select from 'react-select';
import './LinkBankNational.css';

const selectStyles = {
  control: (provided) => ({
    ...provided,
    border: 'none',
    outline: 0,
    backgroundColor: 'var(--color-dark-gray)',
    boxShadow: 'none',
    color: '#000',
    padding: '.25rem .5rem',
  }),
  menu: (provided, state) => ({
    ...provided,
    background: '#fff',
    marginTop: 0,
    minWidth: state.menuIsOpen && 150,
    zIndex: 99999,
    border: '1px solid #fff',
  }),
  option: (provided) => ({
    ...provided,
    background: '#fff',
    color: '#000',
  }),
  singleValue: (provided) => ({
    ...provided,
    color: '#fff',
    padding: 0,
    fontWeight: 'bold',
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '0 3px',
    color: 'var(--color-primary)',
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#fff',
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
  }),
};

const LinkBankNational = ({ history }) => {
  const ButtonStatus = {
    Normal: 'NORMAL',
    InProgress: 'INPROGRESS',
    ProgressFinished: 'FINISHED',
    Error: 'ERROR',
  };
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const welletContext = useContext(WelletContext);
  const [btnStatus, setBtnStatus] = useState(ButtonStatus.Normal);
  const [selectedBank, setSelectedBank] = useState(null);
  const [selectedAccountType, setSelectedAccountType] = useState(null);

  function validate(values) {
    let errors = {};
    const requiredFields = ['customerName', 'accountNumber', 'clabe', 'accountType', 'bank'];

    requiredFields.forEach((field) => {
      if (!values[field]) {
        errors[field] = `${t(`bankForm.${field}`)} ${t('bankForm.isRequired')}`;
      }
    });

    return errors;
  }

  const sendForm = async () => {
    setBtnStatus(ButtonStatus.InProgress);
    try {
      await welletContext.apis.tickets.post('/withdrawal/AddWithdrawal', {
        WithdrawalMethodType: 'WIRE_MEXICO',
        MexicoTransfer: {
          RecipientFullName: values.customerName,
          TypeAccount: selectedAccountType.value,
          AccountNumber: values.accountNumber,
          Bank: selectedBank.value,
          CLABE: values.clabe,
        },
      });
      const paymentMethod = await getPaymentMethod(welletContext);
      dispatch({
        type: 'USER_WITHDRAWAL',
        payload: paymentMethod.data,
      });
      setBtnStatus(ButtonStatus.ProgressFinished);
      setTimeout(() => {
        history.push('/my-money');
      }, 2000);
    } catch (error) {
      console.error(error);
    }
  };

  const { values, errors, handleChange, handleSubmit } = useForm(sendForm, validate);

  const change = (e) => {
    handleChange(e);
  };

  const handleBankChange = (selectedOption) => {
    setSelectedBank(selectedOption);
    handleChange(null, `bank`, selectedOption);
  };

  const handleAccountTypeChange = (selectedOption) => {
    setSelectedAccountType(selectedOption);
    handleChange(null, `accountType`, selectedOption);
  };

  // Array solo con los nombres de los bancos
  const banks = [
    'Banco Actinver',
    'Banco Afirme',
    'Banco Ahorro Famsa',
    'Banco Autofin México',
    'Banco Azteca',
    'Banco BanCoppel',
    'Banco Bancrea',
    'Banco Base',
    'Banco CIBanco',
    'Banco Compartamos',
    'Banco del Bajío (BanBajío)',
    'Banco Financiero Inbursa',
    'Banco Forjadores',
    'Banco Inbursa',
    'Banco Interacciones',
    'Banco Intercam',
    'Banco Invex',
    'Banco Mercantil del Norte (Banorte)',
    'Banco Monex',
    'Banco Multiva',
    'Banco Nacional de México (Banamex)',
    'Banco Regional de Monterrey (BanRegio)',
    'Banco Sabadell México',
    'Banco Santander México',
    'Banco Ve Por Más (BX+)',
    'Banco Wal-Mart de México Adelante',
    'Bankaool',
    'BBVA México',
    'HSBC México',
    'Scotiabank México',
  ];

  const accountType = ['Cuenta de cheque', 'Cuenta de ahorros'];

  return (
    <>
      <Header title={t('bankForm.header')} />
      <ContentApp>
        <div className='font-normal bold text-white mt-2'>{t('WIRE.linkAccountTitle')}</div>
        <p className='mt-2 text-secondary font-tiny'>{t('WIRE.linkAccountDescription')}</p>
        <div className='link-mp-account-form mt-2'>
          <div className='text-white bold mt-3'>{t('bankForm.customerDetails')}</div>
          <form className='mt-3'>
            <InputFormNew
              name='customerName'
              type='text'
              placeholder={t('bankForm.customerName')}
              onChange={change}
              value={values.customerName}
              error={errors.customerName}
            />
            <div
              className='text-secondary bold mb-2'
              style={{ marginTop: `${errors.customerName ? '3rem' : '2rem'}` }}
            >
              {t('bankForm.accountType')}
            </div>
            <Select
              className='hide-indicator-separator'
              options={accountType.map((type) => ({ value: type, label: type }))}
              placeholder={t('bankForm.selectType')}
              styles={selectStyles}
              onChange={handleAccountTypeChange}
              value={selectedAccountType}
              isSearchable={false}
            />
            {errors.accountType && (
              <div className='error-label text-white' style={{ width: '92%' }}>
                {errors['accountType']}
              </div>
            )}
            <InputFormNew
              style={{ marginTop: `${errors.accountType ? '4rem' : '2rem'}` }}
              name='accountNumber'
              type='text'
              placeholder={t('bankForm.accountNumber')}
              onChange={change}
              value={values.accountNumber}
              error={errors.accountNumber}
            />
            <InputFormNew
              style={{ marginTop: `${errors.accountNumber ? '4rem' : '2rem'}` }}
              name='clabe'
              type='number'
              placeholder='CLABE'
              onChange={change}
              value={values.clabe}
              error={errors.clabe}
              maxLength={18}
            />

            <div className={`text-secondary bold mb-2 ${errors.clabe ? 'mt-5' : 'mt-4'}`}>
              {t('bankForm.bank')}
            </div>
            <Select
              className='hide-indicator-separator'
              options={banks.map((bank) => ({ value: bank, label: bank }))}
              placeholder={t('bankForm.selectBank')}
              styles={selectStyles}
              onChange={handleBankChange}
              value={selectedBank}
              isSearchable={false}
              menuPlacement='top'
            />
            {errors?.bank && (
              <div className='error-label text-white' style={{ width: '92%' }}>
                {errors['bank']}
              </div>
            )}
          </form>
        </div>
        <div className='d-flex justify-content-center'>
          <ButtonProccessing
            status={btnStatus}
            size='large'
            text={t('bankForm.save')}
            className='mt-5 mb-2'
            type='submit'
            onClick={handleSubmit}
            backgroundColor='var(--color-primary)'
            color='#000'
          />
        </div>
      </ContentApp>
    </>
  );
};

export default LinkBankNational;
