import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { WelletContext } from '../../../context/WelletContext';
import { InputSingleCharacter } from '../../../components/Common/InputSingleCharacter/InputSingleCharacter';
import { useDispatch, useSelector } from 'react-redux';
import { getAuth, signInWithCustomToken } from 'firebase/auth';
import { getQueryString } from '../../../helpers/url';
import { exchangeToken } from '../../../actions/associatesActions';
import Header from '../../../components/Common/Header/Header';
import ContentApp from '../../../components/ContentApp';
import Button from '../../../components/Common/Button/Button';
import ErrorBox from '../../../components/Common/ErrorBox/ErrorBox';
import { Spinner } from '../../../components/Common/Spinner/Spinner';
import { useTranslation } from 'react-i18next';

const ForgotPassword = () => {
  const welletContext = useContext(WelletContext);
  const { t } = useTranslation();
  const codeLength = 6;
  const [sendingCode, setSendingCode] = useState(false);
  const [btnVerifyDisable, setBtnVerifyDisable] = useState(true);
  const [verifyingCode, setVerifyingCode] = useState(false);
  const [code, setCode] = useState('');
  const [errorCode, setErrorCode] = useState('');
  const auth = getAuth();
  const history = useHistory();
  const email = getQueryString('email');
  const dispatch = useDispatch();
  const tenant = useSelector((state) => state.app.tenantId);
  const [secondsRemaining, setSecondsRemaining] = useState(0);

  const onVerifyHandle = (user) => {
    exchangeToken(dispatch, user, () => history.push('/'));
  };

  const requestCodeVerification = async () => {
    if (secondsRemaining > 0) {
      return;
    }
    setSendingCode(true);
    try {
      await welletContext.apis.tickets.post(`/users/forgotpassword`, {
        email: email,
        tenantId: tenant,
      });
      startTimer();
      setSendingCode(false);
    } catch (error) {
      console.error(error);
      setSendingCode(false);
    }
  };

  const sendCodeVerification = async (code) => {
    setVerifyingCode(true);
    try {
      const result = await welletContext.apis.tickets.post(`/users/forgotpassword/validate`, {
        email: email,
        tenantId: tenant,
        code: code,
      });

      if (result.data.isValid) {
        const userCredentials = await signInWithCustomToken(auth, result.data.customToken);
        onVerifyHandle(userCredentials.user);
      } else {
        setErrorCode(t('signUp.codeError'));
        setVerifyingCode(false);
      }
    } catch (error) {
      console.error(error);
      setVerifyingCode(false);
    }
  };

  useEffect(() => {
    requestCodeVerification();
    startTimer();
  }, []);

  useEffect(() => {
    if (code.length === codeLength) setBtnVerifyDisable(false);
    else setBtnVerifyDisable(true);
  }, [code]);

  const startTimer = () => {
    setSecondsRemaining(120);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (secondsRemaining > 0) {
        setSecondsRemaining(secondsRemaining - 1);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [secondsRemaining]);

  const verifyCode = () => {
    sendCodeVerification(code);
  };

  const onCodeChange = (value) => {
    setCode(value);
    setErrorCode('');
  };

  return (
    <>
      <Header title={''} pathName={'/login-password'} pathSearch={'?email=' + email} />
      <ContentApp>
        <div className=''>
          <div className='font-medium bold' style={{ color: 'var(--color-font-primary)' }}>
            Ingresá el código que te enviamos por e-mail
          </div>
          <div className='mt-2 subtitle' style={{ color: 'var(--color-font-gray)' }}>
            Te enviamos un código a tu e-mail para que puedas ingresar a tu cuenta. Si no lo
            encuentras revisá el correo no deseado.
          </div>
          <div className='font-small mt-2' style={{ color: 'var(--color-font-gray)' }}>
            {email}
          </div>
          <div className='mt-5 input-character'>
            <InputSingleCharacter
              length={codeLength}
              onValueChange={(result) => onCodeChange(result)}
            />
          </div>

          {errorCode && (
            <div className='mt-3'>
              <ErrorBox title={errorCode} />
            </div>
          )}
          <div className='mt-5'>
            <Button
              className={`${btnVerifyDisable ? 'disabled' : ''} btn-primary`}
              onClick={verifyCode}
              size={'large'}
              fontSize={'18px'}
              text={
                verifyingCode ? (
                  <div className='d-flex justify-content-center'>
                    <Spinner size={25} color='#000' />
                  </div>
                ) : (
                  <>Continuar</>
                )
              }
              icon={verifyingCode ? false : true}
              typeIcon='arrow'
              iconColor='#000'
            />

            <Button
              className={`mt-2 ${secondsRemaining > 0 ? 'disabled' : ''}`}
              fontSize={'18px'}
              size={'large'}
              text={
                sendingCode ? (
                  <div className='d-flex justify-content-center'>
                    <Spinner size={25} color='#fff' />
                  </div>
                ) : (
                  <>
                    {secondsRemaining > 0
                      ? `Reenviar código en ${Math.floor(secondsRemaining / 60)}:${(
                          secondsRemaining % 60
                        )
                          .toString()
                          .padStart(2, '0')}`
                      : 'Reenviar código'}
                  </>
                )
              }
              disabled={secondsRemaining > 0}
              onClick={() => requestCodeVerification()}
            />
          </div>
        </div>
      </ContentApp>
    </>
  );
};

export default ForgotPassword;
