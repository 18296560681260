import { useHistory } from 'react-router-dom';
import Header from '../../components/Common/Header/Header';
import ContentApp from '../../components/ContentApp';
import { QrReader } from '../../components/Common/QrReader/QrReader';
import { useTranslation } from 'react-i18next';
const QRReaderScreen = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const qrReadeHandle = (token) => {
    history.push({
      pathname: '/cashier-operation',
      search: `?token=${token}`,
    });
  };

  return (
    <div className='qr-screen w-100'>
      <Header title={t('pay')} pathName={'/'} />
      <ContentApp>
        <QrReader onQRRead={(msg) => qrReadeHandle(msg)} />
      </ContentApp>
    </div>
  );
};

export default QRReaderScreen;
