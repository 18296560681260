import React, { useContext, useState } from 'react';
import Header from '../../../components/Common/Header/Header';
import ContentApp from '../../../components/ContentApp';
import ButtonProccessing from '../../../components/Common/ButtonProccessing/ButtonProccessing';
import InputFormNew from '../../../components/Common/InputFormNew/InputFormNew';
import { useTranslation } from 'react-i18next';
import useForm from '../../../helpers/useForm';
import { WelletContext } from '../../../context/WelletContext';
import { getPaymentMethod } from '../../../actions/associatesActions';
import { useDispatch } from 'react-redux';
import Select from 'react-select';

const selectStyles = {
  control: (provided) => ({
    ...provided,
    border: 'none',
    outline: 0,
    backgroundColor: 'var(--color-dark-gray)',
    boxShadow: 'none',
    color: '#000',
    padding: '.25rem .5rem',
  }),
  menu: (provided, state) => ({
    ...provided,
    background: '#fff',
    marginTop: 0,
    minWidth: state.menuIsOpen && 150,
    zIndex: 99999,
    border: '1px solid #fff',
  }),
  option: (provided) => ({
    ...provided,
    background: '#fff',
    color: '#000',
  }),
  singleValue: (provided) => ({
    ...provided,
    color: '#fff',
    padding: 0,
    fontWeight: 'bold',
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '0 3px',
    color: 'var(--color-primary)',
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#fff',
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
  }),
};

const LinkBankInternational = ({ history }) => {
  const ButtonStatus = {
    Normal: 'NORMAL',
    InProgress: 'INPROGRESS',
    ProgressFinished: 'FINISHED',
    Error: 'ERROR',
  };
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const welletContext = useContext(WelletContext);
  const [btnStatus, setBtnStatus] = useState(ButtonStatus.Normal);
  const [selectedAccountType, setSelectedAccountType] = useState(null);
  const [selectedCurrency, setSelectedCurrency] = useState(null);

  function validate(values) {
    let errors = {};
    const requiredCustomerFields = [
      'customerName',
      'accountType',
      'accountNumber',
      'address',
      'country',
      'currency',
    ];
    const requiredBankFields = ['bank', 'bankAddress', 'swift', 'aba'];

    requiredCustomerFields.forEach((field) => {
      if (!values[field]) {
        errors[field] = `${t(`bankForm.${field}`)} ${t('bankForm.isRequired')}`;
      }
    });

    requiredBankFields.forEach((field) => {
      if (!values[field]) {
        errors[field] = `${t(`bankForm.${field}`)} ${t('bankForm.isRequired')}`;
      }
    });

    return errors;
  }

  const sendForm = async () => {
    setBtnStatus(ButtonStatus.InProgress);

    try {
      await welletContext.apis.tickets.post('/withdrawal/AddWithdrawal', {
        WithdrawalMethodType: 'WIRE_INTERNATIONAL',
        InternationalTransfer: {
          RecipientAddress: values.address,
          RecipientFullName: values.customerName,
          TypeAccount: values.accountType.label,
          Country: values.country,
          AccountNumber: values.accountNumber,
          SWIFT: values.swift,
          ABARoutingNumber: values.aba,
          Bank: values.bank,
          BankAddress: values.bankAddress,
          Currency: values.currency.value,
        },
      });
      const paymentMethod = await getPaymentMethod(welletContext);
      dispatch({
        type: 'USER_WITHDRAWAL',
        payload: paymentMethod.data,
      });
      setBtnStatus(ButtonStatus.ProgressFinished);
      setTimeout(() => {
        history.push('/my-money');
      }, 2000);
    } catch (error) {
      console.error(error);
    }
  };

  const { values, errors, handleChange, handleSubmit } = useForm(sendForm, validate);

  const change = (e) => {
    handleChange(e);
  };

  const accountType = ['Cuenta de cheque', 'Cuenta de ahorros'];

  const currency = ['USD', 'EUR'];

  const handleAccountTypeChange = (selectedOption) => {
    setSelectedAccountType(selectedOption);
    handleChange(null, `accountType`, selectedOption);
  };

  const handleSelectedCurrency = (selectedOption) => {
    setSelectedCurrency(selectedOption);
    handleChange(null, 'currency', selectedOption);
  };

  const customerDetails = (
    <>
      <div className='text-white mb-4 bold font-small'>{t('bankForm.customerDetails')}</div>
      <InputFormNew
        name='customerName'
        type='text'
        placeholder={t('bankForm.customerName')}
        onChange={change}
        value={values.customerName}
        error={errors.customerName}
      />
      <div
        className='text-secondary bold mb-2'
        style={{ marginTop: `${errors.customerName ? '3rem' : '2rem'}` }}
      >
        {t('bankForm.accountType')}
      </div>
      <Select
        className='hide-indicator-separator'
        options={accountType.map((type) => ({ value: type, label: type }))}
        placeholder={t('bankForm.selectType')}
        styles={selectStyles}
        onChange={handleAccountTypeChange}
        value={selectedAccountType}
      />
      {errors.accountType && (
        <div className='error-label text-white' style={{ width: '92%' }}>
          {errors['accountType']}
        </div>
      )}
      <InputFormNew
        style={{ marginTop: `${errors.accountType ? '4rem' : '2rem'}` }}
        name='accountNumber'
        type='text'
        placeholder={t('bankForm.accountNumber')}
        onChange={change}
        value={values.accountNumber}
        error={errors.accountNumber}
      />
      <div
        className='text-secondary bold mb-2'
        style={{ marginTop: `${errors.accountNumber ? '4rem' : '2rem'}` }}
      >
        {t('bankForm.currency')}
      </div>
      <Select
        className='hide-indicator-separator'
        options={currency.map((type) => ({ value: type, label: type }))}
        placeholder={t('bankForm.selectCurrency')}
        styles={selectStyles}
        onChange={handleSelectedCurrency}
        value={selectedCurrency}
      />
      {errors.currency && (
        <div className='error-label text-white' style={{ width: '92%' }}>
          {errors['currency']}
        </div>
      )}
      <InputFormNew
        style={{ marginTop: `${errors.currency ? '4rem' : '2rem'}` }}
        name='address'
        type='text'
        placeholder={t('bankForm.address')}
        onChange={change}
        value={values.address}
        error={errors.address}
      />
      <InputFormNew
        style={{ marginTop: `${errors.address ? '4rem' : '2rem'}` }}
        name='country'
        type='text'
        placeholder={t('bankForm.country')}
        onChange={change}
        value={values.country}
        error={errors.country}
      />
    </>
  );

  const bankDetails = (
    <>
      <div className='text-white bold mt-5 mb-2'>{t('bankForm.bankDetails')}</div>
      <InputFormNew
        style={{ marginTop: '2rem' }}
        name='bank'
        type='text'
        placeholder={t('bankForm.bank')}
        onChange={change}
        value={values.bank}
        error={errors.bank}
      />
      <InputFormNew
        style={{ marginTop: `${errors.bank ? '4rem' : '2rem'}` }}
        name='bankAddress'
        type='text'
        placeholder={t('bankForm.bankAddress')}
        onChange={change}
        value={values.bankAddress}
        error={errors.bankAddress}
      />
      <InputFormNew
        style={{ marginTop: `${errors.bankAddress ? '4rem' : '2rem'}` }}
        name='swift'
        type='text'
        placeholder='SWIFT'
        onChange={change}
        value={values.swift}
        error={errors.swift}
      />
      <InputFormNew
        style={{ marginTop: `${errors.swift ? '4rem' : '2rem'}` }}
        name='aba'
        type='text'
        placeholder='ABA Routing Number'
        onChange={change}
        value={values.aba}
        error={errors.aba}
      />
    </>
  );

  return (
    <>
      <Header title={t('bankForm.header')} />
      <ContentApp>
        <div className='font-normal bold text-white mt-2'>{t('bankForm.linkBankAccount')}</div>
        <p className='mt-2 text-secondary font-tiny'>{t('WIRE.linkAccountDescription')}:</p>
        <div className='link-mp-account-form mt-4'>
          <form className='mt-2'>
            {customerDetails}
            {bankDetails}
          </form>
        </div>
        <div className='d-flex justify-content-center'>
          <ButtonProccessing
            status={btnStatus}
            size='large'
            text={t('bankForm.save')}
            className='mt-5 mb-3'
            type='submit'
            onClick={handleSubmit}
            backgroundColor='var(--color-primary)'
            color='#000'
          />
        </div>
      </ContentApp>
    </>
  );
};

export default LinkBankInternational;
