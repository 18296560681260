import React from 'react';
import styles from './switch.module.css';

const Switch = ({ isActive, toggleSwitch }) => {
  return (
    <div className={`${styles.switch} ${isActive ? styles.active : ''}`} onClick={toggleSwitch}>
      <div className={styles.circle}></div>
    </div>
  );
};

export default Switch;
