import React from 'react';

const NotificationsInactiveIcon = ({ size = '25px', color = '#fff' }) => {
  return (
    <svg
      id='b'
      data-name='Capa 2'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 31.25 33.9'
      width={size}
      height={size}
    >
      <g id='c' data-name='Capa 1'>
        <g>
          <path
            d='M15.62,27.51h12.1c1.81,0,3.16-1.84,2.67-3.69-1.06-4-2.92-3.97-2.92-8.2,0-3.77-.75-6.63-1.88-8.79C23.49,2.77,19.56.75,15.62.75c-3.93,0-7.87,2.02-9.97,6.07-1.13,2.16-1.88,5.02-1.88,8.79,0,4.24-1.86,4.2-2.92,8.2-.49,1.86.87,3.69,2.67,3.69h12.1Z'
            fill='none'
            stroke={color}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='1.5'
          />
          <path
            d='M21.45,27.51c0,3.11-2.61,5.64-5.82,5.64s-5.82-2.52-5.82-5.64h11.65Z'
            fill='none'
            stroke={color}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='1.5'
          />
        </g>
      </g>
    </svg>
  );
};

export default NotificationsInactiveIcon;
