import React from 'react';

const ReservationSent = ({ size, color }) => {
  return (
    <svg
      version='1.0'
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 135.000000 135.000000'
    >
      <g
        transform='translate(0.000000,135.000000) scale(0.100000,-0.100000)'
        fill={color}
        stroke='none'
      >
        <path
          d='M660 1206 c-61 -18 -115 -41 -150 -66 -25 -18 -43 -20 -193 -20 -151
0 -166 -2 -191 -21 -21 -16 -26 -29 -26 -64 0 -67 29 -85 137 -85 77 0 84 -2
78 -17 -4 -10 -18 -45 -30 -78 l-23 -60 -112 -5 c-102 -5 -113 -7 -131 -29
-26 -32 -24 -74 5 -108 23 -27 29 -28 128 -31 l104 -4 11 -56 c6 -32 14 -65
18 -74 6 -15 1 -18 -29 -18 -43 0 -91 -22 -100 -45 -15 -39 -6 -83 20 -104 23
-18 41 -21 123 -21 l96 0 55 -45 c104 -86 215 -125 358 -125 232 0 434 147
514 371 32 92 32 257 -1 349 -29 83 -99 188 -159 240 -55 48 -146 95 -222 115
-66 18 -218 18 -280 1z m323 -159 c313 -155 313 -589 -1 -744 -73 -37 -80 -38
-181 -38 -98 0 -111 2 -171 31 -174 83 -270 257 -241 439 26 163 126 280 286
338 38 13 73 17 141 14 82 -2 99 -7 167 -40z'
        />
        <path
          d='M685 992 c-99 -42 -155 -96 -197 -189 -31 -68 -31 -189 0 -257 37
-81 85 -131 164 -170 62 -31 80 -36 144 -36 140 0 253 71 311 195 39 84 40
194 2 276 -36 76 -86 129 -156 165 -49 24 -72 29 -143 31 -63 2 -95 -2 -125
-15z m157 -179 l3 -98 58 -3 57 -3 0 -34 0 -35 -100 0 -100 0 0 135 0 135 40
0 39 0 3 -97z'
        />
      </g>
    </svg>
  );
};

export default ReservationSent;
