import React, { useContext, useEffect, useState } from 'react';
import { WelletContext } from '../../context/WelletContext';
import { format, subDays } from 'date-fns';
import { getDateFromOffset, getLocale } from '../../helpers/dates';
import { useSelector } from 'react-redux';
import { SkeletonPayments } from '../../components/Skeletons/SkeletonCashier';
import { useTranslation } from 'react-i18next';
import AccountHeader from '../../components/AccountHeader/AccountHeader';
import ContentApp from '../../components/ContentApp';
import EmptyActivity from '../../components/Common/EmptyActivity/EmptyActivity';
import Button from '../../components/Common/Button/Button';
import Balance from '../../components/Balance/Balance';
import FixedButton from '../../components/Common/FixedButton/FixedButton';
import './Cashier.css';
import CashierItem from './CashierItem';
import ActivitiesHome from '../../components/Activities/ActivitiesHome/ActivitiesHome';

const AccountCashier = ({ history }) => {
  const { t } = useTranslation();
  const welletContext = useContext(WelletContext);
  const user = useSelector((state) => state.associates.user);
  const app = useSelector((state) => state.app);
  const locale = getLocale(app.language);
  const today = new Date(getDateFromOffset(user.companyUtcMinutesOffset));
  const [payments, setPayments] = useState(null);
  const [loadingPayments, setLoadingPayments] = useState(true);
  const [loadingTotal, setLoadingTotal] = useState(true);
  const [total, setTotal] = useState(null);
  const [calendarVisible, setCalendarVisible] = useState(false);
  const [selectedDate, setSelectedDate] = useState(format(today, 'yyyy-MM-dd'));
 
  const last30days = format(
    subDays(new Date(getDateFromOffset(user.companyUtcMinutesOffset)), 30),
    'yyyy-MM-dd',
  );

  const getPayments = async () => {
    setLoadingPayments(true);
    try {
      const balance = await welletContext.apis.tickets.get(
        `/cashier/payments?From=${last30days}&To=${format(today, 'yyyy-MM-dd', {
          locale,
        })}&skip=0&limit=5`,
      );
      setPayments(balance.data);
    } catch (error) {
      console.error(error);
    }
    setLoadingPayments(false);
  };

  const getTotal = async (date = selectedDate) => {
    setLoadingTotal(true);
    try {
      const total = await welletContext.apis.tickets.get(
        `/cashier/payments/total?From=${date}&to=${date}`,
      );
      setTotal(total.data);
    } catch (error) {
      console.error(error);
    }
    setLoadingTotal(false);
  };

  const handleChangeDate = (day) => {
    setTotal(0);
    if (format(day, 'yyyy-MM-dd') === selectedDate) {
      setSelectedDate(selectedDate);
    } else {
      setSelectedDate(day);
    }
    setCalendarVisible(!calendarVisible);
    getTotal(format(day, 'yyyy-MM-dd'));
  };

  useEffect(() => {
    getPayments();
  }, []);

  useEffect(() => {
    getTotal(selectedDate);
  }, []);

  return (
    <div>
      <AccountHeader title={app.name} icon={'config'} pathName='/configuration' />
      <ContentApp menu header>
        <Balance
          title={t('payments')}
          loading={loadingTotal}
          history={history}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          handleChangeDate={handleChangeDate}
          calendarVisible={calendarVisible}
          setCalendarVisible={setCalendarVisible}
          todayBalance={total}
        />
        <div className='mt-2'>
          {loadingPayments ? (
            SkeletonPayments()
          ) : (
            <ActivitiesHome
              title={<div className='bold'>{t('yourLatestOperations')}</div>}
              linkTitle={payments?.length >= 5 ? t('allPayments') : null}
              pathName={'/cashier-payments'}
              history={history}
              children={
                payments?.length === 0 ? (
                  <EmptyActivity
                    title={t('noPayments')}
                    subtitle={<div className='text-secondary'>{t('noPaymentsDescription')}</div>}
                    icon='notification'
                    styles={{ backgroundColor: 'rgba(30, 30, 30, 0.7)' }}
                  />
                ) : (
                  <div className='p-2'>
                    {payments?.slice(0, 5).map((w, index) => (
                      <CashierItem
                        activity={w}
                        key={index}
                        className='px-2 py-2'
                        handleClick={() => history.push(`/cashier-activity?id=${w.id}`)}
                        date={w.createdAt}
                        isLastItem={index === payments.length - 1}
                        index={index}
                      />
                    ))}
                  </div>
                )
              }
            />
          )}
        </div>
        <FixedButton>
          <Button
            text={t('pay')}
            size='large'
            className='btn'
            icon
            typeIcon='qr'
            onClick={() => history.push('/cashier-qr-scan')}
          />
        </FixedButton>
      </ContentApp>
    </div>
  );
};

export default AccountCashier;
