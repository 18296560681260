import React, { useState, useEffect, Suspense, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Detector } from 'react-detect-offline';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { Route, HashRouter, useLocation, useHistory } from 'react-router-dom';
import { CircleSpinner } from 'react-spinners-kit';
import { appReady, init, fetchAppData } from '../actions/appActions';
import { initializeApp } from 'firebase/app';
import { DataManagerProvider } from '../context/DataManager';
import { AssociateRealtimeProvider } from '../context/AssociateRealtimeProvider';
import { useTranslation } from 'react-i18next';

import ContextProvider from '../context/ContextProvider';
import ForgotPassword from '../screens/Login/ForgotPassword/ForgotPassword';
import SignUpScreen from '../screens/SignUp/SignUpScreen';
import SignUpEmailValidationScreen from '../screens/SignUp/SignUpEmailValidationScreen';
import SignUpCreatePassword from '../screens/SignUp/SignUpCreatePassword';
import Menu from '../screens/User/Menu/Menu';
import FrontValidateKyc from '../screens/User/Kyc/ValidateScreen/FrontValidateKyc';
import StartKyc from '../screens/User/Kyc/ValidateScreen/StartKyc';

import BackValidateKyc from '../screens/User/Kyc/ValidateScreen/BackValidateKyc';
import SelfieValidateKyc from '../screens/User/Kyc/ValidateScreen/SelfieValidateKyc';
import FinishKyc from '../screens/User/Kyc/ValidateScreen/FinishKyc';
import DeleteAccountScreen from '../screens/User/DeleteAccountScreen/DeleteAccountScreen';
import Login from '../screens/Login/LoginScreen/LoginScreen';
import UserProfile from '../screens/User/Profile/UserProfile';
import PointsScreen from '../screens/User/Points/PointsScreen';
import PointsDetailsScreen from '../screens/User/Points/PointsDetailsScreen';
import PointsActivityScreen from '../screens/User/Points/PointsActivityScreen';
import Payments from '../screens/User/Payments/Payments';
import AllReservations from '../screens/Reservation/AllReservations/AllReservations';
import AuthRoute from './Routes/AuthRoute';
import Auth from './Auth';
import Account from '../screens/User/Account/Account';
import ReservationCheck from '../screens/Reservation/ReservationCheck/ReservationCheck';
import AllPayments from '../screens/User/Payments/AllPayments';
import RestaurantList from './RestaurantList/RestaurantList';
import RefreshIcon from './icons/RefreshIcon';
import KycStatus from '../screens/User/Kyc/ValidateScreen/KycStatus';
import Notifications from '../screens/User/Notifications/Notifications';
import ReservationSuccess from '../screens/Reservation/ReservationSuccess/ReservationSuccess';
import Configuration from '../screens/User/NotificationsConfiguration/Configuration';
import ReservationsDisputed from './ReservationsDisputed/ReservationsDisputed';
import CitySelector from './CitySelector/CitySelector';

import '../css/bootstrap-customization.css';
import '../css/fonts-inter.css';
import '../css/colors.css';
import '../css/style.css';
import AccountCashier from '../screens/Cashier/AccountCashier';
import QRReaderScreen from '../screens/Cashier/QRReaderScreen';
import CashierOperation from '../screens/Cashier/CashierOperation';

import Activation from '../screens/Activate/Activation';
import Withdrawal from '../screens/Withdrawals/Withdrawal';

import Unauthorized from '../screens/User/Unauthorized/Unauthorized';
import MyAffiliates from '../screens/UserRp/MyAffiliates';
import WelcomeHomeScreen from '../screens/User/WelcomeScreens/WelcomeHomeScreen';
import Onboarding from '../screens/User/WelcomeScreens/Onboarding';
import SignUpPhoneValidationScreen from '../screens/SignUp/SignUpPhoneValidationScreen';
import LoginPassword from '../screens/Login/LoginPasswordScreen/LoginPasswordScreen';

import CashierPayments from '../screens/Cashier/CashierPayments';
import SignUpTerms from '../screens/SignUp/SignUpTerms';
import TermsAndConditions from '../screens/SignUp/TermsAndConditions';
import PrivacyPolicy from '../screens/SignUp/PrivacyPolicy';

import Reservation from '../screens/Reservation/Reservation';
import BalancePerMonthScreen from '../screens/User/BalancePerMonth/BalancePerMonthScreen';
import BalancePerMonthDetailScreen from '../screens/User/BalancePerMonth/BalancePerMonthDetailScreen';
import ModifyReservation from '../screens/Reservation/ModifyReservation/ModifyReservation';
import SignUpConciergeScreen from '../screens/SignUp/SignUpConciergeScreen';
import LinkMercadoPagoAccount from '../screens/User/LinkMercadoPagoAccount/LinkMercadoPagoAccount';
import LinkStripeConnect from '../screens/User/LinkStripeConnect/LinkStripeConnect';
import LinkBankNational from '../screens/User/LinkBankNational/LinkBankNational';
import LinkBankInternational from '../screens/User/LinkBankInternational/LinkBankInternational';
import ReservationType from '../screens/Reservation/ReservationType/ReservationType';
import ReservationArea from '../screens/Reservation/ReservationArea/ReservationArea';
import ReservationAreaExtra from '../screens/Reservation/ReservationAreaExtra/ReservationAreaExtra';
import CashierActivityDetail from '../screens/Cashier/CashierActivityDetail';
import ReservationDetailScreen from '../screens/Reservation/ReservationDetailScreen/ReservationDetailScreen';
import LoginUserScreen from '../screens/Login/LoginUserScreen/LoginUserScreen';
import MyMoneyDetail from '../screens/User/MyMoneyDetail/MyMoneyDetail';
import UserChangePasswordScreen from '../screens/User/UserChangePasswordScreen/UserChangePasswordScreen';
import OfflineScreen from '../screens/Common/OfflineScreen';

import FrequentQuestions from '../screens/User/FrequentQuestions/FrequentQuestions';
import RouteReservationDependant from './Routes/RouteReservationDependant';
import OnboardingCampaigns from '../screens/User/Campaigns/OnboardingCampaigns/OnboardingCampaigns';
import CampaignsFeed from '../screens/User/Campaigns/CampaignsFeed/CampaignsFeed';

import { saveToken } from '../services/firebase/pushNotifications';
import LinkQrRestaurant from '../screens/User/LinkQr/LinkQrRestaurant/LinkQrRestaurant';
import LockIcon from './icons/LockIcon';
import SignUpPersonalInformation from '../screens/SignUp/SignUpPersonalInformation';
import MarketSelector from '../screens/SignUp/MarketSelector';

const firebaseConfig = JSON.parse(process.env.REACT_APP_FIREBASE);

const App = ({ language }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const associate = useSelector((state) => state.associates);
  const app = useSelector((state) => state.app);
  const user = associate.user;
  const authChecked = associate.authChecked;
  const authenticated = associate.authenticated;
  const status = useSelector((state) => state.app.status);
  const tenantId = useSelector((state) => state.app.tenantId);
  const [loginScreen, setLoginScreen] = useState(history.location.hash === '#login' ? true : false);
  const firebaseApp = initializeApp(firebaseConfig);
  const [startPoint, setStartPoint] = useState(0);
  const [pullChange, setPullChange] = useState();
  const refreshCont = useRef(0);
  const [refresh, setRefresh] = useState(false);
  const isCashier = user?.roles?.includes('cashier');
  const advantageNetworkStatus = useSelector(
    (state) => state?.associates?.user?.advantageNetworkStatus,
  );

  const initLoading = () => {
    // full reload when in home
    if (history?.location?.hash === '' || history?.location?.hash === '#') {
      window.location.reload();
    }

    if (refreshCont && refreshCont.current) {
      refreshCont?.current?.classList.add('loading');
    }
    setTimeout(() => {
      setRefresh(!refresh);
    }, 1000);
  };

  const pullStart = (e) => {
    if (app.canRefresh) {
      const { screenY } = e.targetTouches[0];
      setStartPoint(screenY);
    }
  };

  const pull = (e) => {
    if (app.canRefresh) {
      const touch = e.targetTouches[0];
      const { screenY } = touch;
      const pullLength = startPoint < screenY ? Math.abs(screenY - startPoint) : 0;
      setPullChange(pullLength);
    }
  };

  const endPull = (e) => {
    setStartPoint(0);
    setPullChange(0);
    if (pullChange > 220) initLoading();
  };

  useEffect(() => {
    window.addEventListener('touchstart', pullStart);
    window.addEventListener('touchmove', pull);
    window.addEventListener('touchend', endPull);

    return () => {
      window.removeEventListener('touchstart', pullStart);
      window.removeEventListener('touchmove', pull);
      window.removeEventListener('touchend', endPull);
    };
  });

  useEffect(() => {
    if (app.language !== null) i18n.changeLanguage(app.language);
  }, [app.language]);

  useEffect(() => {
    dispatch(appReady(language));
  }, []);

  useEffect(() => {
    if (history.location.hash === '#login') {
      setLoginScreen(true);
    } else {
      setLoginScreen(false);
    }
  }, [history.location.hash]);

  // useEffect(() => {
  //   if (user !== null) {
  //     dispatch(init(user, associate));
  //   }
  // }, [user]);

  useEffect(() => {
    if (authenticated) {
      saveToken(associate.jwt);
    }
  }, [authenticated]);

  useEffect(() => {
    if (tenantId) {
      // get app data based on tenant
      dispatch(fetchAppData(tenantId, language));
    }
  }, [tenantId, dispatch]);

  const renderLoading = () => {
    let spinner = (
      <CircleSpinner
        className='spinner-app mx-auto'
        style={{ margin: 'auto' }}
        size={40}
        color='var(--color-font-primary)'
      />
    );
    return (
      <div className='loading-app'>
        <div className='loaderDiv'>{spinner}</div>
      </div>
    );
  };

  const renderHomeRoute = () => {
    if (isCashier) {
      return <AuthRoute path='/' render={(props) => <AccountCashier {...props} />} />;
    } else {
      return <AuthRoute path='/' render={(props) => <Account {...props} />} />;
    }
  };

  const onlinePollingDetector = {
    enabled: true,
    interval: 15000,
    url: `https://status.wellet.fun/`,
  };

  const renderApp = () => {
    const timeout = { enter: 500, exit: 500 };

    return (
      <DataManagerProvider>
        <AssociateRealtimeProvider>
          <ContextProvider key={refresh}>
            <TransitionGroup
              component='div'
              className={`main ${loginScreen ? 'login-screen' : 'background-app'}`}
            >
              <CSSTransition timeout={timeout} classNames='fade' appear>
                <div>
                  <div
                    className={
                      advantageNetworkStatus === 'REJECTED' ? 'censured-text fade-up' : 'd-none'
                    }
                  >
                    <LockIcon size='50px' color='#fff' />
                    <div className='bold text-white mt-4 text-center'>
                      {t('identityNotValidated.paymentsText')}
                    </div>
                  </div>
                  <div className={advantageNetworkStatus === 'REJECTED' ? 'censured-content' : ''}>
                    <div
                      className='refresh-container'
                      ref={refreshCont}
                      style={{
                        paddingTop: pullChange / 3.118 / 2 || '',
                        //height: Math.min(pullChange / 3.118, 37) || '',
                      }}
                    >
                      <div className='refresh-icon pt-3'>
                        <RefreshIcon color={'#fff'} pullChange={pullChange} />
                      </div>
                    </div>
                    <div className='main-inner'>
                      <HashRouter location={location} hashType='noslash'>
                        <Route path='/login' render={(props) => <Login {...props} />} />
                        <Route
                          path='/login-user'
                          render={(props) => <LoginUserScreen {...props} />}
                        />
                        <Route
                          path='/login-password'
                          render={(props) => <LoginPassword {...props} />}
                        />
                        <Route
                          path='/forgot-password'
                          render={(props) => <ForgotPassword {...props} />}
                        />
                        <Route path='/sign-up' render={(props) => <SignUpScreen {...props} />} />
                        <Route
                          path='/sign-up-terms'
                          render={(props) => <SignUpTerms {...props} />}
                        />
                        <Route
                          path='/terms-and-conditions'
                          render={(props) => <TermsAndConditions {...props} />}
                        />
                        <Route
                          path='/privacy-policy'
                          render={(props) => <PrivacyPolicy {...props} />}
                        />
                        <Route
                          path='/sign-up-phone-validation'
                          render={(props) => <SignUpPhoneValidationScreen {...props} />}
                        />
                        <Route
                          path='/sign-up-email-validation'
                          render={(props) => <SignUpEmailValidationScreen {...props} />}
                        />
                        <Route
                          path='/sign-up-create-password'
                          render={(props) => <SignUpCreatePassword {...props} />}
                        />
                        <Route
                          path='/sign-up-concierge-info'
                          render={(props) => <SignUpConciergeScreen {...props} />}
                        />
                        <Route
                          path='/sign-up-personal-information'
                          render={(props) => <SignUpPersonalInformation {...props} />}
                        />
                        <Route path='/kyc-start' render={(props) => <StartKyc {...props} />} />
                        <Route
                          path='/kyc-validate-front'
                          render={(props) => <FrontValidateKyc {...props} />}
                        />
                        <Route
                          path='/kyc-validate-back'
                          render={(props) => <BackValidateKyc {...props} />}
                        />
                        <Route
                          path='/kyc-validate-selfie'
                          render={(props) => <SelfieValidateKyc {...props} />}
                        />
                        <Route
                          path='/kyc-validate-finish'
                          render={(props) => <FinishKyc {...props} />}
                        />
                        <Route
                          path='/market-selector'
                          render={(props) => <MarketSelector {...props} />}
                        />
                        <AuthRoute
                          path='/cashier-qr-scan'
                          render={(props) => <QRReaderScreen {...props} />}
                        />
                        <AuthRoute
                          path='/cashier-operation'
                          render={(props) => <CashierOperation {...props} />}
                        />
                        <AuthRoute
                          path='/kyc-status'
                          render={(props) => <KycStatus {...props} />}
                        />
                        <AuthRoute
                          path='/account-cashier'
                          render={(props) => <AccountCashier {...props} />}
                        />
                        <AuthRoute
                          path='/cashier-activity'
                          render={(props) => <CashierActivityDetail {...props} />}
                        />
                        <AuthRoute
                          path='/cashier-payments'
                          render={(props) => <CashierPayments {...props} />}
                        />
                        {renderHomeRoute()}
                        <Route
                          path='/unauthorized'
                          render={(props) => <Unauthorized {...props} />}
                        />
                        <AuthRoute
                          path='/notifications'
                          render={(props) => <Notifications {...props} />}
                        />
                        <AuthRoute
                          path='/configuration'
                          render={(props) => <Configuration {...props} />}
                        />
                        <AuthRoute
                          path='/my-affiliates'
                          render={(props) => <MyAffiliates {...props} />}
                        />
                        <AuthRoute
                          path='/city-selector'
                          render={(props) => <CitySelector {...props} />}
                        />
                        <AuthRoute
                          path='/welcome'
                          render={(props) => <WelcomeHomeScreen {...props} />}
                        />
                        <AuthRoute
                          path='/onboarding'
                          render={(props) => <Onboarding {...props} />}
                        />
                        <AuthRoute
                          path='/restaurant-list/:cityId?'
                          render={(props) => <RestaurantList {...props} />}
                        />
                        <AuthRoute
                          path='/reservation-type'
                          render={(props) => <ReservationType {...props} />}
                        />
                        <AuthRoute
                          path='/reservation-restaurant'
                          render={(props) => <Reservation {...props} />}
                        />
                        <AuthRoute
                          path='/qr-restaurant'
                          render={(props) => <LinkQrRestaurant {...props} />}
                        />
                        <AuthRoute
                          path='/reservation/:reservationType'
                          render={(props) => <ReservationArea {...props} />}
                        />
                        <RouteReservationDependant
                          path='/area-extra-reservation'
                          render={(props) => <ReservationAreaExtra />}
                          redirectPath='/restaurant-list'
                        />
                        <RouteReservationDependant
                          path='/reservation-check'
                          render={(props) => <ReservationCheck {...props} />}
                          redirectPath='/restaurant-list'
                        />
                        <AuthRoute
                          path='/modify-reservation'
                          render={(props) => <ModifyReservation {...props} />}
                        />
                        <AuthRoute path='/my-money' render={(props) => <Payments {...props} />} />
                        <AuthRoute
                          path='/reservations-disputed'
                          render={(props) => <ReservationsDisputed {...props} />}
                        />
                        <AuthRoute
                          path='/my-money/detail'
                          render={(props) => <MyMoneyDetail {...props} />}
                        />
                        <AuthRoute
                          path='/withdrawal-cash'
                          render={(props) => <Withdrawal method='cash' {...props} />}
                        />
                        <AuthRoute
                          path='/withdrawal-certificate'
                          render={(props) => <Withdrawal method='certificate' {...props} />}
                        />
                        <AuthRoute
                          path='/mercadopagoaccount'
                          render={(props) => <LinkMercadoPagoAccount {...props} />}
                        />
                        <AuthRoute
                          path='/stripeaccount'
                          render={(props) => <LinkStripeConnect {...props} />}
                        />
                        <AuthRoute
                          path='/national-bank'
                          render={(props) => <LinkBankNational {...props} />}
                        />
                        <AuthRoute
                          path='/international-bank'
                          render={(props) => <LinkBankInternational {...props} />}
                        />
                        <AuthRoute
                          path='/all-reservations'
                          render={(props) => <AllReservations {...props} />}
                        />
                        <AuthRoute
                          path='/activation'
                          render={(props) => <Activation {...props} />}
                        />
                        <AuthRoute
                          path='/all-payments'
                          render={(props) => <AllPayments {...props} />}
                        />
                        <AuthRoute
                          path='/points-activities'
                          render={(props) => <PointsActivityScreen {...props} />}
                        />
                        <AuthRoute path='/profile' render={(props) => <UserProfile {...props} />} />
                        <AuthRoute
                          path='/operation-details'
                          render={(props) => <ReservationDetailScreen {...props} />}
                        />
                        <AuthRoute
                          path='/points-details'
                          render={(props) => <PointsDetailsScreen {...props} />}
                        />
                        <AuthRoute
                          path='/change-password'
                          render={(props) => <UserChangePasswordScreen {...props} />}
                        />
                        <AuthRoute
                          path='/delete-account'
                          render={(props) => <DeleteAccountScreen {...props} />}
                        />
                        <AuthRoute
                          path='/onboarding-campaigns'
                          render={(props) => <OnboardingCampaigns {...props} />}
                        />
                        <AuthRoute
                          path='/campaigns-feed'
                          render={(props) => <CampaignsFeed {...props} />}
                        />
                        <AuthRoute
                          path='/frequent-questions'
                          render={(props) => <FrequentQuestions {...props} />}
                        />
                        <AuthRoute
                          path='/reservation-success'
                          render={(props) => <ReservationSuccess {...props} />}
                        />
                        <AuthRoute
                          path='/incomes-per-month'
                          render={(props) => <BalancePerMonthScreen {...props} />}
                        />
                        <AuthRoute
                          path='/incomes-per-month-detail'
                          render={(props) => <BalancePerMonthDetailScreen {...props} />}
                        />
                        <AuthRoute
                          path={'/points'}
                          render={(props) => <PointsScreen {...props} />}
                        />
                        <AuthRoute path={'/menu'} render={() => <Menu history={history} />} />
                      </HashRouter>
                    </div>
                  </div>
                </div>
              </CSSTransition>
            </TransitionGroup>
          </ContextProvider>
        </AssociateRealtimeProvider>
      </DataManagerProvider>
    );
  };

  return (
    <Detector
      polling={onlinePollingDetector}
      render={({ online }) =>
        online ? (
          <Suspense fallback={renderLoading()}>
            {authChecked &&
            status === 'READY' &&
            (!authenticated || (authenticated && user !== null))
              ? renderApp()
              : renderLoading()}
            <Auth key='auth' />
          </Suspense>
        ) : (
          <TransitionGroup component='div' className={'main background-app'}>
            <OfflineScreen />
          </TransitionGroup>
        )
      }
    />
  );
};

export default App;
