import './badge.css';

const Badge = ({ text, type }) => {
  return (
    <div className={`badge-status ${type}`} >
      <div className='badge-status-dot'></div>
      {text}
    </div>
  );
};

export default Badge;
