import React from 'react';

const LockIcon = ({ size, color }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 50 56'
      fill={color}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M25 1.13323e-08C29.3122 -0.000159536 33.4566 1.68438 36.5624 4.6997C39.6683 7.71502 41.4938 11.8264 41.6556 16.17L41.6667 16.8H44.4444C45.846 16.7996 47.196 17.3331 48.2237 18.2938C49.2515 19.2544 49.881 20.5712 49.9861 21.98L50 22.4V50.4C50.0004 51.8128 49.4711 53.1736 48.5181 54.2095C47.565 55.2455 46.2588 55.88 44.8611 55.986L44.4444 56H5.55556C4.15395 56.0004 2.80398 55.4669 1.77626 54.5062C0.74853 53.5456 0.119009 52.2288 0.0138895 50.82L2.78522e-07 50.4V22.4C-0.000443276 20.9872 0.528907 19.6264 1.48194 18.5905C2.43497 17.5545 3.74124 16.92 5.13889 16.814L5.55556 16.8H8.33333C8.33333 12.3444 10.0893 8.07122 13.2149 4.92061C16.3405 1.76999 20.5797 1.13323e-08 25 1.13323e-08ZM44.4444 22.4H5.55556V50.4H44.4444V22.4ZM25 28C26.185 28.0004 27.3388 28.3827 28.2929 29.091C29.247 29.7994 29.9514 30.7967 30.3033 31.9373C30.6551 33.0778 30.6359 34.3019 30.2485 35.4308C29.8611 36.5596 29.1258 37.534 28.15 38.2116L27.7778 38.4496V42C27.777 42.7137 27.5059 43.4001 27.0199 43.919C26.5338 44.438 25.8696 44.7503 25.1628 44.7921C24.456 44.8339 23.7601 44.6021 23.2171 44.1441C22.6742 43.686 22.3253 43.0363 22.2417 42.3276L22.2222 42V38.4496C21.1632 37.8332 20.3355 36.8818 19.8675 35.7429C19.3996 34.604 19.3175 33.3413 19.634 32.1506C19.9505 30.9599 20.648 29.9077 21.6181 29.1573C22.5883 28.4068 23.7771 28.0001 25 28ZM25 5.6C22.0531 5.6 19.227 6.78 17.1433 8.8804C15.0595 10.9808 13.8889 13.8296 13.8889 16.8H36.1111C36.1111 13.8296 34.9405 10.9808 32.8567 8.8804C30.773 6.78 27.9468 5.6 25 5.6Z'
        fill='#D9D9D9'
      />
    </svg>
  );
};

export default LockIcon;
