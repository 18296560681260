import { Skeleton } from '@mui/material';
import React from 'react';

export const SkeletonReservationArea = () => {
  return (
    <>
      <div className='mt-2'>
        <Skeleton
          variant='rectangular'
          sx={{
            bgcolor: 'grey.900',
            width: '100%',
            borderRadius: '.4rem',
            height: 86,
          }}
        />
      </div>
      <div className='mt-2'>
        <Skeleton
          variant='rectangular'
          sx={{
            bgcolor: 'grey.900',
            width: '100%',
            borderRadius: '.4rem',
            height: 86,
          }}
        />
      </div>
      <div className='mt-2 mb-3'>
        <Skeleton
          variant='rectangular'
          sx={{
            bgcolor: 'grey.900',
            width: '100%',
            borderRadius: '.4rem',
            height: 86,
          }}
        />
      </div>
    </>
  );
};

export default SkeletonReservationArea;
