import React from 'react';

const InstagramIcon = ({ size, color }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      version='1.0'
      width={size}
      height={size}
      viewBox='0 0 512.000000 512.000000'
      preserveAspectRatio='xMidYMid meet'
    >
      <g
        transform='translate(0.000000,512.000000) scale(0.100000,-0.100000)'
        fill={color}
        stroke='none'
      >
        <path d='M1220 5105 c-628 -87 -1121 -584 -1206 -1215 -20 -155 -20 -2505 0 -2660 42 -312 180 -592 402 -814 221 -221 502 -360 814 -402 155 -20 2505 -20 2660 0 636 86 1130 580 1216 1216 20 155 20 2505 0 2660 -86 636 -580 1130 -1216 1216 -148 19 -2526 19 -2670 -1z m2562 -445 c244 -23 445 -118 609 -287 160 -165 246 -354 269 -591 13 -144 13 -2300 0 -2444 -23 -237 -109 -426 -269 -591 -164 -169 -365 -264 -609 -287 -144 -13 -2300 -13 -2444 0 -244 23 -445 118 -609 287 -160 165 -246 354 -269 591 -13 144 -13 2300 0 2444 23 244 118 445 287 609 159 155 356 247 573 268 127 12 2333 13 2462 1z' />
        <path d='M3813 4239 c-75 -29 -144 -95 -178 -171 -97 -212 50 -453 284 -466 113 -6 228 51 290 145 41 61 55 113 54 196 -2 222 -238 378 -450 296z' />
        <path d='M2380 3865 c-290 -41 -542 -167 -750 -375 -224 -223 -348 -488 -381 -813 -33 -311 66 -658 260 -912 58 -77 179 -198 256 -256 254 -194 601 -293 913 -260 324 33 589 157 812 381 224 223 348 488 381 812 27 257 -35 543 -168 773 -268 466 -793 724 -1323 650z m380 -465 c311 -75 565 -329 640 -640 27 -116 27 -284 0 -400 -59 -248 -233 -463 -463 -575 -126 -61 -233 -85 -377 -85 -144 0 -251 24 -377 85 -230 112 -404 327 -463 575 -27 116 -27 284 0 400 74 308 327 564 635 639 112 28 291 28 405 1z' />
      </g>
    </svg>
  );
};

export default InstagramIcon;
