import React from 'react';

const CashIcon = ({ size = '20px', color = '#fff' }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      version='1.0'
      width={size}
      height={size}
      viewBox='0 0 64.000000 50.000000'
      preserveAspectRatio='xMidYMid meet'
    >
      <g
        transform='translate(0.000000,50.000000) scale(0.100000,-0.100000)'
        fill={color}
        stroke='none'
      >
        <path d='M356 407 c-59 -17 -104 -33 -98 -35 12 -4 234 27 241 34 5 5 -20 34 -28 33 -3 -1 -55 -15 -115 -32z' />
        <path d='M352 370 c-68 -10 -126 -21 -130 -24 -3 -3 68 -6 157 -6 l162 0 -3 23 c-4 29 -29 30 -186 7z' />
        <path d='M77 324 c-4 -4 -7 -62 -7 -130 0 -102 3 -123 16 -128 24 -9 457 -7 472 2 9 7 12 40 10 133 l-3 124 -241 3 c-132 1 -243 -1 -247 -4z m163 -34 c0 -5 -21 -10 -47 -10 -68 -1 -83 -15 -83 -82 0 -68 16 -85 85 -91 l50 -3 -55 -2 c-79 -2 -90 10 -90 93 0 89 13 105 87 105 29 0 53 -4 53 -10z m125 -5 c94 -45 60 -185 -45 -185 -104 0 -139 138 -46 184 39 20 50 20 91 1z m157 -2 c14 -13 18 -31 18 -86 0 -85 -11 -97 -90 -95 l-55 2 50 3 c69 6 85 23 85 91 0 67 -15 81 -82 82 -27 0 -48 5 -48 10 0 17 101 12 122 -7z m-342 -83 c0 -13 -7 -20 -20 -20 -19 0 -27 20 -13 33 13 14 33 6 33 -13z m318 -3 c2 -12 -3 -17 -17 -17 -15 0 -21 6 -21 21 0 25 33 22 38 -4z' />
        <path d='M255 248 c-32 -38 -29 -81 11 -116 49 -43 127 -14 140 54 9 46 -35 94 -86 94 -31 0 -44 -6 -65 -32z m83 0 c7 -7 12 -19 12 -27 0 -11 -3 -11 -13 4 -8 10 -19 15 -25 11 -18 -11 -14 -22 13 -34 28 -13 31 -28 10 -56 -15 -20 -15 -20 -30 0 -19 25 -19 40 0 24 12 -10 19 -10 27 -2 8 8 4 14 -15 21 -29 11 -35 39 -15 59 15 15 21 15 36 0z' />
      </g>
    </svg>
  );
};

export default CashIcon;
