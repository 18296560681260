import React from 'react';

const ConfigIcon = ({ size, color, styles }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      style={styles}
      viewBox='0 0 512.000000 512.000000'
      preserveAspectRatio='xMidYMid meet'
    >
      <g
        transform='translate(0.000000,512.000000) scale(0.100000,-0.100000)'
        fill={color}
        stroke='none'
      >
        <path
          d='M1974 4941 c-74 -34 -82 -58 -113 -346 -15 -140 -30 -257 -34 -261
           -3 -4 -83 56 -177 132 -226 184 -238 192 -286 199 -80 13 -100 -4 -510 -416
           -297 -299 -384 -392 -394 -422 -24 -76 -12 -100 167 -322 91 -113 162 -209
           159 -212 -4 -4 -121 -19 -262 -34 -288 -31 -311 -39 -345 -113 -17 -38 -19
           -76 -19 -586 0 -510 2 -548 19 -586 34 -74 57 -82 345 -113 141 -15 258 -30
           262 -33 3 -4 -68 -100 -159 -213 -141 -175 -167 -213 -172 -250 -13 -81 2
           -100 416 -511 409 -406 417 -413 497 -399 44 8 60 19 282 199 94 76 174 136
           177 132 4 -4 19 -121 34 -261 31 -288 39 -312 113 -346 38 -17 76 -19 586 -19
           510 0 548 2 586 19 74 34 82 58 113 346 15 140 30 257 33 261 4 3 100 -68 213
           -159 222 -179 246 -191 322 -167 53 17 796 754 825 819 38 83 28 103 -159 336
           -91 113 -162 209 -159 213 4 3 121 18 261 33 288 31 312 39 346 113 17 38 19
           76 19 586 0 510 -2 548 -19 586 -34 74 -58 82 -346 113 -140 15 -257 30 -261
           33 -3 4 68 100 159 213 187 233 197 253 159 336 -29 65 -772 802 -825 819 -76
           24 -100 12 -322 -167 -113 -91 -209 -162 -213 -159 -3 4 -18 121 -33 261 -31
           288 -39 312 -113 346 -38 17 -76 19 -586 19 -510 0 -548 -2 -586 -19z m960
           -323 c3 -13 15 -117 26 -233 12 -115 28 -221 35 -235 22 -43 57 -67 140 -100
           44 -18 102 -41 129 -52 86 -34 105 -25 309 139 99 79 185 147 191 149 6 2 127
           -112 270 -255 186 -186 256 -263 250 -272 -4 -8 -72 -93 -150 -190 -161 -200
           -170 -219 -136 -305 11 -27 34 -85 52 -129 33 -83 57 -118 100 -140 14 -7 120
           -23 235 -35 116 -11 220 -23 233 -26 l22 -4 0 -370 0 -370 -22 -4 c-13 -3
           -117 -15 -233 -26 -115 -12 -221 -28 -235 -35 -43 -22 -67 -57 -100 -140 -18
           -44 -41 -102 -52 -129 -34 -86 -25 -105 136 -305 78 -97 146 -182 150 -190 6
           -9 -64 -86 -250 -272 -142 -143 -262 -259 -265 -259 -4 0 -87 65 -185 145
           -201 163 -229 177 -304 152 -103 -35 -227 -90 -248 -110 -42 -39 -50 -69 -72
           -282 -11 -116 -23 -220 -26 -232 l-4 -23 -370 0 -370 0 -4 23 c-3 12 -15 116
           -26 232 -12 115 -28 221 -35 235 -22 43 -57 67 -140 100 -44 18 -102 41 -129
           52 -86 34 -105 25 -308 -139 -99 -79 -184 -146 -191 -149 -7 -2 -128 112 -271
           255 -186 186 -256 263 -250 272 4 8 72 93 150 190 161 200 170 219 136 305
           -11 27 -34 85 -52 129 -33 83 -57 118 -100 140 -14 7 -120 23 -235 35 -116 12
           -220 23 -232 26 l-23 4 0 370 0 370 22 4 c13 3 117 14 233 26 115 12 221 28
           235 35 43 22 67 57 100 140 18 44 41 102 52 129 34 86 25 105 -136 305 -78 97
           -146 182 -150 190 -6 9 64 86 250 272 142 143 262 259 265 259 4 0 90 -67 192
           -149 209 -168 227 -177 313 -143 27 11 85 34 129 52 83 33 118 57 140 100 7
           14 23 120 35 235 11 116 23 220 26 233 l4 22 370 0 370 0 4 -22z'
        />
        <path
          d='M2415 3860 c-568 -65 -1030 -497 -1141 -1067 -23 -118 -23 -348 0
           -466 104 -539 514 -948 1053 -1053 130 -26 357 -23 490 5 529 111 934 526
           1030 1055 22 119 22 333 0 452 -95 526 -505 948 -1025 1053 -116 24 -297 33
           -407 21z m355 -336 c176 -36 350 -133 485 -268 287 -289 368 -694 216 -1075
           -81 -201 -266 -401 -463 -498 -159 -79 -276 -107 -453 -106 -144 0 -239 19
           -370 72 -123 51 -221 116 -321 216 -192 191 -287 421 -287 695 0 274 95 504
           287 695 166 165 364 259 606 289 59 7 220 -4 300 -20z'
        />
      </g>
    </svg>
  );
};

export default ConfigIcon;
