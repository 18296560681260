import React, { useState, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { WelletContext } from '../../../context/WelletContext';
import { firstLogin } from '../../../actions/associatesActions';
import { useTranslation } from 'react-i18next';
import ContentApp from '../../../components/ContentApp';
import Button from '../../../components/Common/Button/Button';
import step1 from '../../../images/Welcome/Restaurants.png';
import step2 from '../../../images/Welcome/Balance.png';
import step3 from '../../../images/Welcome/Points.png';
import styles from './welcomeScreens.module.css';
import FixedButton from '../../../components/Common/FixedButton/FixedButton';
import { useSelector } from 'react-redux';
const Onboarding = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const welletContext = useContext(WelletContext);
  const history = useHistory();
  const app = useSelector((state) => state.app)
  const steps = [
    {
      value: 1,
      title: t('onboarding.step1.title'),
      subtitle:  t('onboarding.step1.subtitle', { organization: app.name }),
      image: step1,
    },
    {
      value: 2,
      title: t('onboarding.step2.title'),
      subtitle: t('onboarding.step2.subtitle'),
      image: step2,
    },
    {
      value: 3,
      title: t('onboarding.step3.title'),
      subtitle: t('onboarding.step3.subtitle'),
      image: step3,
    },
  ];
  const [currentStep, setCurrentStep] = useState(0);

  const handleNextClick = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    } else {
      dispatch(firstLogin(welletContext));
      history.push('/');
    }
  };

  const onHandleOmit = () => {
    dispatch(firstLogin(welletContext));
    history.push('/');
  };

  const step = steps[currentStep];

  return (
    <ContentApp key={currentStep}>
      <div className={`${styles.container} fade-up`}>
        <div className={`text-right font-normal p-3 ${styles.omit}`} onClick={() => onHandleOmit()}>
          {t('onboarding.skip')}
        </div>
        <div
          className={`d-flex align-items-center justify-content-center mt-3 ${styles.minHeigth}`}
        >
          <img src={step.image} alt='logo' className={styles.img} />
        </div>
        <div className='mt-5 mx-2'>
          <div className={`font-large lh-title bold mt-3`}>{step.title}</div>
          <div className={`text-secondary mt-2`}>{step.subtitle}</div>
        </div>
      </div>
      <FixedButton>
        <Button
          text={currentStep < steps.length - 1 ? t('onboarding.next') : t('onboarding.start')}
          size='large'
          className={`mt-2 ${styles.btnOmit}`}
          onClick={handleNextClick}
          color='var(--color-primary)'
        />
      </FixedButton>
    </ContentApp>
  );
};

export default Onboarding;
