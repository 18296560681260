import React, { useState } from 'react';
import { CircleSpinner } from 'react-spinners-kit';
import { useDispatch, useSelector } from 'react-redux';
import { getQueryString } from '../../../helpers/url';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { setExtraPax } from '../../../actions/reservationActions';
import Header from '../../../components/Common/Header/Header';
import ContentApp from '../../../components/ContentApp';
import ProductCard from '../../../components/ProductCard/ProductCard';
import ButtonReservation from '../../../components/Common/Button/ButtonReservation';
import Money from '../../../components/Common/Money/Money';

import styles from '../reservation.module.css';
import ChevronIcon from '../../../components/icons/chevronIcon';
import ProductModal from '../../../components/Common/ProductModal/ProductModal';
import ReservationTicketInfo from '../ReservationTicketInfo/ReservationTicketInfo';
import '../../../css/product-list.css';

const ReservationAreaExtra = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const showId = getQueryString('showId');
  const showName = getQueryString('showName');
  const refReservationId = getQueryString('refReservationId');
  const [loading, setLoading] = useState(false);

  const mainProducts = useSelector((state) => state.reservation.products);
  const mainPaxQuantity = mainProducts
    .filter((p) => p.specialType === null)
    .reduce((t, p) => t + p.quantity, 0);
  const extraProducts = useSelector((state) => state.extraProducts.items);
  const groupedBySpecialType = extraProducts.reduce((acc, producto) => {
    const { specialType } = producto;

    if (!acc[specialType]) {
      acc[specialType] = [];
    }

    acc[specialType].push(producto);
    return acc;
  }, {});
  const orderType = ['EXTRAPAX', 'KIDS', 'DRINK_HOUR', 'EXTRA_PRODUCT', 'UNIQUE_EXTRA_PRODUCT'];
  const [products, setProducts] = useState(extraProducts);
  const hasItems = products?.some((p) => p.quantity > 0);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const getTotal = () => {
    let total = 0;
    extraProducts
      .filter((p) => p.quantity > 0)
      .forEach((product) => {
        if (product.specialType === 'EXTRAPAX') {
          total += product.quantity * product.prices.priceExtraPax;
        } else if (product.specialType === 'DRINK_HOUR') {
          total += product.quantity * product.prices.price * mainPaxQuantity;
        } else total += product.quantity * product.prices.price;
      });

    return total;
  };

  const handleIncrement = (index) => {
    let newProducts = [...products];

    if (newProducts[index].isExclusive) {
      newProducts
        .filter((p) => p.isExclusive && p.id !== newProducts[index].id)
        .forEach((p) => (p.disabled = true));
    }

    if (newProducts[index].specialType === 'EXTRAPAX')
      newProducts[index].quantity = Math.min(
        newProducts[index].extraPax,
        newProducts[index].quantity ? newProducts[index].quantity + 1 : 1,
      );
    else {
      let newQuantity = newProducts[index].quantity ? newProducts[index].quantity : 0;

      if (newQuantity == 0) newQuantity = newProducts[index].minQuantity;
      else newQuantity += 1;

      newProducts[index].quantity = newProducts[index].stock
        ? Math.min(newProducts[index].stock, newQuantity)
        : newQuantity;
    }

    setProducts(newProducts);
  };

  const handleDecrement = (index) => {
    let newProducts = [...products];
    let newQuantity = newProducts[index].quantity - 1;

    if (newQuantity < newProducts[index].minQuantity) newQuantity = 0;

    newProducts[index].quantity = Math.max(0, newQuantity);

    if (newProducts[index].isExclusive && newProducts[index].quantity === 0) {
      newProducts.forEach((p) => (p.disabled = false));
    }

    setProducts(newProducts);
  };

  const handleContinue = () => {
    let query = `?showId=${showId}`;
    if (refReservationId) query += `&refReservationId=${refReservationId}`;

    dispatch(setExtraPax(products.filter((p) => p.quantity !== undefined)));

    history.push({
      pathname: '/reservation-check',
      search: query,
    });
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleAddProduct = (productId) => {
    const index = extraProducts.findIndex((p) => p.id === productId);
    handleIncrement(index);
  };

  const onInfoClick = (product) => {
    setSelectedProduct(product);
    toggleModal();
  };

  const renderProducts = (type, i) => {
    return (
      <div className='product-types' key={i}>
        <div className='bold'>{t('productType.' + type + '.title')}</div>
        <div className='font-small text-lighter-gray'>{t('productType.' + type + '.subTitle')}</div>
        <div className='mt-2'>
          {extraProducts.map((e, index) => {
            if (e.specialType === type)
              return (
                <ProductCard
                  key={index}
                  product={e}
                  isExtraPax={e.specialType && e.specialType === 'EXTRAPAX' ? true : false}
                  onlyOne={type === 'UNIQUE_EXTRA_PRODUCT' ? true : false}
                  hasMoreInfo={true}
                  onInfoClick={() => onInfoClick(e)}
                  handleIncrement={() => handleIncrement(index)}
                  handleDecrement={() => handleDecrement(index)}
                  mainPax={mainPaxQuantity}
                />
              );
            else return null;
          })}
        </div>
      </div>
    );
  };

  return loading ? (
    <div className='loaderDiv'>
      <CircleSpinner
        className='spinner-app mx-auto'
        style={{ margin: 'auto' }}
        size={40}
        color='var(--color-font-primary)'
      />
    </div>
  ) : (
    <>
      <Header title={<div className='text-capitalize px-3'>{t('book') + ' ' + showName}</div>} />
      <ContentApp menu={true}>
        <>
          <div className='bold font-normal'>{t('addAdditional')}</div>
          <div className='product-list mt-3'>
            {orderType.map((type, i) => {
              if (groupedBySpecialType[type] && groupedBySpecialType[type].length > 0) {
                return renderProducts(type, i);
              }
              return null;
            })}
          </div>

          <div
            className='position-fixed w-100'
            style={{
              bottom: 0,
              left: 0,
              zIndex: 2,
            }}
          >
            <div className='container'>
              <div className='row justify-content-center'>
                <div className='col-12 col-md-7 col-lg-6 p-0'>
                  <ButtonReservation
                    leftContent={
                      hasItems ? (
                        <>
                          {t('total')} <Money value={getTotal()} />
                        </>
                      ) : (
                        t('noThanks')
                      )
                    }
                    rightContent={
                      hasItems ? (
                        <>
                          {t('continue')} <ChevronIcon size={20} fillColor={'#000'} />
                        </>
                      ) : (
                        <ChevronIcon size={20} fillColor={'#000'} />
                      )
                    }
                    backgroundColor={!hasItems ? 'var(--color-deep-gray)' : null}
                    size='large'
                    className={`${styles.btn}`}
                    onClick={handleContinue}
                    fixed={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      </ContentApp>

      <ProductModal
        open={isModalOpen}
        onClose={toggleModal}
        animationClass='slide-left-right'
        fullScreen={true}
        hasBackground={true}
        backIcon={true}
      >
        <ReservationTicketInfo
          productId={selectedProduct?.id}
          showAddButton={!selectedProduct?.hasSoldOut && selectedProduct?.stock > 0}
          handleIncrement={() => handleAddProduct(selectedProduct?.id)}
          prices={selectedProduct?.prices}
          toggleModal={toggleModal}
          isModalOpen={isModalOpen}
        />
      </ProductModal>
    </>
  );
};

export default ReservationAreaExtra;
