import React from 'react';

const DelayIcon = ({ size, color, styles }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      version='1.0'
      width={size}
      height={size}
      viewBox='0 0 512.000000 512.000000'
      preserveAspectRatio='xMidYMid meet'
      style={styles}
    >
      <g
        transform='translate(0.000000,512.000000) scale(0.100000,-0.100000)'
        fill={color}
        stroke='none'
      >
        <path d='M2285 4900 c-951 -70 -1765 -752 -2000 -1674 -50 -198 -68 -342 -68 -556 0 -325 55 -596 179 -887 353 -826 1190 -1371 2075 -1351 l137 3 -40 65 c-261 423 -291 964 -78 1417 50 107 149 257 231 351 475 540 1287 662 1899 284 l65 -40 3 137 c15 657 -283 1305 -801 1737 -442 371 -1023 557 -1602 514z m229 -641 c21 -6 58 -29 82 -51 76 -70 74 -46 74 -845 0 -506 -3 -717 -12 -751 -16 -64 -86 -134 -150 -150 -33 -8 -194 -12 -536 -12 -560 0 -562 0 -630 74 -66 72 -77 157 -32 246 22 44 68 82 119 99 23 7 159 11 422 11 l389 0 0 608 0 607 24 51 c25 54 65 89 125 110 45 16 75 17 125 3z' />
        <path d='M3718 2340 c-158 -20 -340 -87 -475 -177 -84 -55 -213 -183 -273 -269 -260 -375 -260 -852 -1 -1227 211 -305 603 -487 966 -447 268 28 494 137 672 323 93 97 139 166 198 290 138 292 135 616 -9 912 -107 219 -267 381 -482 489 -177 89 -408 130 -596 106z m221 -339 c48 -25 87 -71 101 -119 7 -24 10 -147 8 -358 l-3 -321 -27 -39 c-15 -21 -44 -50 -65 -64 -31 -21 -48 -25 -113 -25 -65 0 -82 4 -113 25 -21 14 -50 43 -65 64 l-27 39 -3 321 c-3 343 0 373 44 426 65 76 172 97 263 51z m-36 -1052 c21 -6 56 -26 77 -45 143 -130 51 -370 -141 -368 -182 2 -276 215 -156 351 57 64 134 86 220 62z' />
      </g>
    </svg>
  );
};

export default DelayIcon;
