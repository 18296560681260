import { format, parseISO } from 'date-fns';
import React from 'react';
import { getLocale, isTodayFormat } from '../../../helpers/dates';
import { useSelector } from 'react-redux';
import CallcenterIcon from '../../icons/CallcenterIcon';
import CheckIcon from '../../icons/CheckIcon';
import DelayIcon from '../../icons/DelayIcon';
import '../Activity/activity.module.css';
import Money from '../../Common/Money/Money';
import DolarActivityIcon from '../../icons/DolarActivityIcon';
import { useTranslation } from 'react-i18next';
import styles from './ActivityReservationItem.module.css';
import LinkIcon from '../../icons/LinkIcon';

const ActivityReservationItem = ({
  index,
  activity,
  handleClick,
  date,
  amount,
  isCampaign = false,
}) => {
  const app = useSelector((state) => state.app);
  const user = useSelector((state) => state.associates.user);
  const locale = getLocale(app.language);
  const { t } = useTranslation();

  const statusTexts = {
    PENDING: t('reservationStatus.confirming').toUpperCase(),
    WAITING_DEPOSIT: t('reservationStatus.paymentLink').toUpperCase(),
    REJECTED: t('reservationStatus.rejected').toUpperCase(),
    CANCELLED: t('reservationStatus.cancelled').toUpperCase(),
    CHECKIN: 'CHECK-IN',
    CLOSED: t('options.finished').toUpperCase(),
    NO_SHOW: 'NO SHOW',
    NO_SHOW_MANUAL: 'NO SHOW',
    EXPIRED: t('reservationStatus.expired').toUpperCase(),
    PAYMENT_ACCREDITED: 'CONFIRMADA',
    COMMISSION_REJECTED: 'CONFIRMADA',
    DELAYED: t('reservationStatus.delay').toUpperCase(),
    CONFIRMED: t('reservationStatus.confirmed').toUpperCase(),
  };

  const statusIcons = {
    PENDING: <CallcenterIcon size={'21px'} styles={{ marginLeft: '.25rem' }} />,
    WAITING_DEPOSIT: <LinkIcon size={'11px'} styles={{ marginLeft: '.25rem' }} />,
    CHECKIN: (
      <CheckIcon
        size={'13px'}
        checkColor='#fff'
        circleColor='#00ba00'
        styles={{ marginLeft: '.25rem' }}
      />
    ),
    DELAYED: <DelayIcon size={'15px'} color={'#fff'} styles={{ marginLeft: '.25rem' }} />,
  };

  const getRightText = (activity) => {
    if (activity.amount > 0) {
      const amountStyle = {
        color: '#1DA844',
        fontSize: '.85rem',
      };

      return (
        <div className='text-right'>
          <Money value={activity.amount} lowDecimals decimal={false} />
          <div style={amountStyle}>
            <span className='mr-1'>{activity.commissionPercent ?? '0'} % </span>
            <Money value={activity.commissionAmount} lowDecimals decimal={false} />
          </div>
        </div>
      );
    } else {
      const statusClass = styles[activity.status.toLowerCase().replace(/\s+/g, '')] || '';
      let statusColorClass = '';

      if (activity.status === 'CONFIRMED') {
        statusColorClass = styles.greenCircle;
      } else if (activity.status === 'CANCELLED' || activity.status === 'REJECTED') {
        statusColorClass = styles.redCircle;
      }

      return (
        <div className={`${statusClass} font-extra-small ${styles.activityItemStatus}`}>
          {statusColorClass && (
            <span className={`${styles.statusCircle} ${statusColorClass}`}></span>
          )}
          <span style={{ fontSize: '.7rem' }}>{statusTexts[activity.status]}</span>

          {statusIcons[activity.status]}
        </div>
      );
    }
  };

  const getRightTextCampaign = (amount) => {
    if (amount.totalAmount > 0) {
      const amountStyle = {
        color: '#1DA844',
        fontSize: '.85rem',
      };

      return (
        <div className='text-right'>
          <Money value={amount.totalAmount} lowDecimals decimal={false} />
          <div style={amountStyle}>
            <span className='mr-1'>{amount.comissionPercentage ?? '0'} % </span>
            <Money value={amount.comissionAmount} lowDecimals decimal={false} />
          </div>
        </div>
      );
    }
  };

  return (
    <div
      key={index}
      className={`activity-item-container ${
        activity.status === 'PENDING' ? 'border-none p-0' : ''
      }`}
    >
      <div
        className={`row font-small ${styles.activityItem} ${
          activity.status === 'WAITING_DEPOSIT' ||
          activity.status === 'NO_SHOW' ||
          activity.status === 'REJECTED'
            ? 'text-secondary'
            : ''
        } ${activity.status === 'PENDING' ? styles.isCallcenter : ''}`}
        key={index}
        onClick={handleClick}
      >
        <div className={activity.status === 'PENDING' ? styles.activityLeftBorder : ''}></div>
        <div className='col-auto bold text-center pr-0' style={{ width: '70px' }}>
          <div>{isTodayFormat(date, locale, t)}</div>
          <div>{format(parseISO(date), 'HH:mm', { locale })}</div>
        </div>
        <div className='col text-truncate pl-2'>
          <div className={`${styles.activityItemInfo} bold text-truncate`}>
            {activity.restaurantName || activity.showName}
            {activity.hasDepositWarranty ? (
              <span className={styles.activityItemIcon}>
                <DolarActivityIcon
                  styles={{ marginLeft: '5px' }}
                  size={18}
                  color={
                    activity.status === 'WAITING_DEPOSIT' || activity.status === 'PENDING'
                      ? '#FFE500'
                      : '#00BA00'
                  }
                />
              </span>
            ) : null}
          </div>
          <div className='subtitle-text text-truncate'>
            {activity?.customerName} x {activity.pax || activity.paxs}
          </div>
          {activity.seller?.id === user.sid ?? (
            <div className='text-primary'>#{activity.seller.name}</div>
          )}
        </div>
        {isCampaign ? (
          <div className='col-auto my-auto'>{getRightTextCampaign(amount)}</div>
        ) : (
          <div className='col-auto my-auto'>{getRightText(activity, amount)}</div>
        )}
      </div>
    </div>
  );
};

export default ActivityReservationItem;
