import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Button from '../../components/Common/Button/Button';
import ContentApp from '../../components/ContentApp';
import SuccessCheck from '../../components/icons/SuccessCheck';
import FixedButton from '../../components/Common/FixedButton/FixedButton';
import styles from '../Activate/Activation.module.css';

const SuccessScreen = ({ title, subtitle, path, onClick }) => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <ContentApp>
      <div style={{ marginTop: '50%' }}>
        <div className='text-center'>
          <SuccessCheck size='135' color='var(--color-primary)' />
        </div>
        <div className='text-left mt-5 mx-2'>
          <h2 className='text-white'>{title}</h2>
          <div className='text-secondary font-medium'>{subtitle}</div>
        </div>
        <FixedButton>
          <Button
            text={t('continue')}
            size='large'
            className={`mt-2 ${styles.btn}`}
            onClick={() => {
              if(onClick) {
                onClick();
              }
              else if(path) {
                history.push(path)}}
              }
          />
        </FixedButton>
      </div>
    </ContentApp>
  );
};

export default SuccessScreen;
