import React from 'react';

export default function PaymentErrorIcon({ size = '16px', color = 'currentColor' }) {
  return (
    <svg
      version='1.0'
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 512.000000 512.000000'
      preserveAspectRatio='xMidYMid meet'
    >
      <g
        transform='translate(0.000000,512.000000) scale(0.100000,-0.100000)'
        fill={color}
        stroke='none'
      >
        <path
          d='M712 4196 c-208 -51 -389 -237 -431 -445 -8 -39 -11 -364 -11 -1091
0 -1139 -2 -1087 62 -1220 48 -99 162 -209 269 -259 46 -22 111 -44 143 -50
41 -8 443 -11 1268 -11 l1208 0 47 -34 c160 -114 364 -176 576 -176 561 1
1007 448 1007 1010 0 298 -128 580 -349 767 l-66 56 -5 491 -6 491 -27 80
c-50 149 -149 267 -284 338 -135 71 -25 67 -1773 66 -1243 -1 -1586 -3 -1628
-13z m3210 -316 c60 -17 119 -69 153 -135 29 -57 30 -63 34 -222 l3 -163
-1761 0 -1761 0 0 159 c0 93 5 172 12 192 24 73 88 138 162 166 41 15 3102 18
3158 3z m188 -910 c0 -38 -1 -70 -2 -70 -2 0 -38 7 -81 15 -109 21 -281 19
-392 -4 -392 -81 -705 -394 -786 -785 -25 -121 -25 -291 0 -411 16 -76 31
-123 88 -262 4 -10 -212 -13 -1059 -13 -1183 0 -1115 -4 -1198 67 -24 21 -53
60 -66 87 l-24 51 0 698 0 697 1760 0 1760 0 0 -70z m-110 -375 c193 -46 373
-190 460 -370 51 -104 70 -187 70 -305 0 -118 -19 -201 -70 -305 -66 -137
-178 -249 -315 -315 -104 -51 -187 -70 -305 -70 -117 0 -201 19 -305 70 -180
87 -324 266 -369 460 -20 83 -20 237 0 320 57 244 266 455 509 514 80 19 244
19 325 1z'
        />
        <path
          d='M1010 2069 c-14 -6 -36 -20 -48 -32 -78 -72 -68 -185 21 -251 27 -20
41 -21 392 -24 348 -3 366 -2 404 17 112 58 113 224 0 281 -36 19 -59 20 -391
19 -210 0 -363 -4 -378 -10z'
        />
        <path
          d='M3459 2357 c-69 -47 -96 -121 -68 -194 5 -15 58 -76 117 -135 l107
-108 -107 -107 c-111 -112 -128 -139 -128 -202 0 -73 78 -151 151 -151 63 0
90 17 202 128 l107 107 108 -107 c111 -111 138 -128 201 -128 73 0 151 78 151
151 0 63 -17 90 -128 202 l-107 107 107 108 c111 111 128 138 128 201 0 73
-78 151 -151 151 -63 0 -90 -17 -201 -128 l-108 -107 -107 107 c-60 59 -121
112 -136 117 -47 18 -101 13 -138 -12z'
        />
      </g>
    </svg>
  );
}
