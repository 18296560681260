import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { WelletContext } from '../../../context/WelletContext';
import { SkeletonBalancePerMonthCard } from '../../../components/Skeletons/SkeletonBalance';
import { useTranslation } from 'react-i18next';
import ContentApp from '../../../components/ContentApp';
import AccountHeader from '../../../components/AccountHeader/AccountHeader';
import BalancePerMonthCard from '../../../components/Balance/BalancePerMonthCard/BalancePerMonthCard';
import CustomerNav from '../../../components/CustomerNav/CustomerNav';
import EmptyActivity from '../../../components/Common/EmptyActivity/EmptyActivity';

const BalancePerMonthScreen = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const welletContext = useContext(WelletContext);
  const [allEarnings, setAllEarnings] = useState(null);
  const [loading, setLoading] = useState(null);

  const getAllEarnings = async () => {
    setLoading(true);
    try {
      const response = await welletContext.apis.tickets.get('/associates/GetAllEarnings');
      setAllEarnings(response.data);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllEarnings();
  }, []);

  return (
    <>
      <AccountHeader title={t('incomesPerMonth')} icon={'config'} pathName='/configuration' />
      <ContentApp menu header>
        {!loading ? (
          allEarnings?.details?.length === 0 ? (
            <EmptyActivity
              title={t('noIncomesPerMonth.title')}
              subtitle={t('noIncomesPerMonth.subtitle')}
            />
          ) : (
            allEarnings?.details?.map((yearDetails) =>
              yearDetails.months.map((monthDetails) => (
                <div className='mt-3 fade-up' key={`${yearDetails.year}-${monthDetails.month}`}>
                  <BalancePerMonthCard
                    month={monthDetails.month}
                    year={yearDetails.year}
                    amount={monthDetails.amount}
                  />
                </div>
              )),
            )
          )
        ) : (
          SkeletonBalancePerMonthCard()
        )}
        <CustomerNav history={history} />
      </ContentApp>
    </>
  );
};

export default BalancePerMonthScreen;
