import React from 'react';
import { QrReader as QrReaderLib } from 'react-qr-reader';
import './qr-reader.css';
import { useTranslation } from 'react-i18next';
import QrIcon from '../../icons/QrIcon';

export function QrReader({ onQRRead }) {
  const { t } = useTranslation();
  return (
    <div className='cashier-rectangle' style={{ backgroundColor: '#fff', borderRadius: '10px' }}>
      <div className='qr-reader-container p-3 mt-4 '>
        <QrReaderLib
          constraints={{ facingMode: 'environment' }}
          onResult={(result, error) => {
            if (!!result) {
              onQRRead(result?.text);
            }
          }}
          style={{ width: '100%' }}
          ViewFinder={() => {
            return (
              <div className='viewfinder'>
                <div className='viewfinder-content'></div>
              </div>
            );
          }}
        />
      </div>
      <div className='qr-footer'>
        <QrIcon color={'#000'} size={'20px'} /> {t('alignScanner')}
      </div>
    </div>
  );
}
