import React from 'react';
import NotificactionScreen from '../../components/Common/NotificactionScreen/NotificactionScreen';
import ContentApp from '../../components/ContentApp';
import { NoInternetIcon } from '../../components/icons/NoInternetIcon';
// import ContentApp from '../components/Common/ContentApp';

const OfflineScreen = () => {
  return (
    <ContentApp>
      <NotificactionScreen>
        <div className='d-flex justify-content-center w-100'>
          <NoInternetIcon color='var(--color-font-primary)' size='100px' />
        </div>
        <div className='px-2 mt-5 pt-5'>
          <h3
            className='font-large bold text-left pl-3'
            style={{ color: 'var(--color-font-primary)' }}
          >
            ¡Sin conexión!
          </h3>
          <p className='text-left pl-3 text-secondary'>
            Parece que no hay conexión a Internet. <br />
            Asegúrese de que el wifi o los datos móviles estén activados
          </p>
        </div>
      </NotificactionScreen>
    </ContentApp>
  );
};

export default OfflineScreen;
