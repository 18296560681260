import React from 'react';
import { format, parseISO } from 'date-fns';
import { useSelector } from 'react-redux';
import { getLocale } from '../../../helpers/dates';
import Money from '../../Common/Money/Money';

const ActivityBalanceItem = ({ index, activity, handleClick, date, className }) => {
  const app = useSelector((state) => state.app);
  const locale = getLocale(app.language);

  return (
    <div className={`row text-white ${className}`} key={index} onClick={handleClick}>
      <div className='col'>
        <div className='bold'>{activity?.showName}</div>
        <div className='font-small'>
          {(activity?.customerName || activity?.paxs) && (
            <div className='text-truncate text-secondary'>
              {activity?.customerName} x {activity.paxs}
            </div>
          )}

          <div className='text-secondary line-height-1'>
            {format(parseISO(date), 'PP HH:mm', {
              locale,
            })}
          </div>
          {!activity.isOwnSale && (
            <div style={{ color: 'var(--color-primary)' }} className='font-extra-small'>
              {activity.sellerName ? `#${activity.sellerName}` : ''}{' '}
              {activity.sellerNickName ? `(${activity.sellerNickName})` : ''}
            </div>
          )}
        </div>
      </div>
      <div className='col-auto my-auto'>
        <Money value={activity.amount} lowDecimals decimal={false} />
      </div>
    </div>
  );
};

export default ActivityBalanceItem;
