import React, { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { format, parseISO } from 'date-fns';
import { WelletContext } from '../../context/WelletContext';
import { formatDateNormal, getLocale } from '../../helpers/dates';
import { SkeletonReservationDetail } from '../Skeletons/SkeletonReservationDetail';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import ReservationItem from './ReservationItem';
import Money from '../Common/Money/Money';
import Line from '../Common/Line/Line';
import Modal from '../Common/Modal/Modal';

import ChevronIcon from '../icons/chevronIcon';
import Badge from '../Badge/Badge';
import SummaryBox from '../ReservationCheck/Summary/SummaryBox';
import CopyIcon from '../icons/copyIcon';
import { Toast } from '../Common/Toast/Toast';
import './reservation-details.css';
import Button from '../Common/Button/Button';
import ErrorBox from '../Common/ErrorBox/ErrorBox';
import { Spinner } from '../Common/Spinner/Spinner';

const ReservationDetail = ({ reservation }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const app = useSelector((state) => state.app);
  const isTeamLead = useSelector((state) => state.associates.roles.includes('salesteamlead'));
  const organizationName = useSelector((state) => state.associates.user.company.name);
  const welletContext = useContext(WelletContext);
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [cancellingReservation, setCancellingReservation] = useState(false);
  const [copied, setCopied] = useState(false);

  const handleCopyLink = () => {
    const link = reservation?.detail?.paymentInformation?.paymentLink;
    if (link) {
      navigator.clipboard.writeText(link);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    }
  };

  const reservationDate = new Date(reservation.reservationLocalDate);
  const reservationPaymentInfo = reservation?.detail?.reservationProducts?.paymentDetails;
  const locale = getLocale(app.language);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const onModifyClick = () => {
    if (reservation.reservationType === 'AREA' || reservation.reservationType === 'GROUP') {
      history.push({
        pathname: '/reservation/' + reservation.reservationType.toLowerCase(),
        search:
          '?showId=' +
          reservation.showId +
          '&refReservationId=' +
          reservation.id +
          '&refReferenceCode=' +
          reservation.referenceCode,
      });
    } else {
      const reservationInfo = {
        showId: reservation.showId,
        paxs: reservation.paxs,
        customerName: reservation.customerName,
        customerPhone: reservation.customerPhone,
        customerEmail: reservation.emailclient,
        comments: reservation.comments,
        occasion: reservation.occasion,
        reservationDate: reservation.reservationLocalDate,
        reservationId: reservation.id,
      };

      history.push({
        pathname: '/modify-reservation',
        state: reservationInfo,
      });
    }
  };

  const cancelReservation = async () => {
    setCancellingReservation(true);
    try {
      const response = await welletContext.apis.tickets.post(
        `/reservation/cancel/${reservation.id}`,
      );
      if (response?.status === 200) {
        setTimeout(() => {
          history.push('/');
        }, 1500);
      }
    } catch (error) {
      console.error(error);
      switch (error.response.data) {
        case 'INVALID_ID_RESERVATION':
          setErrorMsg(t('cancelReservationError.INVALID_ID_RESERVATION'));
          break;
        case 'USER_NOT_FOUND':
          setErrorMsg(t('cancelReservationError.USER_NOT_FOUND'));
          break;
        case 'NOT_ALLOWED':
          setErrorMsg(t('cancelReservationError.NOT_ALLOWED'));
          break;
        case 'INVALID_STATE':
          setErrorMsg(t('cancelReservationError.INVALID_STATE'));
          break;
        default:
          setErrorMsg(t('cancelReservationError.DEFAULT'));
      }
      setCancellingReservation(false);
    }
  };

  const handleCancel = () => {
    cancelReservation();
  };

  const getStatus = (reservationStatus) => {
    switch (reservationStatus) {
      case 'PENDING':
        return <Badge text={t('reservationStatus.pending')} type={'pending'} />;
      case 'WAITING_DEPOSIT':
        return <Badge text={t('reservationStatus.pendingPayment')} type={'pending'} />;
      case 'CONFIRMED':
      case 'CHECKIN':
        return <Badge text={t('reservationStatus.confirmed')} type={'active'} />;
      case 'CANCELLED':
        return <Badge text={t('reservationStatus.cancelled')} type={'cancelled'} />;
      case 'EXPIRED':
        return <Badge text={t('reservationStatus.expired')} type={'expired'} />;
      case 'REJECTED':
        return <Badge text={t('reservationStatus.rejected')} type={'cancelled'} />;
      case 'NO_SHOW':
      case 'NO_SHOW_MANUAL':
        return <Badge text={t('reservationStatus.noshow')} type={'expired'} />;
      default:
        return <Badge text={t('reservationStatus.finished')} type={'active'} />;
    }
  };

  const getStatusByCommission = (reservationCommission) => {
    switch (reservationCommission) {
      case 'DISPUTED':
        return t('reservationStatus.underReview');
      case 'PENDING_REVIEW':
        return t('reservationStatus.verifying');
      case 'REJECTED':
        return t('reservationStatus.rejected');
      case 'APPROVED':
        return t('reservationStatus.verifying');
      case 'ACCREDITED':
        return t('reservationStatus.paid');
      default:
        return <></>;
    }
  };

  const getCancelMessage = (cancelComment) => {
    switch (cancelComment) {
      case 'CANCELLED_BY_CUSTOMER':
        return t('cancelledCases.byCustomer');
      case 'CANCELLED_BY_CONCIERGE':
        return t('cancelledCases.byConcierge');
      case 'CANCELLED':
        return t('cancelledCases.cancelled', { organizationName: organizationName });
      case 'REFUND':
        return t('cancelledCases.refund');
      case 'CANCELLED_MISSING_PAYMENT':
        return t('cancelledCases.missingPayment');
      default:
        return cancelComment;
    }
  };

  const products = () => {
    if (
      !reservation?.detail?.reservationProducts?.products ||
      reservation?.detail?.reservationProducts?.products.length === 0
    )
      return;

    return (
      <div className='mt-4'>
        {reservation?.detail?.reservationProducts?.products?.map((p, index) => (
          <div key={index}>
            <div className='fw-semibold mt-2'>
              {p.productName} x {p.quantity}
            </div>
            {p.extraPaxs && p.extraPaxs > 0 ? (
              <div className='fw-semibold mt-2'>
                {p.productName} - {t('extraGuest')} x {p.extraPaxs}
              </div>
            ) : null}
          </div>
        ))}
      </div>
    );
  };

  const summary = (reservation) => {
    if (
      !reservation.detail.reservationProducts ||
      reservation.detail.reservationProducts.items === 0
    )
      return;

    return (
      <div className='section'>
        <div className='bold'>{t('summary')}</div>
        <div className='mt-2 light text-lighter-gray font-small'>
          <div className='row mt-1'>
            <div className='col pr-0'>{t('productsInfo.numberOfPeople')}:</div>
            <div className='col-auto'>{t('upToGuests', { pax: reservation?.paxs })}</div>
          </div>
          {/* {reservation?.detail?.reservationProducts?.items && (
            <div className='row mt-1'>
              <div className='col pr-0'>{t('productsInfo.priceDetailsFor')}:</div>
              <div className='col-auto'>
                {reservation?.detail?.reservationProducts?.items}{' '}
                {reservation?.detail?.reservationProducts?.items > 1 ? t('items') : t('item')}
              </div>
            </div>
          )} */}
          {reservation?.detail?.reservationProducts?.totalAmount > 0 && (
            <div className='row mt-1'>
              <div className='col pr-0'>Sub total:</div>
              <div className='col-auto'>
                <Money
                  value={reservation?.detail.reservationProducts?.subTotalAmount}
                  symbolSize='14px'
                  lowDecimals
                  decimal={false}
                />
              </div>
            </div>
          )}
          {reservation?.detail?.reservationProducts?.tipAmount &&
          reservation?.detail?.reservationProducts?.tipAmount > 0 ? (
            <div className='row mt-1'>
              <div className='col pr-0'>{t('productsInfo.tip')}:</div>
              <div className='col-auto'>
                <Money
                  value={reservation.detail.reservationProducts.tipAmount}
                  symbolSize='14px'
                  decimal={false}
                  lowDecimals
                />
              </div>
            </div>
          ) : null}
          {reservation?.detail?.reservationProducts?.taxes &&
          reservation?.detail?.reservationProducts?.taxes > 0 ? (
            <div className='row mt-1'>
              <div className='col pr-0'>{t('productsInfo.taxes')}:</div>
              <div className='col-auto'>
                <Money
                  value={reservation.detail.reservationProducts.taxes}
                  symbolSize='14px'
                  decimal={false}
                  lowDecimals
                />
              </div>
            </div>
          ) : null}
        </div>
        {reservation?.detail?.reservationProducts?.totalAmount > 0 && (
          <div className='row mt-2'>
            <div className='col bold font-small pr-0'>Total:</div>
            <div className='col-auto bold'>
              <Money
                value={reservation?.detail?.reservationProducts?.totalAmount}
                symbolSize='14px'
                decimal={false}
                lowDecimals
              />
            </div>
          </div>
        )}

        {reservation?.detail?.reservationProducts?.remainingAmount > 0 ? (
          <>
            <div className='row mt-1'>
              <div className='col bold pr-0'>{t('productsInfo.depositGuarantee')}:</div>
              <div className='col-auto bold'>
                <Money
                  value={reservation.detail.reservationProducts.depositAmount}
                  symbolSize='14px'
                  decimal={false}
                  lowDecimals
                />
              </div>
            </div>
            <div className='row mt-1'>
              <div className='col bold pr-0'>{t('productsInfo.balanceToBePaid')}:</div>
              <div className='col-auto bold'>
                <Money
                  value={reservation.detail.reservationProducts.remainingAmount}
                  symbolSize='14px'
                  decimal={false}
                  lowDecimals
                />
              </div>
            </div>
            {reservation?.detail?.reservationProducts?.remainingAmount > 0 && (
              <div className={`row justify-content-center my-4`}>
                <div className='col-4 pr-1 pl-2'>
                  <SummaryBox
                    title='Total'
                    value={reservation.detail.reservationProducts.totalAmount}
                  />
                </div>
                <div className='col-4 px-1'>
                  <SummaryBox
                    title={t('deposit')}
                    value={reservation.detail.reservationProducts.depositAmount}
                    subtitle={t('productsInfo.payUponConfirmation')}
                  />
                </div>
                <div className='col-4 pl-1 pr-2'>
                  <SummaryBox
                    title='Balance'
                    value={reservation.detail.reservationProducts.remainingAmount}
                    subtitle={t('productsInfo.payUponArrival')}
                  />
                </div>
              </div>
            )}
          </>
        ) : null}
      </div>
    );
  };

  const payments = () => {
    if (!reservationPaymentInfo || reservationPaymentInfo.length === 0) return;

    return (
      <div className='section'>
        <div className='bold'>{t('paymentInformation')}</div>
        {reservationPaymentInfo?.map((payment) => (
          <div className='row font-small mt-3' key={payment.chargeId}>
            <div className='col'>
              <div className='fw-bold '>
                {t('payment')} #{payment.chargeId}
              </div>
              <div className='text-secondary font-tiny mt-1'>
                <div>
                  <span className='text-capitalize'>{payment.brand}</span>
                  (XXXX XXXX XXXX {payment.last4})
                </div>
                <div>
                  {format(new Date(payment.paymentLocalDate), 'PPP HH:mm', {
                    locale,
                  })}
                </div>
              </div>
            </div>
            <div className='col-auto'>
              <Money value={payment.amount} />
            </div>
          </div>
        ))}
      </div>
    );
  };

  const policy = () => {
    if (reservation.policy)
      return (
        <div className='section terms-and-conditions'>
          <div className='bold'>{t('termsAndConditions')}</div>
          <div className='font-small' dangerouslySetInnerHTML={{ __html: reservation.policy }} />
        </div>
      );

    return null;
  };

  return (
    <>
      {loading || !reservation ? (
        SkeletonReservationDetail()
      ) : (
        <>
          <Modal open={isModalOpen} onClose={closeModal} animationClass='fade' hasBackground={true}>
            {reservation.reservationType === 'RESTAURANT' ? (
              <>
                <div className='text-white bold text-center'>{t('cancelReservation.title')}</div>
                <div className='font-small text-secondary mt-2 text-center'>
                  {t('cancelReservation.subtitle')}
                </div>
                <div className='mt-2'>
                  {errorMsg && <ErrorBox title={errorMsg} />}
                  <Button
                    className='btn-primary mt-3 mx-auto w-100'
                    size='medium'
                    fontSize={'16px'}
                    text={
                      cancellingReservation ? (
                        <div className='d-flex justify-content-center'>
                          <Spinner size={25} color='#fff' />
                        </div>
                      ) : (
                        <div>{t('yes')}</div>
                      )
                    }
                    onClick={handleCancel}
                    type='text'
                    backgroundColor='var(--color-primary)'
                    color='#000'
                    iconColor='#000'
                  />
                  <Button
                    className='btn-cancel mt-3 mx-auto w-100'
                    size='medium'
                    fontSize={'16px'}
                    text={'No'}
                    onClick={toggleModal}
                    type='text'
                    backgroundColor='var(--color-primary)'
                    color='#000'
                    iconColor='#000'
                  />
                </div>
              </>
            ) : (
              <div className='text-white text-center'>
                {t('toCancel')} {reservation?.organizationEmails?.map((p) => p)}
              </div>
            )}
          </Modal>
          <div className='order-details fade-up'>
            <div className='card px-4'>
              <div className='section'>
                <div className='row'>
                  <div className='col show-name'>{reservation.showName}</div>
                  <div className='col-auto my-auto pl-0'>{getStatus(reservation.status)}</div>
                </div>
                <div className='bold'>
                  <div className='mt-2'>{`${reservation.paxs} ${
                    reservation.paxs > 1 ? t('people') : t('person')
                  }`}</div>
                  <div className='mt-1'>{formatDateNormal(reservationDate, locale)}</div>

                  <div className='mt-1'>{`${format(reservationDate, 'H:mm')} hr`}</div>
                </div>
                {products()}
                {reservation?.detail?.reservationProducts?.consumptionAmount > 0 && (
                  <div className='consumption mt-4'>
                    {t('consumptionIncluded', {
                      consumption: '$' + reservation.detail.reservationProducts.consumptionAmount,
                    })}
                  </div>
                )}
              </div>

              <div className='section'>
                <div className='fw-semibold text-medium  text-secondary'>{t('bookingCode')}</div>
                <div className='font-normal bold mt-1'>{reservation.referenceCode}</div>

                <div className='fw-semibold text-medium text-secondary mt-3'>
                  {t('bookingHolder')}
                </div>
                <div className='font-normal bold mt-1'>{reservation.customerName}</div>

                {reservation.occasion && (
                  <>
                    <div className='fw-semibold text-medium text-secondary mt-3'>
                      {t('occasion')}
                    </div>
                    <div className='light mt-1 text-capitalize'>
                      {reservation.occasion.toLowerCase()}
                    </div>
                  </>
                )}

                {reservation.comments && (
                  <>
                    <div className='fw-semibold text-medium text-secondary mt-3'>
                      {t('transactionDetailContent.specialRequest')}
                    </div>
                    <div className='light mt-1'>{reservation.comments}</div>
                  </>
                )}
              </div>

              {summary(reservation)}
              {payments()}
              {policy()}
            </div>

            {reservation.detail.paymentInformation && (
              <div className='card p-4 mt-3'>
                <div className='bold'>{t('paymentLinkInformation')}</div>
                <div className='text-secondary text-medium fw-semibold mt-3'>
                  {t('paymentLink')}
                </div>
                <div className='position-relative text-truncate pr-5 mt-1'>
                  <a target='_noblank' href={reservation.detail.paymentInformation.paymentLink}>
                    {reservation.detail.paymentInformation.paymentLink}
                  </a>{' '}
                  <span
                    onClick={handleCopyLink}
                    style={{ position: 'absolute', right: '1.5rem', top: '-2px' }}
                  >
                    <CopyIcon size={19} fillColor='#00A3FF' />
                  </span>
                  {copied && (
                    <Toast top={false} type={'success'} message={t('linkCopied')} delay={3} />
                  )}
                </div>
                <div className='text-secondary text-medium fw-semibold mt-3'>{t('sent')}</div>
                {reservation?.detail?.paymentInformation?.customerEmail ? (
                  <div className='fw-semibold mt-1'>
                    {reservation?.detail?.paymentInformation?.customerEmail}
                  </div>
                ) : null}

                <div className='mt-1 fw-bold'>
                  {reservation?.detail?.paymentInformation?.customerPhone}
                </div>
                <div className='text-secondary text-medium fw-semibold mt-3'>
                  {t('dateAndLimitTime')}
                </div>
                <div className='fw-bold sentences-capitalize mt-1'>
                  {format(
                    new Date(reservation.detail.paymentInformation.expirePayment),
                    locale.code === 'es'
                      ? "EEEE, d 'de' MMMM 'a las' HH:mm 'hr'"
                      : "EEEE, d MMMM '-' HH:mm 'hr'",
                    {
                      locale,
                    },
                  )}
                </div>
                <div className='text-secondary text-medium fw-semibold mt-3'>{t('status')}</div>
                <div className='fw-bold mt-1'>
                  {t('paymentStatus.' + reservation.detail.paymentInformation.status)}
                </div>
              </div>
            )}
            {reservation.callCenterComment ||
            reservation.commissionComment ||
            reservation.cancelEvent ? (
              <div className='card p-3 mt-3'>
                <div className='bold'>{t('additionalComments')}</div>
                <Line color='#212121' className='row mt-3' />
                {reservation.callCenterComment && (
                  <>
                    <div className='semi-bold font-small mt-2 text-secondary'>
                      {format(parseISO(reservation.responseDate), "d MMM yyyy - HH:mm 'hr'", {
                        locale: locale,
                      })}
                    </div>
                    <div className='light'>{reservation.callCenterComment}</div>
                  </>
                )}
                {reservation.commissionComment && (
                  <>
                    <div className='semi-bold font-small mt-3 text-secondary'>
                      {format(
                        parseISO(reservation.dateCommissionComment),
                        "d MMM yyyy - HH:mm 'hr'",
                        {
                          locale: locale,
                        },
                      )}
                    </div>
                    <div className='light'>{reservation.commissionComment}</div>
                  </>
                )}
                {reservation.cancelEvent && (
                  <div className='light mt-2'>{getCancelMessage(reservation.cancelEvent)}</div>
                )}
              </div>
            ) : null}
            {reservation.consumes.map((p, index) => (
              <div className='card p-3 mt-3' key={index}>
                <div className='bold'>{`${t('consumptionInformation')} ${index + 1} ${t('of')} ${
                  reservation.consumes.length
                }`}</div>{' '}
                <Line color='#212121' className='row mt-3' />
                <ReservationItem
                  text1={t('tableOpeningDate')}
                  text2={
                    p.dateLocalTime ? (
                      <div className='font-tiny'>
                        {format(parseISO(p.dateLocalTime), "d MMM yyyy - HH:mm 'hr'", {
                          locale: locale,
                        })}
                      </div>
                    ) : (
                      '-'
                    )
                  }
                />
                <ReservationItem text1={t('numberDiners')} text2={p.pax > 0 ? p.pax : '-'} />
                <ReservationItem
                  text1={t('consumption')}
                  text2={
                    <Money value={p.consume} symbolSize='0.85rem' lowDecimals decimal={false} />
                  }
                />
              </div>
            ))}

            {reservation?.detail?.reservationProducts?.parentReservationReferenceCode &&
              reservation?.detail?.reservationProducts?.remainingDepositAmount > 0 &&
              reservation?.detail?.paymentInformation && (
                <div className='card text-secondary font-small p-3 mt-3'>
                  {t('reservationHasPayment', {
                    referenceCode:
                      reservation.detail.reservationProducts.parentReservationReferenceCode,
                    amount:
                      '$' + reservation.detail.reservationProducts.parentReservationPaidAmount,
                    totalAmount:
                      '$' + reservation.detail.reservationProducts.remainingDepositAmount,
                    date: format(
                      new Date(reservation?.detail?.paymentInformation?.expirePayment),
                      "PPPP HH:mm 'hr'",
                      { locale },
                    ),
                  })}
                </div>
              )}

            {reservation.commission > 0 || reservation.sellerCommission > 0 ? (
              <div className='card p-3 mt-3'>
                <div className='bold'>{t('earningCommission')}</div>
                <Line color='#212121' className='row mt-3' />
                <ReservationItem
                  text1={<div className='bold'>{t('commission')}</div>}
                  text2={
                    <div className='bold'>
                      +{' '}
                      <Money
                        value={isTeamLead ? reservation.sellerCommission : reservation.commission}
                        symbolSize='0.85rem'
                        lowDecimals
                        decimal={false}
                      />
                    </div>
                  }
                />

                <ReservationItem
                  text1={t('state')}
                  text2={getStatusByCommission(reservation.commissionStatus)}
                />
              </div>
            ) : null}

            {reservation?.canModify || reservation?.canCancel ? (
              <div className='card p-3 mt-3'>
                {reservation.canModify && (
                  <ReservationItem
                    onClick={() => onModifyClick()}
                    text1={
                      <div
                        style={{
                          color: 'var(--color-primary)',
                          fontSize: '1rem',
                        }}
                      >
                        {t('reservationActions.modify')}
                      </div>
                    }
                    text2={<ChevronIcon fillColor='var(--color-primary)' size='20px' />}
                  />
                )}
                {reservation?.canCancel && (
                  <ReservationItem
                    onClick={() => toggleModal()}
                    text1={
                      <div
                        style={{
                          color: 'var(--color-primary)',
                          fontSize: '1rem',
                        }}
                      >
                        {t('reservationActions.cancel')}
                      </div>
                    }
                    text2={<ChevronIcon fillColor='var(--color-primary)' size='20px' />}
                  />
                )}
              </div>
            ) : null}
          </div>
        </>
      )}
    </>
  );
};

export default ReservationDetail;
