import React, { useContext, useState } from 'react';
import Switch from '../../Common/Switch/Switch';
import { WelletContext } from '../../../context/WelletContext';

const NotificationSwitch = ({ name, type, isActive }) => {
  const welletContext = useContext(WelletContext);
  const [active, setActive] = useState(isActive);

  const handleActive = async () => {
    try {
      await welletContext.apis.tickets.patch(
        `/api/Notification/UpdateStatusNotificationType?NotificationType=${type}`,
      );
      setActive(!active);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className='d-flex justify-content-between align-items-center p-3'>
      <div className='bold'>{name}</div>
      <Switch isActive={active} toggleSwitch={handleActive} />
    </div>
  );
};

export default NotificationSwitch;
