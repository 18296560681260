import React from 'react';
import styles from './reservationDetail.module.css';

const ReservationItem = ({ text1, text2, onClick }) => {
  return (
    <>
      <div
        className={`d-flex font-small justify-content-between align-items-center light ${styles.border}`}
        onClick={onClick}
      >
        <div>{text1}</div>
        <div>{text2}</div>
      </div>
    </>
  );
};

export default ReservationItem;
