import React from 'react';
import { useHistory } from 'react-router-dom';
import ContentApp from '../../components/ContentApp';
import ErrorIcon from '../../components/icons/ErrorIcon';
import FixedButton from '../../components/Common/FixedButton/FixedButton';
import Button from '../../components/Common/Button/Button';
import styles from '../Activate/Activation.module.css';

const ErrorScreen = ({ title, subtitle, path, buttonText }) => {
  const history = useHistory();
  return (
    <ContentApp background={true}>
      <div className='mt-5 pt-5 px-2'>
        <div className='text-center'>
          <ErrorIcon size='115' />
        </div>
        <div className='mt-5'>
          <div className='font-large bold mt-4' style={{ color: '#fff' }}>
            {title}
          </div>
          <div className='mt-3 font-normal' style={{ color: 'var(--color-font-gray)' }}>
            {subtitle}
          </div>
        </div>
      </div>
      <FixedButton>
        <Button
          text={buttonText}
          color='#000'
          fontSize={'17px'}
          size='large'
          onClick={() => history.push(path)}
          className={`mt-2 ${styles.btn}`}
        />
      </FixedButton>
    </ContentApp>
  );
};

export default ErrorScreen;
