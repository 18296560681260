import React from 'react';

export function SecureIcon({ size = '16px', color = '#ccc' }) {
  return (
    <svg
      version='1.0'
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 512.000000 512.000000'
      preserveAspectRatio='xMidYMid meet'
    >
      <g
        transform='translate(0.000000,512.000000) scale(0.100000,-0.100000)'
        fill={color}
        stroke='none'
      >
        <path
          d='M2410 4145 c-304 -65 -532 -294 -594 -597 -12 -56 -16 -135 -16 -293
l0 -215 120 0 120 0 0 208 c0 149 5 225 15 271 44 187 199 342 386 386 276 65
558 -110 624 -386 10 -46 15 -122 15 -271 l0 -208 120 0 120 0 0 215 c0 241
-11 320 -61 442 -136 327 -504 521 -849 448z'
        />
        <path
          d='M1375 2906 c-41 -18 -83 -69 -91 -111 -3 -19 -4 -184 -1 -367 4 -290
7 -344 25 -418 122 -508 496 -877 1002 -986 119 -26 381 -26 500 0 513 111
894 491 1006 1006 16 74 19 131 19 437 0 339 -1 352 -21 379 -11 15 -33 37
-48 48 -27 21 -33 21 -1194 23 -955 2 -1173 0 -1197 -11z m2225 -463 c0 -260
-12 -367 -54 -491 -126 -370 -438 -635 -821 -697 -510 -83 -1003 223 -1158
720 -38 121 -47 213 -47 471 l0 234 1040 0 1040 0 0 -237z'
        />
        <path
          d='M2480 2347 c-49 -16 -133 -102 -148 -153 -27 -91 -8 -168 59 -237
l47 -49 4 -119 3 -119 33 -32 c28 -28 39 -33 82 -33 43 0 54 5 82 33 l33 32 3
119 4 119 47 49 c94 97 95 221 4 323 -65 72 -158 97 -253 67z'
        />
      </g>
    </svg>
  );
}
