import { Skeleton } from '@mui/material';
import React from 'react';

export const SkeletonPoints = () => {
  return (
    <div className='mt-2'>
      <Skeleton
        variant='rectangular'
        sx={{
          bgcolor: 'grey.900',
          width: '100%',
          borderRadius: '.4rem',
          height: 513,
        }}
      />
    </div>
  );
};

export const SkeletonCommissions = () => {
  return (
    <Skeleton
      variant='rectangular'
      sx={{
        bgcolor: 'grey.900',
        width: '100%',
        borderRadius: '.4rem',
        height: 265,
      }}
    />
  );
};

export const SkeletonTotalPoints = () => {
  return (
    <Skeleton
      variant='rectangular'
      sx={{
        bgcolor: 'grey.900',
        width: '100%',
        borderRadius: '.4rem',
        height: 22,
      }}
    />
  );
};
