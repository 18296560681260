import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';

const initTranslations = (lang) => {
  return i18next
    .use(HttpApi)
    .use(initReactI18next)
    .init({
      load: 'languageOnly',
      debug: false,
      fallbackLng: lang,
      backend: {
        loadPath: '/translations/{{lng}}/{{ns}}.json',
        allowMultiLoading: false,
        crossDomain: true,
        withCredentials: false,
        queryStringParams: { v: process.env.REACT_APP_TIMESTAMP },
      },
    });
};

export default initTranslations;
