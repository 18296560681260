import React from 'react';

const FaqIcons = () => {
  return (
    <svg width='14' height='17' viewBox='0 0 14 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M7.84848 4.25C7.84848 4.47543 7.75909 4.69163 7.59997 4.85104C7.44085 5.01045 7.22503 5.1 7 5.1C6.77497 5.1 6.55915 5.01045 6.40003 4.85104C6.24091 4.69163 6.15152 4.47543 6.15152 4.25C6.15152 4.02457 6.24091 3.80837 6.40003 3.64896C6.55915 3.48955 6.77497 3.4 7 3.4C7.22503 3.4 7.44085 3.48955 7.59997 3.64896C7.75909 3.80837 7.84848 4.02457 7.84848 4.25ZM6.36364 6.5875V10.8375C6.36364 11.0066 6.43068 11.1687 6.55002 11.2883C6.66936 11.4078 6.83123 11.475 7 11.475C7.16877 11.475 7.33064 11.4078 7.44998 11.2883C7.56932 11.1687 7.63636 11.0066 7.63636 10.8375V6.5875C7.63636 6.41842 7.56932 6.25627 7.44998 6.13672C7.33064 6.01717 7.16877 5.95 7 5.95C6.83123 5.95 6.66936 6.01717 6.55002 6.13672C6.43068 6.25627 6.36364 6.41842 6.36364 6.5875ZM0 2.125C0 1.56141 0.223484 1.02091 0.621289 0.622398C1.01909 0.223883 1.55863 0 2.12121 0H11.8788C12.1573 0 12.4332 0.0549648 12.6905 0.161756C12.9479 0.268547 13.1817 0.425074 13.3787 0.622398C13.5757 0.819723 13.7319 1.05398 13.8385 1.3118C13.9451 1.56961 14 1.84594 14 2.125V14.2375C14 14.4066 13.933 14.5687 13.8136 14.6883C13.6943 14.8078 13.5324 14.875 13.3636 14.875H1.27273C1.27273 15.1004 1.36212 15.3166 1.52124 15.476C1.68036 15.6354 1.89618 15.725 2.12121 15.725H13.3636C13.5324 15.725 13.6943 15.7922 13.8136 15.9117C13.933 16.0313 14 16.1934 14 16.3625C14 16.5316 13.933 16.6937 13.8136 16.8133C13.6943 16.9328 13.5324 17 13.3636 17H2.12121C1.55863 17 1.01909 16.7761 0.621289 16.3776C0.223484 15.9791 0 15.4386 0 14.875V2.125ZM12.7273 13.6V2.125C12.7273 1.89957 12.6379 1.68337 12.4788 1.52396C12.3196 1.36455 12.1038 1.275 11.8788 1.275H2.12121C1.89618 1.275 1.68036 1.36455 1.52124 1.52396C1.36212 1.68337 1.27273 1.89957 1.27273 2.125V13.6H12.7273Z'
        fill='white'
      />
    </svg>
  );
};

export default FaqIcons;
