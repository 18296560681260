import React from 'react';
import { Skeleton } from '@mui/material';
import ChevronIcon from '../../icons/chevronIcon';

const ActivitiesHome = ({
  history,
  title,
  linkTitle,
  children,
  pathName,
  loading,
  className = 'card',
  chevronColor = 'var(--color-primary)',
  activityFooter,
}) => {
  const SkeletonActivity = () => (
    <div className='row p-2'>
      <div className='col-3 pr-1'>
        <Skeleton
          variant='rectangular'
          sx={{ bgcolor: '#2a2d2d', width: '100%', borderRadius: '.4rem', height: 48 }}
        />
      </div>
      <div className='col-5 px-1'>
        <Skeleton
          variant='rectangular'
          sx={{ bgcolor: '#2a2d2d', width: '100%', borderRadius: '.4rem', height: 48 }}
        />
      </div>
      <div className='col-4 pl-1'>
        <Skeleton
          variant='rectangular'
          sx={{ bgcolor: '#2a2d2d', width: '100%', borderRadius: '.4rem', height: 48 }}
        />
      </div>
    </div>
  );

  const skeletonActivities = Array.from({ length: 5 }, (_, index) => (
    <SkeletonActivity key={index} />
  ));

  return (
    <>
      <div className={className}>
        {title && (
          <>
            <div className='card-header pl-0 d-flex'>
              <div className='col semi-bold'>{title}</div>
            </div>
            <div className='card-bottom'></div>
          </>
        )}
        <>
          {loading ? (
            skeletonActivities
          ) : (
            <>
              {children}
              {activityFooter && activityFooter}
              {linkTitle && (
                <>
                  <div className='card-bottom'></div>
                  <div
                    className='card-footer font-small pl-0'
                    style={{ color: 'var(--color-primary)' }}
                  >
                    <div
                      className='d-flex align-items-center col justify-content-between'
                      onClick={() => history.push(pathName)}
                    >
                      <span className='link-title'>{linkTitle}</span>
                      <ChevronIcon
                        fillColor={chevronColor}
                        size={'18px'}
                        styles={{ marginRight: '-13px' }}
                      />
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </>
      </div>
    </>
  );
};

export default ActivitiesHome;
