import React from 'react';

const ItemProfile = ({ title, subtitle, className }) => {
  return (
    <div className={`feed-item px-3 pb-2 pt-2 border-bottom border-secondary ${className}`}>
      <div className='item-title pl-1 bold'>{title}</div>
      <div className='text-secondary d-flex justify-content-between align-items-center profile-phone pl-1'>
        {subtitle}
      </div>
    </div>
  );
};

export default ItemProfile;
