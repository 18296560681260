import React, { useState, useEffect } from 'react';
import { Skeleton } from '@mui/material';
import styles from './Qr.module.css';
import { getQrImage } from '../../../helpers/qr';

const Qr = ({ qrUrl, welletContext, format = 3, corner = 0, logo = null, logoBorder }) => {
  const [loadingImage, setLoadingImage] = useState(true);
  const [qrImage, setQrImage] = useState(null);

  useEffect(() => {
    const fetchQr = async () => {
      setLoadingImage(true);
      await getQrImage(welletContext, qrUrl, setQrImage, { format, corner, logo, logoBorder });
      setLoadingImage(false);
    };

    fetchQr();
  }, []);

  return (
    <div className={styles.qrContainer}>
      {loadingImage && (
        <Skeleton
          sx={{
            bgcolor: 'grey.900',
            borderRadius: '20px',
            position: 'absolute',
            width: '100%',
            height: '100%',
          }}
          variant='rectangular'
        />
      )}
      {qrImage ? (
        <img
          src={qrImage}
          alt={'QR'}
          style={{ display: loadingImage ? 'none' : 'block' }}
          className={styles.userQr}
        />
      ) : null}
    </div>
  );
};

export default Qr;
