export const setExtraProductList = (products) => ({
  type: 'SET_EXTRA_PRODUCT',
  payload: products,
});

export const addExtraProduct = (product) => ({
  type: 'ADD_EXTRA_PRODUCT',
  payload: product,
});

export const removeExtraProduct = (product) => ({
  type: 'REMOVE_EXTRA_PRODUCT',
  payload: product,
});


