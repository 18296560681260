import React from 'react';

const CallcenterIcon = ({ size, color, styles }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      version='1.0'
      width={size}
      height={size}
      viewBox='0 0 512.000000 512.000000'
      preserveAspectRatio='xMidYMid meet'
      style={styles}
    >
      <g
        transform='translate(0.000000,512.000000) scale(0.100000,-0.100000)'
        fill={color}
        stroke='none'
      >
        <path d='M2390 4424 c-14 -2 -59 -9 -100 -15 -248 -35 -504 -136 -736 -290 -151 -101 -362 -312 -464 -464 -83 -126 -163 -283 -210 -414 -32 -89 -33 -91 -65 -91 -55 0 -160 -38 -224 -81 -123 -82 -216 -221 -263 -394 -18 -68 -22 -107 -22 -255 0 -188 9 -247 61 -385 62 -166 188 -322 307 -380 81 -40 190 -46 305 -16 149 37 201 83 201 178 0 23 -30 236 -66 475 -72 476 -73 491 -32 677 42 199 143 422 262 581 84 112 260 281 366 351 262 174 542 259 850 259 397 0 756 -145 1044 -420 225 -216 370 -474 434 -771 39 -178 38 -213 -13 -549 l-46 -305 -55 -114 c-210 -438 -584 -730 -1069 -837 -59 -13 -147 -19 -340 -23 -169 -4 -267 -10 -280 -18 -31 -18 -73 -65 -84 -96 -16 -40 -14 -191 3 -230 17 -43 78 -95 118 -102 18 -3 160 -5 315 -3 l281 3 39 31 c48 39 73 87 73 144 l0 45 133 43 c271 88 490 223 698 431 74 75 157 166 183 204 49 69 62 76 109 58 14 -6 60 -17 102 -26 141 -29 252 10 369 127 79 79 138 173 179 283 52 138 61 197 61 385 -1 206 -15 277 -89 427 -86 174 -205 267 -378 295 l-78 13 -29 85 c-63 181 -178 385 -300 535 -83 102 -242 250 -350 328 -113 80 -338 194 -468 237 -178 58 -318 81 -522 85 -102 2 -196 2 -210 -1z' />
        <path d='M2360 3865 c-453 -74 -830 -394 -969 -822 -46 -142 -56 -214 -56 -393 0 -100 5 -192 14 -235 41 -210 152 -439 274 -573 l40 -42 -41 -185 c-32 -149 -38 -192 -31 -220 13 -46 55 -78 94 -72 17 3 107 48 201 100 l169 96 103 -35 c587 -196 1225 59 1498 598 85 170 123 317 131 514 15 348 -100 660 -332 903 -104 109 -214 191 -339 251 -116 56 -167 74 -286 101 -113 26 -353 34 -470 14z m-322 -1011 c216 -107 139 -434 -103 -434 -245 0 -322 325 -104 434 71 35 137 35 207 0z m593 16 c19 -5 55 -27 79 -47 162 -139 64 -403 -150 -403 -129 0 -230 101 -230 230 0 157 146 263 301 220z m656 -14 c179 -84 170 -345 -14 -423 -84 -34 -206 -4 -264 66 -159 192 52 462 278 357z' />
      </g>
    </svg>
  );
};

export default CallcenterIcon;
