import React from 'react';
import styles from './customerSpecialRequest.module.css';
import SolidChevron from '../../icons/SolidChevron';
import { useTranslation } from 'react-i18next';

const CustomerSpecialRequest = ({ values, handleChange, open, setOpen }) => {
  const { t } = useTranslation();
  return (
    <div className='my-2 px-3'>
      <div className={`row text-white ${styles.card}`}>
        <p
          className={`mb-0 mr-2 bold text-center`}
          onClick={() => setOpen(!open)}
        >
          {t('specialRequest')}
        </p>
        <SolidChevron size='20px' color='var(--color-primary)' rotation={open ? -180 : 0} />
        {open && (
          <div className={`mt-3 ${styles.textareaContainer} fade-up`}>
            <textarea
              name='comments'
              placeholder={t('requestHere')}
              value={values.comments}
              onChange={handleChange}
              className={`p-3 ${styles.textarea}`}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomerSpecialRequest;
