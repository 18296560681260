import { useState, useEffect } from 'react';

const useForm = (callback, validate) => {
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      callback();
    }
  }, [errors]);

  const handleSubmit = (event) => {
    if (event) event.preventDefault();

    const trimmedValues = Object.keys(values).reduce((acc, key) => {
      const value = values[key];
      acc[key] = typeof value === 'string' ? value.trim() : value;
      return acc;
    }, {});

    setValues(trimmedValues);
    setErrors(validate(trimmedValues));
    setIsSubmitting(true);
  };

  const handleChange = (event, key, value) => {
    if (event) {
      event.persist();
      const { name, value } = event.target;
      setValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: '',
      }));
    } else if (key) {
      setValues((prevValues) => ({
        ...prevValues,
        [key]: value,
      }));
      setErrors((prevErrors) => ({
        ...prevErrors,
        [key]: '',
      }));
    }
  };

  return {
    handleChange,
    handleSubmit,
    values,
    errors,
  };
};

export default useForm;
