import React from 'react';

const LinkIcon = ({ size, color, styles }) => {
  return (
    <svg
      enableBackground='new 0 0 24 24'
      fill={color}
      height={size}
      id='Layer_1'
      version='1.1'
      viewBox='0 0 24 24'
      width={size}
      style={styles}
    >
      <g>
        <path d='M16.9,7.1c-0.4-0.4-1-0.4-1.4,0l-8.4,8.4c-0.4,0.4-0.4,1,0,1.4c0.4,0.4,1,0.4,1.4,0l8.4-8.4C17.3,8.1,17.3,7.5,16.9,7.1z' />
        <path d='M8.5,22.5L12,19c1.4-1.4,1.8-3.5,1.1-5.3l-1.7,1.7c0,0.8-0.2,1.6-0.9,2.3l-3.5,3.5c-1.2,1.2-3.1,1.2-4.2,0   c-1.2-1.2-1.2-3.1,0-4.2l3.5-3.5c0.6-0.6,1.5-0.9,2.3-0.9l1.7-1.7C8.5,10.2,6.4,10.5,5,12l-3.5,3.5c-1.9,1.9-1.9,5.1,0,7   C3.4,24.5,6.6,24.5,8.5,22.5z' />
        <path d='M13.4,6.4l3.5-3.5c1.2-1.2,3.1-1.2,4.2,0s1.2,3.1,0,4.2l-3.5,3.5c-0.6,0.6-1.5,0.9-2.3,0.9l-1.7,1.7   c1.8,0.7,3.9,0.3,5.3-1.1l3.5-3.5c1.9-1.9,1.9-5.1,0-7c-1.9-1.9-5.1-1.9-7,0L12,5c-1.4,1.4-1.8,3.5-1.1,5.3l1.7-1.7   C12.5,7.8,12.8,7,13.4,6.4z' />
      </g>
    </svg>
  );
};

export default LinkIcon;
