import React from 'react';
import { format, parseISO } from 'date-fns';
import { useSelector } from 'react-redux';
import { getLocale } from '../../helpers/dates';
import Money from '../../components/Common/Money/Money';

const CashierItem = ({ index, activity, handleClick, date, isLastItem, className }) => {
  const app = useSelector((state) => state.app);
  const locale = getLocale(app.language);

  return (
    <>
      <div
        className={`row text-white font-small align-items-center ${className}`}
        key={index}
        onClick={handleClick}
      >
        <div className='col-auto bold'>
          <div>{format(parseISO(date), 'dd MMM', { locale })}</div>
          <div>{format(parseISO(date), 'HH:mm', { locale })}</div>
        </div>
        <div className='col pl-0' style={{ width: '140px' }}>
          <div className='bold text-truncate'>
            {' '}
            {activity?.affiliateUserName || activity?.customerName}
          </div>
          <div className='text-secondary font-smaller'>{activity.restaurant}</div>
        </div>

        <div className='col-auto text-right'>
          {' '}
          <Money value={activity.amount} lowDecimals decimal={false} />
        </div>
      </div>

      {!isLastItem && (
        <div
          style={{
            borderBottom: '1px solid #212121',
            margin: '0 0.5rem',
          }}
        ></div>
      )}
    </>
  );
};

export default CashierItem;
