import React from 'react';
export function SuccessIcon({ size = '16px', color = '#ccc' }) {
  return (
    <svg width={size} height={size} viewBox='0 0 32 32'>
      <desc />
      <g>
        <circle
          cx='16'
          cy='16'
          fill='none'
          r='15'
          stroke={color}
          strokeLinejoin='round'
          strokeMiterlimit='10'
          strokeWidth='2'
        />
        <polyline
          fill='none'
          points='7,19 11,23 25,9'
          stroke={color}
          strokeLinejoin='round'
          strokeMiterlimit='10'
          strokeWidth='2'
        />
      </g>
    </svg>
  );
}
