//@ts-nocheck
import React, { useState, createRef } from 'react';
import './input-single-character.css';

export function InputSingleCharacter({ length, onValueChange, error }) {
  const numerOfInputs = length;
  const className = `field text-center ${error ? 'error' : ''}`;

  const [inputRefsArray] = useState(() => Array.from({ length: numerOfInputs }, () => createRef()));

  const [letters, setLetters] = useState(() => Array.from({ length: numerOfInputs }, () => ''));

  const onDigitKeyPress = ({ nativeEvent }, index) => {
    if (inputRefsArray[index].current.value !== '') return;
    else if (nativeEvent.key && nativeEvent.key === 'Backspace') {
      if (index > 0) {
        inputRefsArray[index - 1].current.focus();
      }
    }
  };

  const onChangeValue = (e, index) => {
    const { value } = e.target;
    const result = letters.map((letter, letterIndex) => (letterIndex === index ? value : letter));

    setLetters(result);
    onValueChange(result.join(''));

    if (index < inputRefsArray.length - 1 && value.length > 0) {
      inputRefsArray[index + 1].current.focus();
      inputRefsArray[index + 1].current.select();
    }
  };

  const onPaste = (e, index) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData('text');

    if (pastedData.length === numerOfInputs) {
      const result = pastedData.split('');
      setLetters(result);
      onValueChange(pastedData);
      inputRefsArray[numerOfInputs - 1].current.focus();
      inputRefsArray[numerOfInputs - 1].current.select();
    }
  };

  return (
    <div className={className}>
      <div className='row justify-content-center position-relative input-single-character'>
        {inputRefsArray.map((ref, index) => {
          return (
            <input
              ref={ref}
              key={index}
              type='tel'
              id={`box${index}-1`}
              onKeyDown={(e) => onDigitKeyPress(e, index)}
              onChange={(e) => onChangeValue(e, index)}
              onPaste={(e) => onPaste(e, index)}
              onClick={(e) => {
                e.target.select();
              }}
              value={letters[index]}
              maxLength={1}
              className='col-2 p-0'
            />
          );
        })}
      </div>
    </div>
  );
}
