import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { WelletContext } from '../../../context/WelletContext';
import { useSelector } from 'react-redux';
import ContentApp from '../../../components/ContentApp';
import Money from '../../../components/Common/Money/Money';
import Slider from '../../../components/Common/Slider/Slider';
import Button from '../../../components/Common/Button/Button';
import styles from './reservationTicketInfo.module.css';
import FixedButton from '../../../components/Common/FixedButton/FixedButton';
import { skeletonProductInfo } from '../../../components/Skeletons/skeletonProductInfo';
import ImageModal from '../../../components/Common/ImageModal/ImageModal';

const ReservationTicketInfo = ({
  productId,
  handleIncrement,
  prices,
  toggleModal,
  isModalOpen,
  showAddButton = true,
}) => {
  const { t } = useTranslation();
  const sliderRef = useRef();
  const welletContext = useContext(WelletContext);
  const language = useSelector((state) => state.app.language);
  const [open, setOpen] = useState(false);
  const [url, setUrl] = useState(false);
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleClickImage = (image) => {
    setOpen(true);
    setUrl(image);
  };

  const getProduct = async () => {
    setLoading(true);
    try {
      const response = await welletContext.apis.tickets.get(
        `/products/get/${productId}?lang=${language}`,
      );
      setProduct(response.data);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isModalOpen) {
      getProduct();
    }
  }, [productId]);

  return (
    <>
      {open && (
        <ImageModal onClose={() => setOpen(false)} hasBackground={true}>
          <img src={url} alt={product.name} />
        </ImageModal>
      )}
      <ContentApp>
        <div className={`${styles.container}`}>
          {!loading ? (
            <>
              <div className='font-large bold'>{product?.name}</div>
              <div className='font-small light mt-1'>
                {product?.pax > 0 && t('upToGuests', { pax: product?.pax })}
                {product?.extraPax && product?.extraPax > 0 ? (
                  <span>
                    {' '}
                    + {product.extraPax} {t('extraGuests')} (<Money value={prices?.priceExtraPax} />{' '}
                    {t('each')})
                  </span>
                ) : null}
              </div>
              <div className='font-medium d-flex align-items-center mt-1'>
                <span className='bold'>
                  <Money value={prices?.price} symbolSize='20px' decimal={false} />
                </span>
                {prices?.consumption > 0 && (
                  <span className='ml-3 credit-consume'>
                    {t('consumptionCredit')} <Money value={prices.consumption} />
                  </span>
                )}
              </div>
              <div className='mt-3'>
                {product?.images.length > 0 && (
                  <Slider
                    ref={sliderRef}
                    slideSpacing={10}
                    initialSlide={0}
                    touchScroll={true}
                    showBtns={false}
                    visibleSlides={'all'}
                  >
                    {product?.images.map((i, index) => (
                      <div
                        key={index}
                        onClick={() => handleClickImage(i)}
                        className={`${styles.imageContainer} position-relative`}
                        style={{
                          backgroundImage: `url(${i})`,
                        }}
                      >
                        <div className={styles.seeMore}>+</div>
                      </div>
                    ))}
                  </Slider>
                )}
                {product?.description && (
                  <>
                    <div className='mt-4 text-secondary font-normal bold'>{t('description')}</div>
                    <div className='mt-1 font-1rem bold'>{t('includes')}</div>
                    <div
                      className='mt-3 font-small'
                      dangerouslySetInnerHTML={{ __html: product?.description }}
                    />
                  </>
                )}

                {product?.termsAndConditions && (
                  <>
                    <div
                      className={`mt-3 ${styles.termsAndConditions}`}
                      dangerouslySetInnerHTML={{ __html: product?.termsAndConditions }}
                    />
                  </>
                )}
              </div>
            </>
          ) : (
            skeletonProductInfo()
          )}
        </div>
        {showAddButton ? (
          <FixedButton backgroundColor={'#000'}>
            <Button
              size='large'
              text={<div className='bold'>{t('addToReservation')}</div>}
              className='mt-2 mb-3'
              icon={true}
              typeIcon='add'
              onClick={() => {
                handleIncrement(productId);
                toggleModal();
              }}
            />
          </FixedButton>
        ) : null}
      </ContentApp>
    </>
  );
};

export default ReservationTicketInfo;
