import { Skeleton } from '@mui/material';
import React from 'react';

const SkeletonCities = () => {
  return (
    <>
      <div className='mt-4'>
        <Skeleton
          variant='rectangular'
          sx={{ bgcolor: 'grey.900', width: '100%', borderRadius: '20px', height: 112 }}
        />
      </div>
      <div className='mt-2'>
        <Skeleton
          variant='rectangular'
          sx={{ bgcolor: 'grey.900', width: '100%', borderRadius: '20px', height: 112 }}
        />
      </div>
      <div className='mt-2'>
        <Skeleton
          variant='rectangular'
          sx={{ bgcolor: 'grey.900', width: '100%', borderRadius: '20px', height: 112 }}
        />
      </div>
      <div className='mt-2'>
        <Skeleton
          variant='rectangular'
          sx={{ bgcolor: 'grey.900', width: '100%', borderRadius: '20px', height: 112 }}
        />
      </div>
      <div className='mt-2'>
        <Skeleton
          variant='rectangular'
          sx={{ bgcolor: 'grey.900', width: '100%', borderRadius: '20px', height: 112 }}
        />
      </div>
    </>
  );
};

export default SkeletonCities;
