import React from 'react';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import { alignTimezone, getDateFromOffset, getLocale } from '../../../helpers/dates';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import './calendar.css';

export const generateDisabledDates = (startDate, endDate, locale) => {
  const disabledDates = [];
  const currentDate = new Date(startDate);
  while (currentDate < endDate) {
    disabledDates.push(format(currentDate, 'yyyy-MM-dd', { locale }));
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return disabledDates;
};

export function Calendar({
  minDate,
  maxDate,
  disabledDates,
  selectedDate,
  onSelectDate,
  className = 'calendar',
}) {
  const language = useSelector((state) => state.app.language);
  const locale = getLocale(language);
  const user = useSelector((state) => state.associates.user);
  const today = new Date(getDateFromOffset(user.companyUtcMinutesOffset));
  const renderCalendar = () => {
    let month = selectedDate ? alignTimezone(new Date(selectedDate)) : today;
    if (!selectedDate && minDate) {
      month = alignTimezone(new Date(minDate));
    }

    return (
      <div className={className}>
        <DayPicker
          locale={locale}
          className='day-picker-buytickets'
          mode='single'
          disabled={disabledDates ? getDisabledDays(disabledDates) : []}
          selected={selectedDate ? alignTimezone(new Date(selectedDate)) : null}
          onSelect={(day) => onSelectDate(day)}
          defaultMonth={month}
          fromDate={alignTimezone(new Date(minDate))}
          toDate={alignTimezone(new Date(maxDate))}
        />
      </div>
    );
  };

  const getDisabledDays = (disabledDates) => {
    const specificDays = disabledDates.map((d) => alignTimezone(new Date(d)));
    return [
      ...specificDays,
      {
        before: alignTimezone(new Date(minDate)),
        after: alignTimezone(new Date(maxDate)),
      },
    ].filter((day) => !isToday(day));
  };

  const isToday = (date) => {
    const today = new Date();
    if (!(date instanceof Date)) {
      return false;
    }
    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    );
  };

  return <div>{renderCalendar()}</div>;
}

export default Calendar;
