import React from 'react';

const CalendarActiveIcon = ({ size = '25px', color = 'var(--color-primary)' }) => {
  return (
    <svg
      id='b'
      data-name='Capa 2'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 36.3 36.78'
      width={size}
      height={size}
    >
      <g id='c' data-name='Capa 1'>
        <g>
          <rect x='6.05' y='16.98' width='3.28' height='3.28' fill={color} strokeWidth='0' />
          <rect x='6.05' y='26.17' width='3.28' height='3.28' fill={color} strokeWidth='0' />
          <rect x='26.76' y='16.98' width='3.29' height='3.28' fill={color} strokeWidth='0' />
          <path
            d='M31.65,2.4h-.47v4.53c0,.54-.45.99-1,.99s-.99-.45-.99-.99V.75c0-.42-.34-.75-.75-.75s-.75.33-.75.75v1.65h-3.71v4.53c0,.54-.45.99-1,.99s-.99-.45-.99-.99V.75c0-.42-.34-.75-.75-.75s-.75.33-.75.75v1.65h-3.31v4.53c0,.54-.44.99-.99.99-.52,0-.94-.4-.98-.9-.01-.03-.01-.06-.01-.09V.75c0-.42-.34-.75-.75-.75s-.75.33-.75.75v1.65h-3.31v4.53c0,.54-.45.99-1,.99s-.99-.45-.99-.99V.75c0-.42-.34-.75-.75-.75s-.75.33-.75.75v1.65h-2.25C2.09,2.4,0,4.48,0,7.05v2.9h36.3v-2.9c0-2.57-2.08-4.65-4.65-4.65Z'
            fill={color}
            strokeWidth='0'
          />
          <path
            d='M0,32.13c0,2.56,2.09,4.65,4.65,4.65h27c2.57,0,4.65-2.09,4.65-4.65V11.95H0v20.18ZM25.02,16.23c0-.55.45-1,1-1h4.78c.55,0,1,.45,1,1v4.78c0,.56-.45,1-1,1h-4.78c-.55,0-1-.44-1-1v-4.78ZM25.02,25.42c0-.55.45-1,1-1h4.78c.55,0,1,.45,1,1v4.78c0,.55-.45,1-1,1h-4.78c-.55,0-1-.45-1-1v-4.78ZM15.44,18.03c.39-.38,1.03-.38,1.41.02l.86.86,2.46-2.82c.37-.42,1-.46,1.42-.1.41.37.45,1,.09,1.41l-3.18,3.64c-.18.21-.44.34-.72.34h-.03c-.27,0-.53-.1-.71-.29l-1.61-1.64c-.39-.39-.39-1.03.01-1.42ZM14.66,25.42c0-.55.45-1,1-1h4.78c.55,0,1,.45,1,1v4.78c0,.55-.45,1-1,1h-4.78c-.55,0-1-.45-1-1v-4.78ZM4.3,16.23c0-.55.45-1,1-1h4.78c.55,0,1,.45,1,1v4.78c0,.56-.45,1-1,1h-4.78c-.55,0-1-.44-1-1v-4.78ZM4.3,25.42c0-.55.45-1,1-1h4.78c.55,0,1,.45,1,1v4.78c0,.55-.45,1-1,1h-4.78c-.55,0-1-.45-1-1v-4.78Z'
            fill={color}
            strokeWidth='0'
          />
          <rect x='26.76' y='26.17' width='3.29' height='3.28' fill={color} strokeWidth='0' />
          <rect x='16.41' y='26.17' width='3.28' height='3.28' fill={color} strokeWidth='0' />
        </g>
      </g>
    </svg>
  );
};

export default CalendarActiveIcon;
