import React, { useContext, useState } from 'react';
import { WelletContext } from '../../context/WelletContext';
import { useTranslation } from 'react-i18next';
import useForm from '../../helpers/useForm';
import Header from '../../components/Common/Header/Header';
import ContentApp from '../../components/ContentApp';
import ButtonProccessing from '../../components/Common/ButtonProccessing/ButtonProccessing';
import ErrorBox from '../../components/Common/ErrorBox/ErrorBox';
import InputFormNew from '../../components/Common/InputFormNew/InputFormNew';
import FixedButton from '../../components/Common/FixedButton/FixedButton';
import SuccessScreen from '../Common/SuccessScreen';
import ProcessingScreen from '../Common/ProcessingScreen';

const Activation = ({ history }) => {
  const ButtonStatus = {
    Normal: 'NORMAL',
    InProgress: 'INPROGRESS',
    ProgressFinished: 'FINISHED',
    Error: 'ERROR',
  };
  const { t } = useTranslation();
  const welletContext = useContext(WelletContext);
  const [btnStatus, setBtnStatus] = useState(ButtonStatus.Normal);
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);

  function validate(values) {
    let errors = {};
    if (!values.email) {
      errors.email = t('emailError.EMPTY_EMAIL');
    }
    if (!values.nickName) {
      errors.nickName = t('nameError.REFERENCE_REQUIRED');
    } else if (values.nickName.length < 5 || values.nickName.length > 30) {
      errors.nickName = t('nameError.REFERENCE_CHARACTERS');
    }

    return errors;
  }
  const sendForm = async () => {
    setBtnStatus(ButtonStatus.InProgress);
    setLoading(true);
    try {
      const response = await welletContext.apis.tickets.put('/associates/AssociateUserToRp', {
        email: values.email,
        nickName: values.nickName,
      });
      if (response.status === 200) {
        setLoading(false);
        setResponse(true);
      }
    } catch (error) {
      setBtnStatus(ButtonStatus.Error);

      let errorMessage;
      switch (error.response.data) {
        case 'USER_NOT_FOUND':
          errorMessage = t('userError.USER_NOT_FOUND');
          break;
        case 'HAS_SALES_LEADER':
          errorMessage = t('userError.HAS_SALES_LEADER');
          break;
        case 'USER_INACTIVE':
          errorMessage = t('userError.USER_INACTIVE');
          break;
        case 'USER_KYC_ERROR':
          errorMessage = t('userError.USER_KYC_ERROR');
          break;
        default:
          errorMessage = t('userError.DEFAULT');
      }
      setError(errorMessage);
      setResponse(false);
      setLoading(false);
    }
  };

  const { values, errors, handleChange, handleSubmit } = useForm(sendForm, validate);

  const onChangeEmail = (e) => {
    handleChange(e);
    setError(null);
    setBtnStatus(ButtonStatus.Normal);
  };

  const inputEmail = (
    <InputFormNew
      name='email'
      type='text'
      placeholder={t('linkAffiliate.emailPlaceholder')}
      onChange={onChangeEmail}
      value={values.email}
      error={errors.email}
      forceLowecase={true}
    />
  );

  const nickName = (
    <InputFormNew
      name='nickName'
      type='text'
      placeholder={t('linkAffiliate.positionPlaceholder')}
      onChange={onChangeEmail}
      value={values.nickName}
      error={errors.nickName}
      forceLowecase={true}
    />
  );

  return (
    <>
      {loading ? (
        <ProcessingScreen title={t('wait')} subtitle={t('linkAffiliate.linkingAccount')} />
      ) : response ? (
        <SuccessScreen
          title={`${t('done')}!`}
          subtitle={`${t('activationSuccessText1')} ${values.email} ${t('activationSuccessText2')}`}
          path={'/'}
        />
      ) : (
        <>
          <Header title={t('linkAffiliate.affiliateNetwork')} />
          <ContentApp>
            <div className='font-normal bold text-white mt-2'>
              {t('linkAffiliate.linkAnAffiliate')}
            </div>
            <p className='mt-2 text-secondary font-tiny'>
              {t('linkAffiliate.linkAnAffiliateDescription')}
            </p>
            <div className='link-mp-account-form '>
              <form className='mt-5 pt-4 activate-form'>
                <div className='text-secondary bold'>{inputEmail}</div>
                <div className='text-secondary bold mt-5'>{nickName}</div>
                <div className='text-secondary text-right font-tiny mt-1'>
                  {t('linkAffiliate.positionExample')}
                </div>
              </form>
            </div>
            {error && <ErrorBox title={error} />}
            <FixedButton>
              <ButtonProccessing
                status={btnStatus}
                size='large'
                text={t('linkAffiliate.linkAffiliateNetwork')}
                className='border-none mx-auto'
                type='submit'
                onClick={handleSubmit}
                backgroundColor='var(--color-primary)'
                arrow
                color='#000'
                iconColor='#000'
              />
            </FixedButton>
          </ContentApp>
        </>
      )}
    </>
  );
};
export default Activation;
