import React from 'react';

const CopyIcon = ({ size, fillColor }) => {
  return (
    <svg
      width={size}
      height={size}
      fill={fillColor}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 512 512'
      style={{ rotate: '180deg' }}
    >
      <path d='M64 464l224 0c8.8 0 16-7.2 16-16l0-64 48 0 0 64c0 35.3-28.7 64-64 64L64 512c-35.3 0-64-28.7-64-64L0 224c0-35.3 28.7-64 64-64l64 0 0 48-64 0c-8.8 0-16 7.2-16 16l0 224c0 8.8 7.2 16 16 16zM224 304l224 0c8.8 0 16-7.2 16-16l0-224c0-8.8-7.2-16-16-16L224 48c-8.8 0-16 7.2-16 16l0 224c0 8.8 7.2 16 16 16zm-64-16l0-224c0-35.3 28.7-64 64-64L448 0c35.3 0 64 28.7 64 64l0 224c0 35.3-28.7 64-64 64l-224 0c-35.3 0-64-28.7-64-64z' />
    </svg>
  );
};

export default CopyIcon;
