import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getLocale, getMonthYear } from '../../../helpers/dates';
import Money from '../../Common/Money/Money';
import styles from './balancePerMonthCard.module.css';
import ChevronIcon from '../../icons/chevronIcon';

const BalancePerMonthCard = ({ month, year, amount, key }) => {
  const history = useHistory();
  const app = useSelector((state) => state.app);
  const locale = getLocale(app.language);

  return (
    <div
      className={styles.container}
      onClick={() =>
        history.push({
          pathname: `/incomes-per-month-detail`,
          search: `?month=${month}&year=${year}`,
        })
      }
    >
      <div className='col p-0 ml-3'>
        <div className='text-secondary semi-bold text-capitalize'>
          {getMonthYear(month, year, locale)}
        </div>
        <div className='font-large text-white bold mt-2'>
          {<Money value={amount} symbolSize='24px' />}
        </div>
      </div>
      <div className='col-2 pr-0'>
        <ChevronIcon fillColor={'var(--color-primary)'} size={35} />
      </div>
    </div>
  );
};

export default BalancePerMonthCard;
