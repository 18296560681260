import React from 'react';
import styles from './restaurantCard.module.css';
import ChevronIconBig from '../icons/chevronIconBig';

const RestaurantCard = ({
  lastElementRef,
  index,
  onRestaurantClick,
  id,
  logoUrl,
  name,
  cityName,
}) => {
  return (
    <div
      ref={lastElementRef}
      key={index}
      className={`${styles.restaurantCard} mt-2`}
      onClick={() => onRestaurantClick(id)}
    >
      <div className='col-auto p-0 ml-3'>
        <span className={styles.containerImg}>
          <img className={styles.imgRestaurant} src={logoUrl} alt='logo-restaurant' />
        </span>
      </div>
      <div className='col p-0 ml-3'>
        <div className='bold font-normal text-white'>{name}</div>
        <div className='text-secondary font-small'>{cityName}</div>
      </div>
      <div className='col-auto pl-0'>
        <ChevronIconBig fillColor={'var(--color-primary)'} size={47} />
      </div>
    </div>
  );
};

export default RestaurantCard;
