import { Skeleton } from '@mui/material';

export const skeletonProductInfo = () => {
  return (
    <>
      <div className='mt-3'>
        <Skeleton
          variant='rectangular'
          sx={{ bgcolor: 'grey.900', width: '45%', borderRadius: '.4rem', height: 23 }}
        />
      </div>
      <div className='mt-2'>
        <Skeleton
          variant='rectangular'
          sx={{ bgcolor: 'grey.900', width: '70%', borderRadius: '.4rem', height: 21 }}
        />
      </div>
      <div className='font-medium mt-2'>
        <Skeleton
          variant='rectangular'
          sx={{ bgcolor: 'grey.900', width: '30%', borderRadius: '.4rem', height: 23 }}
        />
      </div>
      <div className='mt-3'>
        <div className='d-flex'>
          <div>
            <Skeleton
              variant='rectangular'
              sx={{ bgcolor: 'grey.900', width: '126px', borderRadius: '10px', height: 111 }}
            />
          </div>
          <div className='ml-2'>
            <Skeleton
              variant='rectangular'
              sx={{ bgcolor: 'grey.900', width: '126px', borderRadius: '10px', height: 111 }}
            />
          </div>
          <div className='ml-2'>
            <Skeleton
              variant='rectangular'
              sx={{ bgcolor: 'grey.900', width: '126px', borderRadius: '10px', height: 111 }}
            />
          </div>
        </div>
        <div className='mt-3'>
          <Skeleton
            variant='rectangular'
            sx={{ bgcolor: 'grey.900', width: '40%', borderRadius: '.4rem', height: 23 }}
          />
        </div>
        <div className='mt-2'>
          <Skeleton
            variant='rectangular'
            sx={{ bgcolor: 'grey.900', width: '30%', borderRadius: '.4rem', height: 23 }}
          />
        </div>
        <div className='mt-3'>
          <Skeleton
            variant='rectangular'
            sx={{ bgcolor: 'grey.900', width: '100%', borderRadius: '.4rem', height: 20 }}
          />
        </div>
        <div className='mt-2'>
          <Skeleton
            variant='rectangular'
            sx={{ bgcolor: 'grey.900', width: '100%', borderRadius: '.4rem', height: 20 }}
          />
        </div>
        <div className='mt-2'>
          <Skeleton
            variant='rectangular'
            sx={{ bgcolor: 'grey.900', width: '100%', borderRadius: '.4rem', height: 20 }}
          />
        </div>
        <div className='mt-2'>
          <Skeleton
            variant='rectangular'
            sx={{ bgcolor: 'grey.900', width: '100%', borderRadius: '.4rem', height: 20 }}
          />
        </div>
        <div className='mt-3'>
          <Skeleton
            variant='rectangular'
            sx={{ bgcolor: 'grey.900', width: '40%', borderRadius: '.4rem', height: 23 }}
          />
        </div>
        <div className='mt-2'>
          <Skeleton
            variant='rectangular'
            sx={{ bgcolor: 'grey.900', width: '30%', borderRadius: '.4rem', height: 23 }}
          />
        </div>
        <div className='mt-3'>
          <Skeleton
            variant='rectangular'
            sx={{ bgcolor: 'grey.900', width: '100%', borderRadius: '.4rem', height: 20 }}
          />
        </div>
        <div className='mt-2'>
          <Skeleton
            variant='rectangular'
            sx={{ bgcolor: 'grey.900', width: '100%', borderRadius: '.4rem', height: 20 }}
          />
        </div>
        <div className='mt-2'>
          <Skeleton
            variant='rectangular'
            sx={{ bgcolor: 'grey.900', width: '100%', borderRadius: '.4rem', height: 20 }}
          />
        </div>
        <div className='mt-2'>
          <Skeleton
            variant='rectangular'
            sx={{ bgcolor: 'grey.900', width: '100%', borderRadius: '.4rem', height: 20 }}
          />
        </div>
      </div>
    </>
  );
};
