import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { setCanRefresh } from '../../actions/appActions';
import ContentApp from '../../components/ContentApp';

import ErrorBox from '../../components/Common/ErrorBox/ErrorBox';
import useForm from '../../helpers/useForm';
import Button from '../../components/Common/Button/Button';
import axios from 'axios';
import { logout } from '../../actions/associatesActions';
import IndustryRoleSelector from '../../components/Common/IndustryRoleSlector/IndustryRoleSelector';
import { Spinner } from '../../components/Common/Spinner/Spinner';
import { CircleSpinner } from 'react-spinners-kit';

const SignUpConciergeScreen = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const jwt = useSelector((state) => state.associates.jwt);
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [errorUser, setErrorUser] = useState(undefined);
  const [selectedIndustry, setSelectedIndustry] = useState(t('signUp.selectSector'));
  const [selectedRp, setSelectedRp] = useState({ label: t('signUp.selectRp') });
  const [selectedCity, setSelectedCity] = useState({ label: t('signUp.selectCity') });
  const hasNoRp = selectedRp.value === '0';
  const currentMarket = useSelector((state) => state.app.currentMarket);

  const handleSignOut = () => {
    dispatch(logout());
    history.push('/login');
  };

  useEffect(() => {
    dispatch(setCanRefresh(false));
  }, []);

  useEffect(() => {
    if (currentMarket !== null) {
      setLoading(false);
    }
  }, [currentMarket]);

  function validate(values) {
    let errors = {};
    if (!values.industry || values.industry === t('selectSector')) {
      errors.industry = t('errorSignUp.NO_INDUSTRY');
    }
    if (!values.role) {
      errors.role = t('errorSignUp.NO_ROLE');
    }
    if (!values.company) {
      errors.company = t('errorSignUp.NO_COMPANY');
    }
    return errors;
  }

  const sendForm = async () => {
    setProcessing(true);

    try {
      const result = await axios.put(
        `${process.env.REACT_APP_ADMIN_API_URL}/api/user/app`,
        {
          sector: values.industry,
          jobTitle: values.role,
          company: values.company,
          rpId: values.rp === '0' ? null : values.rp,
          cityId: values.city,
        },
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        },
      );

      if (result.status === 200) {
        history.push({
          pathname: '/kyc-start',
        });
      }

      setProcessing(false);
    } catch (error) {
      setErrorUser(t('genericError'));
      console.error(error);
      setProcessing(false);
    }
  };

  const onChange = (e) => {
    setErrorUser('');
    handleChange(e);
  };

  const { values, errors, handleChange, handleSubmit } = useForm(sendForm, validate);

  return (
    <>
      {loading ? (
        <div className='loading-app'>
          <div className='loaderDiv'>
            <CircleSpinner
              className='spinner-app mx-auto'
              style={{ margin: 'auto' }}
              size={40}
              color='var(--color-font-primary)'
            />
          </div>
        </div>
      ) : (
        <ContentApp background={true}>
          <div className='mt-1' style={{ paddingTop: 'env(safe-area-inset-top)' }}>
            <div className='font-medium bold text-white' style={{ lineHeight: '1.6rem' }}>
              {t('signUpConcierge.personalInformation')}
            </div>
            <div className='mt-2 text-secondary subtitle' style={{ lineHeight: '1.3rem' }}>
              {t('signUpConcierge.completeInformation')}
            </div>
            <form onSubmit={handleSubmit}>
              <div className='mt-4'>
                <IndustryRoleSelector
                  handleChange={onChange}
                  selectedIndustry={selectedIndustry}
                  setSelectedIndustry={setSelectedIndustry}
                  selectedRp={selectedRp}
                  setSelectedRp={setSelectedRp}
                  selectedCity={selectedCity}
                  setSelectedCity={setSelectedCity}
                  values={values}
                  errors={errors}
                  requiresRP={currentMarket.requiresRP}
                />
              </div>
              {errorUser && <ErrorBox title={errorUser} />}

              <div
                className='text-center mt-3 text-secondary'
                style={{ width: '100%' }}
                onClick={handleSignOut}
              >
                {t('menu.logout')}
              </div>

              <Button
                className='btn-primary mt-3'
                size='large'
                fontSize={'18px'}
                text={
                  processing ? (
                    <div className='d-flex justify-content-center'>
                      <Spinner size={25} color='#000' />
                    </div>
                  ) : (
                    t('continue')
                  )
                }
                icon={processing ? false : true}
                typeIcon='arrow'
                iconColor='#000'
                disabled={
                  !values.industry ||
                  !values.role ||
                  !values.company ||
                  (currentMarket.requiresRP && (!values.rp || hasNoRp)) ||
                  hasNoRp
                    ? !values.city
                    : ''
                }
              />
            </form>
          </div>
        </ContentApp>
      )}
    </>
  );
};

export default SignUpConciergeScreen;
