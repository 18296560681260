import { useContext, useEffect, useState } from 'react';
import { WelletContext } from '../../../context/WelletContext';
import { CircleSpinner } from 'react-spinners-kit';
import { getQueryString } from '../../../helpers/url';
import { useTranslation } from 'react-i18next';
import Header from '../../../components/Common/Header/Header';
import ContentApp from '../../../components/ContentApp';
import ReservationTypeCard from '../../../components/ReservationTypeCard/ReservationTypeCard';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { resetReservation } from '../../../actions/reservationActions';
import useGenericTranslation from '../../../helpers/translate';
import QrIcon from '../../../components/icons/QrIcon';
import ProductModal from '../../../components/Common/ProductModal/ProductModal';
import styles from './ReservationType.module.css';
import Line from '../../../components/Common/Line/Line';
import ShareIcon from '../../../components/icons/ShareIcon';
import { getQrImage } from '../../../helpers/qr';
import Qr from '../../../components/Common/Qr/Qr';

const ReservationType = () => {
  const { t } = useTranslation();
  const { getClientTranslation } = useGenericTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const app = useSelector((state) => state.app);
  const welletContext = useContext(WelletContext);
  const showId = getQueryString('showId');

  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(null);

  const [openQr, setOpenQr] = useState(false);

  const getShow = async () => {
    try {
      const result = await welletContext.apis.tickets.get(`/shows/get/`, {
        params: {
          showId: showId,
          lang: app.language,
        },
      });

      setShow(result.data);
    } catch (error) {
      console.error(error);
    }

    setLoading(false);
  };

  useEffect(() => {
    dispatch(resetReservation());
    getShow();
  }, []);

  const handleClick = (reservationType) => {
    history.push({
      pathname:
        reservationType === 'restaurant'
          ? '/reservation-restaurant'
          : '/reservation/' + reservationType,
      search: `?showId=${showId}`,
    });
  };

  const handleShare = async () => {
    try {
      if (navigator.share) {
        await navigator.share({
          title: show.name,
          url: show.link,
        });
      } else {
        console.warn('Web Share API not supported in this browser.');
      }
    } catch (error) {
      console.error('Error sharing:', error);
    }
  };

  return loading ? (
    <div className='loaderDiv'>
      <CircleSpinner
        className='spinner-app mx-auto'
        style={{ margin: 'auto' }}
        size={40}
        color='var(--color-font-primary)'
      />
    </div>
  ) : (
    <>
      <Header
        rightIcon={app.hasMarketingCampaign ? <QrIcon color='#fff' /> : null}
        title={<div className='text-capitalize'>{t('book') + ' ' + show.name}</div>}
        onClickRight={() => setOpenQr(true)}
        onClick={openQr ? () => setOpenQr(false) : null}
      />
      <ContentApp>
        {show?.reservationTypes?.map((r) => {
          return (
            <div className='mt-3' key={r.type}>
              <ReservationTypeCard
                title={getClientTranslation(`reservation${r.type.toLowerCase()}`)}
                image={
                  app?.name?.toLowerCase().includes('coco')
                    ? `/images/cb-reservation-${r.type.toLowerCase()}.png`
                    : app?.name?.toLowerCase().includes('rosa')
                    ? `/images/rosa-reservation-${r.type.toLowerCase()}.png`
                    : app?.name?.toLowerCase().includes('sounds')
                    ? `/images/sounds-reservation-${r.type.toLowerCase()}.png`
                    : null
                }
                onHandleClick={() => handleClick(r.type.toLowerCase())}
              />
            </div>
          );
        })}
        <ProductModal
          animationClass='fade-up-down'
          open={openQr}
          hasBackground
          onClose={() => setOpenQr(false)}
          fullScreen
          backIcon
          customStyle={{
            backgroundColor: 'transparent',
            border: 'none',
          }}
        >
          <ContentApp>
            <div className='card align-items-center p-3'>
              <div className={styles.imageContainer}>
                <img src={show.organization.logo} alt='logo' />
              </div>
              <div className='font-large fw-semibold my-2 text-center'>{show.name}</div>
              <Line color='#353535' className='w-100 my-4' />
              <Qr
                qrUrl={show.link}
                logo={show.logoUrl}
                welletContext={welletContext}
                logoBorder='ROUNDED'
              />
              <div className='font-large text-center fw-semibold m-3'>
                {t('scanToMakeReservation')}
              </div>
            </div>

            <div
              className='text-center text-secondary font-small mt-3'
              onClick={() => handleShare()}
            >
              {t('shareLinkToBook')}
              <ShareIcon size={25} color={'#7e7e7e'} styles={{ marginLeft: '4px' }} />
            </div>
          </ContentApp>
        </ProductModal>
      </ContentApp>
    </>
  );
};

export default ReservationType;
