//format = SQUARE (default), SQUARE_RANDOM, ROUNDED_ ROUNDED_RANDOM
//logo = url, puede ir vacia
//url = donde redirige
//corner = CORNER_ROUNDED (default), CORNER_SQUARE
//imageFormat = NONE (default), SQUARE, ROUNDED (los últimos traen el borde)

export const getQrImage = async (welletContext, refCodeURL, setQrImage, options = {}) => {
  const { format = 3, corner = 0, logo = null, logoBorder } = options;

  try {
    let url = `api/qr?format=${format}&url=${refCodeURL}&corner=${corner}`;
    if (logo) url += '&logo=' + logo + '&imageFormat=' + logoBorder;

    const response = await welletContext.apis.tickets.get(url, { responseType: 'blob' });
    const imageUrl = URL.createObjectURL(response.data);
    setQrImage(imageUrl);
  } catch (error) {
    console.error('Error fetching QR image:', error);
  }
};
