import React, { useState } from 'react';
import styles from './productCheck.module.css';

const ProductCheck = ({ onCheck, isChecked }) => {
  const [checked, setChecked] = useState(isChecked);

  const handleCheck = (e) => {
    setChecked(e);
    if (onCheck) onCheck(e);
  };

  return (
    <label className={styles.checkboxContainer}>
      <input type='checkbox' onChange={(e) => handleCheck(e.target.checked)} checked={checked} />
      <div className={styles.checkmark}></div>
    </label>
  );
};

export default ProductCheck;
