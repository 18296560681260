import React, { useContext, useEffect, useState } from 'react';
import { getQueryString } from '../../../helpers/url';
import { endOfMonth, format, startOfMonth } from 'date-fns';
import { WelletContext } from '../../../context/WelletContext';
import { SkeletonBalancePerMonthCardDetails } from '../../../components/Skeletons/SkeletonBalance';
import ContentApp from '../../../components/ContentApp';
import Header from '../../../components/Common/Header/Header';
import { getLocale, getMonthYear } from '../../../helpers/dates';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ActivityBalanceItem from '../../../components/Activities/ActivityBalanceItem/ActivityBalanceItems';

const BalancePerMonthDetailScreen = () => {
  const welletContext = useContext(WelletContext);
  const app = useSelector((state) => state.app);
  const locale = getLocale(app.language);
  const month = getQueryString('month');
  const year = getQueryString('year');
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [summary, setSummary] = useState(null);

  const firstMonthDate = format(
    startOfMonth(new Date(Number(year), Number(month) - 1, 1)),
    'yyyy-MM-dd',
  );
  const lastMonthDate = format(
    endOfMonth(new Date(Number(year), Number(month) - 1, 1)),
    'yyyy-MM-dd',
  );

  const getBalanceSummary = async () => {
    setLoading(true);
    try {
      const balance = await welletContext.apis.tickets.get(
        `/associates/GetEarnings?from=${firstMonthDate}&to=${lastMonthDate}&includeDetails=true`,
      );
      setSummary(balance.data);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getBalanceSummary();
  }, []);

  return (
    <>
      <Header
        title={
          <div className='text-capitalize'>{getMonthYear(Number(month), Number(year), locale)}</div>
        }
      />
      <ContentApp>
        {!loading ? (
          <div className='associate-balance px-3 py-2 card fade-up'>
            {summary?.details.map((d, i) => (
              <div key={i}>
                <ActivityBalanceItem
                  activity={d}
                  key={i}
                  date={d.date}
                  rightText={d.amount}
                  className={'px-0 py-2'}
                  handleClick={() => history.push(`/operation-details?id=${d.reservationId}`)}
                />

                <div
                  className={`${
                    i < summary?.details?.length - 1 ? 'activity-item-border my-2' : 'display-none'
                  }`}
                ></div>
              </div>
            ))}
          </div>
        ) : (
          SkeletonBalancePerMonthCardDetails()
        )}
      </ContentApp>
    </>
  );
};

export default BalancePerMonthDetailScreen;
