import { Skeleton } from '@mui/material';

export const skeletonLoading = () => {
  return (
    <div className='mt-2'>
      <Skeleton
        variant='rectangular'
        sx={{ bgcolor: 'grey.900', width: '100%', borderRadius: '.4rem', height: 1000 }}
      />
    </div>
  );
};

export const skeletonLoadingBalanceLast7Days = () => {
  return (
    <div className='mt-2'>
      <Skeleton
        variant='rectangular'
        sx={{ bgcolor: 'grey.900', width: '90%', borderRadius: '.4rem', height: 35 }}
      />
    </div>
  );
};

export const skeletonLoadingBalanceTodayDay = () => {
  return (
    <div className='mt-1'>
      <Skeleton
        variant='rectangular'
        sx={{
          bgcolor: 'grey.900',
          width: '30%',
          height: '15px',
          borderRadius: '.2rem',
          marginTop: '.1rem',
        }}
      />
      <div className='mt-2'>
        <Skeleton
          variant='rectangular'
          sx={{ bgcolor: 'grey.900', width: '30%', height: '25px', borderRadius: '.3rem' }}
        />
      </div>
    </div>
  );
};
