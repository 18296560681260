export const formatMoney = (
  value,
  options = {
    decimals: 0,
    currencySymbol: '$',
  },
  showThousandSplit = false,
) => {
  if (value === null || isNaN(value)) return '';

  if (typeof value === 'string') {
    value = parseFloat(value);
  }

  if (value === null || isNaN(value)) return '';

  if (showThousandSplit)
    return (
      options.currencySymbol +
      parseFloat(parseFloat(value).toFixed(options.decimals)).toLocaleString()
    );
  else return options.currencySymbol + parseFloat(value).toFixed(options.decimals);
};

export const formatDecimals = (number) => {
  return Math.floor(number * 100) / 100;
};

export const formatNumber = (value, decimals = 0) => {
  if (typeof value === 'string') {
    value = value.replace(',', '.');
    value = parseFloat(value);
  }

  if (value === null || isNaN(value)) return '';

  return Number(value).toLocaleString('en', {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  });
};

export const formatPercentage = (value, multiplyBy100 = true, decimals = 0) => {
  if (multiplyBy100) value = value * 100;
  return Number(value.toFixed(decimals)) + '%';
};
