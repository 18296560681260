import { Skeleton } from '@mui/material';
import styles from './skeleton.module.css';

export const skeletonTeam = () => {
  return (
    <div className={`${styles.notificationsContainer}`}>
      <div className='row align-items-center'>
        <div className='col-2 mr-2'>
          <Skeleton variant='circular' sx={{ bgcolor: 'grey.900' }} width={50} height={50} />
        </div>
        <div className='col pr-3'>
          <Skeleton
            sx={{ bgcolor: 'grey.900', borderRadius: '5px' }}
            variant='rectangular'
            width={'50%'}
          />
          <div className='mt-2'>
            <Skeleton
              sx={{ bgcolor: 'grey.900', borderRadius: '5px' }}
              variant='rectangular'
              width={'70%'}
            />
          </div>
          <div className='mt-2'>
            <Skeleton
              sx={{ bgcolor: 'grey.900', borderRadius: '5px' }}
              variant='rectangular'
              width={'70%'}
            />
          </div>
        </div>
      </div>
      <div className='row mt-4 align-items-center'>
        <div className='col-2 mr-2'>
          <Skeleton variant='circular' sx={{ bgcolor: 'grey.900' }} width={50} height={50} />
        </div>
        <div className='col pr-3'>
          <Skeleton
            sx={{ bgcolor: 'grey.900', borderRadius: '5px' }}
            variant='rectangular'
            width={'50%'}
          />
          <div className='mt-2'>
            <Skeleton
              sx={{ bgcolor: 'grey.900', borderRadius: '5px' }}
              variant='rectangular'
              width={'70%'}
            />
          </div>
          <div className='mt-2'>
            <Skeleton
              sx={{ bgcolor: 'grey.900', borderRadius: '5px' }}
              variant='rectangular'
              width={'70%'}
            />
          </div>
        </div>
      </div>
      <div className='row mt-4 align-items-center'>
        <div className='col-2 mr-2'>
          <Skeleton variant='circular' sx={{ bgcolor: 'grey.900' }} width={50} height={50} />
        </div>
        <div className='col pr-3'>
          <Skeleton
            sx={{ bgcolor: 'grey.900', borderRadius: '5px' }}
            variant='rectangular'
            width={'50%'}
          />
          <div className='mt-2'>
            <Skeleton
              sx={{ bgcolor: 'grey.900', borderRadius: '5px' }}
              variant='rectangular'
              width={'70%'}
            />
          </div>
          <div className='mt-2'>
            <Skeleton
              sx={{ bgcolor: 'grey.900', borderRadius: '5px' }}
              variant='rectangular'
              width={'70%'}
            />
          </div>
        </div>
      </div>
      <div className='row mt-4 align-items-center'>
        <div className='col-2 mr-2'>
          <Skeleton variant='circular' sx={{ bgcolor: 'grey.900' }} width={50} height={50} />
        </div>
        <div className='col pr-3'>
          <Skeleton
            sx={{ bgcolor: 'grey.900', borderRadius: '5px' }}
            variant='rectangular'
            width={'50%'}
          />
          <div className='mt-2'>
            <Skeleton
              sx={{ bgcolor: 'grey.900', borderRadius: '5px' }}
              variant='rectangular'
              width={'70%'}
            />
          </div>
          <div className='mt-2'>
            <Skeleton
              sx={{ bgcolor: 'grey.900', borderRadius: '5px' }}
              variant='rectangular'
              width={'70%'}
            />
          </div>
        </div>
      </div>
      <div className='row mt-4 align-items-center'>
        <div className='col-2 mr-2'>
          <Skeleton variant='circular' sx={{ bgcolor: 'grey.900' }} width={50} height={50} />
        </div>
        <div className='col pr-3'>
          <Skeleton
            sx={{ bgcolor: 'grey.900', borderRadius: '5px' }}
            variant='rectangular'
            width={'50%'}
          />
          <div className='mt-2'>
            <Skeleton
              sx={{ bgcolor: 'grey.900', borderRadius: '5px' }}
              variant='rectangular'
              width={'70%'}
            />
          </div>
          <div className='mt-2'>
            <Skeleton
              sx={{ bgcolor: 'grey.900', borderRadius: '5px' }}
              variant='rectangular'
              width={'70%'}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
