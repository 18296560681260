import React, { useContext, useEffect, useState } from 'react';
import { WelletContext } from '../../context/WelletContext';
import Header from '../../components/Common/Header/Header';
import ContentApp from '../../components/ContentApp';
import { skeletonTeam } from '../../components/Skeletons/SkeletonRp';
import UserRp from './UserRp';
import { t } from 'i18next';

const MyAffiliates = () => {
  const welletContext = useContext(WelletContext);
  const [loading, setLoading] = useState(false);
  const [team, setTeam] = useState(null);

  const getTeam = async () => {
    setLoading(true);
    try {
      const result = await welletContext.apis.tickets.get('/associates/GetTeam');
      setTeam(result.data);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getTeam();
  }, []);

  return (
    <>
      <Header title={t('menu.team')} />
      <ContentApp>
        {!loading ? (
          <div className='card fade-up'>
            {team?.items.map((a, index) => (
              <UserRp
                name={a.name}
                email={a.email}
                image={a.profilePic}
                company={a.company}
                position={a.jobTitle}
                className={`${index === team.items.length - 1 ? '' : 'feed-item-border'} px-2 py-3`}
              />
            ))}
          </div>
        ) : (
          skeletonTeam()
        )}
      </ContentApp>
    </>
  );
};

export default MyAffiliates;
