import { createContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr'

const AssociateRealtime = createContext();

const AssociateRealtimeProvider = ({ children }) => {
  const user = useSelector((state) => state.associates);
  const [connection, setConnection] = useState(null);

  useEffect(() => {
    if (user && user.jwt) {
      const connectionBuilder = new HubConnectionBuilder()
        .withUrl(`${process.env.REACT_APP_SALES_API_URL}/realtime/associate`, {
          accessTokenFactory: () => user.jwt
        })
        .configureLogging(LogLevel.Information)
        .withAutomaticReconnect()
        .build();

      connectionBuilder.start()
        //.then(() => console.log('Connection started'))
        .catch(err => console.error('Error while starting realtime connection: ' + err))
      setConnection(connectionBuilder);
    }

    return () => {
      connection && connection.stop();
    }
  }, [user.jwt])

  return (
    <AssociateRealtime.Provider
        value={{
            connection
        }}
    >
      {children}
    </AssociateRealtime.Provider>
  );
};

export { AssociateRealtimeProvider };
export default AssociateRealtime;
