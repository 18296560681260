import React from 'react';
import XMarkIcon from '../../../components/icons/XMarkIcon';
import SimpleCheckIcon from '../../../components/icons/SimpleCheckIcon';
import EditIcon from '../../../components/icons/EditIcon';
import styles from './userProfile.module.css';

const ItemProfileEditable = ({
  title,
  subtitle,
  isEditing,
  setIsEditing,
  onClick,
  className,
  inputRef,
}) => {
  return (
    <div
      className={`feed-item px-3 pb-2 pt-2 border-bottom border-secondary position-relative ${className}`}
    >
      <div className='item-title pl-1 bold'>{title}</div>
      <div className='text-secondary row justify-content-between px-3 align-items-center'>
        <div>{subtitle}</div>
      </div>
      <div className={`row mr-1 ${styles.editIcon}`}>
        {isEditing && (
          <div
            onClick={() => {
              setIsEditing(false);
            }}
          >
            <XMarkIcon color='red' size='18px' />
          </div>
        )}
        <div className='pl-3'>
          {isEditing && (
            <div onClick={onClick}>
              <SimpleCheckIcon size='18px' color='var(--color-green)' />
            </div>
          )}
        </div>
      </div>
      {!isEditing && (
        <div
          className={styles.editIcon}
          onClick={() => {
            setIsEditing(true);
            inputRef.current.focus();
          }}
        >
          <EditIcon size='16px' color='#fff' />
        </div>
      )}
    </div>
  );
};

export default ItemProfileEditable;
