import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Money from '../../Common/Money/Money';

const SummaryProducts = ({ reservationInfo }) => {
  const { t } = useTranslation();

  const getUnits = (p) => {
    console.log(p)
    if (p.specialType) {
      return p.quantity + ' ' + t('productType.' + p.specialType + '.unit');
    } else if (p.reservationType === 'AREA') {
      return p.quantity;
    } else {
      return p.quantity + ' pax';
    }
  };

  return (
    <>
      <table className='w-100'>
        <thead>
          <th className='font-smaller'>{t('items')}</th>
          <th className='font-smaller text-center'>{t('quantityAbbr')}</th>
          <th className='font-smaller text-right'>{t('price')}</th>
        </thead>
        <tbody>
          {reservationInfo?.products.map((p, index) => (
            <>
              <tr>
                <td className='position-relative fw-bold py-2'>
                  <span>{p.productName}</span>
                </td>
                <td className='text-center align-top py-2'>{getUnits(p)}</td>
                <td className='text-right align-top py-2'>
                  <Money value={p.totalAmountWithoutExtraPaxs} symbolSize='14px' />
                </td>
              </tr>

              {p.extraPaxs && p.extraPaxs > 0 ? (
                <tr className='py-1' key={index}>
                  <td className='position-relative fw-bold'>{t('extraGuest')}</td>
                  <td className='text-center py-1'>
                    {p.extraPaxs} {t('productType.EXTRAPAX.unit')}
                  </td>
                  <td className='text-right py-1'>
                    <Money value={p.totalAmountExtraPaxs} symbolSize='14px' />
                  </td>
                </tr>
              ) : null}
            </>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default SummaryProducts;
