export default (
  state = {
    all: null,
    selected: null,
    default: null,
  },
  action,
) => {
  switch (action.type) {
    case 'FETCH_CURRENCIES':
      const defaultCurrency = action.payload.find((c) => c.isDefault);
      const selectedCurrency = defaultCurrency;
      return {
        ...state,
        all: action.payload,
        selected: selectedCurrency,
        default: defaultCurrency,
      };
    case 'CHANGE_CURRENCY':
      return {
        ...state,
        selected: state.all.find((c) => c.shortForm === action.payload.shortForm),
      };
    default:
      return state;
  }
};
