import React, { useEffect, useState } from 'react';
import Header from '../../components/Common/Header/Header';
import ContentApp from '../../components/ContentApp';
import { getQueryString } from '../../helpers/url';
import { useContext } from 'react';
import { WelletContext } from '../../context/WelletContext';
import Money from '../../components/Common/Money/Money';

import { format, parseISO } from 'date-fns';
import { useSelector } from 'react-redux';
import CashierItem from './CashierItem';
import { SkeletonCashierActivity } from '../../components/Skeletons/SkeletonCashierActivityDetail';
import { useTranslation } from 'react-i18next';
import { getLocale } from '../../helpers/dates';
const CashierActivityDetail = () => {
  const language = useSelector((state) => state.app.language);
  const locale = getLocale(language);
  const id = getQueryString('id');
  const welletContext = useContext(WelletContext);
  const [activity, setActivity] = useState(null);
  const [loading, setLoading] = useState(true);
  const [profileImage, setProfileImage] = useState(true);
  const { t } = useTranslation();

  const getCashierActivity = async () => {
    setLoading(true);
    try {
      const response = await welletContext.apis.tickets.get(`/cashier/payments/${id}`);
      setActivity(response.data);
      await getProfileImage(response.data.concierge.profilePic);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const getProfileImage = async (image) => {
    try {
      const response = await welletContext.apis.tickets.get(image, { responseType: 'blob' });
      const url = URL.createObjectURL(response.data);
      setProfileImage(url);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getCashierActivity();
  }, []);

  useEffect(() => {
    if (activity && activity.concierge.profilePic) {
      getProfileImage(activity.concierge.profilePic);
    }
  }, [activity]);

  return (
    <ContentApp>
      <Header title={t('cashierTranslates.operationDetail')} />
      {loading ? (
        SkeletonCashierActivity()
      ) : (
        <>
          <div className='card p-3'>
            <div className='row align-items-center'>
              <div className='col'>
                <div className='text-secondary'>{t('cashierTranslates.recipient')}</div>
                <div>{activity.concierge.email}</div>
              </div>
              <div className='col-auto activity-profile-image'>
                <img src={profileImage} alt='profile' className=' mt-2' />
              </div>
            </div>
            <div className='text-secondary '>{t('signUp.company')}</div>
            <div>{activity.concierge.company}</div>
            <div className='text-secondary mt-2'>{t('cashierTranslates.jobTitle')}</div>
            <div>{activity.concierge.jobTitle}</div>
          </div>
          <div className='card p-3 mt-2'>
            <div className='text-secondary mt-2'>{t('cashierTranslates.paymentMethod')}</div>
            <div className='mt-1'>{t('cashierTranslates.paidInCash')}</div>
            <div className='text-secondary mt-3'>{t('cashierTranslates.paymentStatus')}</div>
            <div className='mt-1'>{t('reservationStatus.paid')}</div>
            <div className='text-secondary mt-3'>{t('cashierTranslates.operationDate')}</div>
            <div className='mt-1'>
              {format(parseISO(activity.operationDate), "MMM dd, yyyy HH:mm 'hr'", {
                locale,
              })}
            </div>
            <div className='text-secondary mt-3'>{t('cashierTranslates.amount')}</div>
            <div className='mt-1'>
              <Money value={activity.amount} lowDecimals decimal={false} /> (MXN)
            </div>
            <div className='text-secondary mt-3'>{t('cashierTranslates.commissionDetails')}</div>
            <div
              className='py-2 mt-3'
              style={{ border: '1px solid #212121', borderRadius: '10px' }}
            >
              {activity.commission.map((a, index) => (
                <CashierItem
                  activity={a}
                  key={index}
                  className='px-2 py-2'
                  date={a.reservationDateLocalTime}
                  isLastItem={index === activity.commission.length - 1}
                />
              ))}
            </div>
            <div className='text-secondary mt-3'>
              {t('cashierTranslates.restaurantCommissionDetails')}
            </div>
            <div className='p-2 mt-3' style={{ border: '1px solid #212121', borderRadius: '10px' }}>
              {activity?.restaurant?.map((p) => (
                <div className='row my-3' style={{ borderRadius: '10px' }}>
                  <div className='col'>{p.name}</div>
                  <div className='col-auto font-small'>
                    <Money value={p.total} lowDecimals decimal={false} />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </ContentApp>
  );
};

export default CashierActivityDetail;
