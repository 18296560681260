import React, { useContext, useEffect, useRef, useState } from 'react';
import Header from '../../../../components/Common/Header/Header';
import { WelletContext } from '../../../../context/WelletContext';
import ContentApp from '../../../../components/ContentApp';
import { CircleSpinner } from 'react-spinners-kit';
import Slider from '../../../../components/Common/Slider/Slider';
import {
  fetchCampaignsFeed,
  fetchCampaignsFeedShows,
} from '../../../../services/campaigns/campaignsService';
import styles from './CampaignsFeed.module.css';
import CampaignMediaCard from '../../../../components/Common/CampaignMediaCard/CampaignMediaCard';
import { SkeletonCampaignFeed } from '../../../../components/Skeletons/SkeletonCreateCampaign';
import { useTranslation } from 'react-i18next';
import EmptyActivity from '../../../../components/Common/EmptyActivity/EmptyActivity';
import { useDispatch, useSelector } from 'react-redux';
import { getAssociateSaleSetup } from '../../../../actions/associatesActions';
import ErrorBox from '../../../../components/Common/ErrorBox/ErrorBox';
import useLazyLoad from '../../../../helpers/lazyLoad';

const CampaignsFeed = () => {
  const { t } = useTranslation();
  const [shows, setShows] = useState(null);
  const [media, setMedia] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingMedia, setLoadingMedia] = useState(true);
  const welletContext = useContext(WelletContext);
  const language = useSelector((state) => state.app.language);
  const [errorMessage, setErrorMessage] = useState(null);
  const sliderRef = useRef();

  const dispatch = useDispatch();
  const associates = useSelector((state) => state.associates);

  const getData = async (showId = '') => {
    try {
      setLoadingMedia(true);
      const body = {
        ShowId: showId,
        language,
      };
      const responseMedia = await fetchCampaignsFeed(welletContext, body);
      setMedia(responseMedia.data);
      setLoadingMedia(false);
    } catch (error) {
      setErrorMessage(t('callApiError'));
    }
  };

  const getShows = async () => {
    const responseShows = await fetchCampaignsFeedShows(welletContext);
    setShows(responseShows.data);
    setLoading(false);
    getData();
  };

  const handleFilterByShow = (showId) => {
    setLoadingMedia(true);
    setShows((prevShows) =>
      prevShows.map((s) => (s.showId === showId ? { ...s, visited: true } : s)),
    );
    getData(showId);
  };

  useEffect(() => {
    getShows();
    dispatch(getAssociateSaleSetup(associates.jwt));
  }, []);

  return (
    <div>
      <Header pathName={'/'} />

      {loading ? (
        <div className='loaderDiv'>
          <CircleSpinner
            className='spinner-app mx-auto'
            style={{ margin: 'auto' }}
            size={40}
            color='var(--color-font-primary)'
          />
        </div>
      ) : (
        <ContentApp>
          {shows.length > 0 ? (
            <>
              <Slider
                ref={sliderRef}
                slideSpacing={10}
                initialSlide={0}
                touchScroll={true}
                showBtns={false}
                visibleSlides={'all'}
              >
                <div
                  className='text-center text-secondary font-extra-small'
                  onClick={() => handleFilterByShow('')}
                >
                  <img
                    className={styles.allLogo}
                    src={associates.user.company.logoURL}
                    alt='rosanegra-logo'
                  />
                  <div className='mt-2'>{t('all')}</div>
                </div>
                {shows?.map((s, index) => (
                  <div
                    className='d-flex flex-column text-secondary font-extra-small text-center align-items-center'
                    key={index}
                    onClick={() => handleFilterByShow(s.showId)}
                  >
                    <div className={`${s.visited ? styles.containerImg : styles.logoBorder}`}>
                      <img className={styles.restaurantImg} src={s.showLogo} alt='logo' />
                    </div>
                    <div className='mt-2'>{s.showName}</div>
                  </div>
                ))}
              </Slider>

              {errorMessage ? (
                <div className='mt-4'>
                  <ErrorBox title={errorMessage} />
                </div>
              ) : loadingMedia ? (
                SkeletonCampaignFeed()
              ) : (
                <>
                  {media.map((m, index) => (
                    <LazyLoadedCampaignMediaCard key={index} campaign={m} index={index} />
                  ))}
                </>
              )}
            </>
          ) : (
            <EmptyActivity title={t('noCampaigns')} />
          )}
        </ContentApp>
      )}
    </div>
  );
};

const LazyLoadedCampaignMediaCard = ({ campaign, index }) => {
  const [isVisible, elementRef] = useLazyLoad();

  return (
    <div ref={elementRef}>
      {isVisible ? (
        <CampaignMediaCard campaign={campaign} index={index} />
      ) : (
        <SkeletonCampaignFeed />
      )}
    </div>
  );
};

export default CampaignsFeed;
