import React from 'react';

const ItemMenu = ({ icon, href, title, className, onClick }) => {
  return (
    <li className={className} onClick={onClick}>
      <a href={href} className='menu-link'>
        <div>
          <span className='menu-icon mb-1'>{icon}</span> <span className='ml-1'>{title}</span>
        </div>
        <i className='icon-wellet-chevron_right' style={{ fontSize: '22px' }}></i>
      </a>
    </li>
  );
};

export default ItemMenu;
