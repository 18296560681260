export default (
  state = {
    status: 'LOADING',

    language: '',
    tenantId: '',
    name: '',
    logoURL: '',
    imageURL: '',
    webURL: '',
    canRefresh: true,
    backGroundImageUrl: '',
    logoType: '',
    favIconUrl: '',
    titleWeb: '',
    countryCode: '',
    videoBackground: '',
    hasMarketingCampaign: null,
    markets: [],
    qrCodeLogo: '',
    currentMarket: null,
  },
  action,
) => {
  switch (action.type) {
    case 'APP_READY':
      return { ...state, status: 'READY', language: action.payload };
    case 'SET_TENANT_ID':
      return { ...state, tenantId: action.payload };
    case 'SET_APP_DATA':
      return {
        ...state,
        name: action.payload.name,
        logoURL: action.payload.logoURL,
        webURL: action.payload.webURL,
        backGroundImageUrl: action.payload.backGroundImageUrl,
        logoType: action.payload.logoType,
        favIconUrl: action.payload.favIconUrl,
        titleWeb: action.payload.titleWeb,
        countryCode: action.payload.countryCode,
        videoBackground: action.payload.videoBackground,
        hasMarketingCampaign: action.payload.hasMarketingCampaign,
        markets: action.payload.markets,
        qrCodeLogo: action.payload.qrCodeLogo,
        currentMarket: action.payload.markets.length === 1 ? action.payload.markets[0] : null,
      };
    case 'SET_MARKET':
      return {
        ...state,
        currentMarket: action.payload,
      };
    case 'SET_CAN_REFRESH':
      return { ...state, canRefresh: action.payload };
    case 'SET_LANGUAGE':
      return { ...state, language: action.payload };
    default:
      return state;
  }
};
