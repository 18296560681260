import React, { useContext, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  skeletonEmptyPerformances,
  skeletonSchedule,
} from '../../../components/Skeletons/SkeletonRestaurantReservation';
import { addMinutes, generateDisabledDates, getDateFromOffset, getLocale } from '../../../helpers/dates';
import { useHistory } from 'react-router-dom';
import { convertTime12to24 } from '../../../helpers/dates';
import { useTranslation } from 'react-i18next';
import { format, isSameDay } from 'date-fns';
import { fetchShow } from '../../../actions/appActions';
import Header from '../../../components/Common/Header/Header';
import ReservationSelector from '../../../components/ReservationSelector/ReservationSelector';
import ContentApp from '../../../components/ContentApp';
import ProductPerformanceSelector from '../../../components/ProductPerformanceSelector/ProductPerformanceSelector';
import EmptyActivity from '../../../components/Common/EmptyActivity/EmptyActivity';
import Calendar from '../../../components/Common/Calendar/Calendar';
import Button from '../../../components/Common/Button/Button';
import ErrorBox from '../../../components/Common/ErrorBox/ErrorBox';
import RestaurantSelector from '../../../components/Points/StatusCommissions/RestaurantSelector';
import styles from '../reservation.module.css';
import '../../../css/product-list.css';
import SuccessScreen from '../../Common/SuccessScreen';
import ProductModal from '../../../components/Common/ProductModal/ProductModal';
import { WelletContext } from '../../../context/WelletContext';
import { Spinner } from '../../../components/Common/Spinner/Spinner';

const ModifyReservation = () => {
  const { t } = useTranslation();
  const welletContext = useContext(WelletContext);
  const language = useSelector((state) => state.app.language);
  const locale = getLocale(language);
  const calendarRef = useRef(null);
  const history = useHistory();
  const reservationInfo = history.location.state;
  const [restaurantId, setRestaurantId] = useState(reservationInfo?.showId);
  const [loading, setLoading] = useState(true);
  const [today, setToday] = useState(null);
  const [compareToday, setCompareToday] = useState(null);
  const [minDate, setMinDate] = useState(new Date());
  const [disabledDates, setDisabledDates] = useState([]);
  const [loadingSchedule, setLoadingSchedule] = useState(false);
  const [schedule, setSchedule] = useState(null);
  const [selectedPax, setSelectedPax] = useState(reservationInfo?.paxs);
  const [selectedDate, setSelectedDate] = useState(
    new Date(reservationInfo?.reservationDate),
    "EEE MMM dd yyyy HH:mm:ss 'GMT'xxx (zzz)",
  );
  const [selectedPerformance, setSelectedPerformance] = useState(
    reservationInfo?.reservationDate?.split('T')[1].substring(0, 5),
  );
  const [selectDisabled, setSelectDisabled] = useState(false);
  const [calendarVisible, setCalendarVisible] = useState(false);
  const settings = useSelector((state) => state.app.language);
  const [intervals, setIntervals] = useState([]);
  const [selectedOccasion, setSelectedOccasion] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [restaurants, setRestaurants] = useState(null);
  const [loadingRestaurants, setLoadingRestaurants] = useState(true);
  const [selectedRestaurant, setSelectedRestaurant] = useState(null);
  const [modifyingReservation, setModifyingReservation] = useState(false);
  const [response, setResponse] = useState(null);
  const reservationType = 'RESTAURANT';

  useEffect(() => {
    if (!reservationInfo && !modifyingReservation) {
      history.goBack();
    }
  }, [reservationInfo]);

  const getRestaurants = async (selectedCity, page = 1, pageSize = 100) => {
    setLoadingRestaurants(true);
    try {
      const result = await fetchShow(settings, selectedCity, page, pageSize, welletContext);
      const simplifiedRestaurants = result.shows.map((restaurant) => ({
        id: restaurant.id,
        name: restaurant.name,
      }));

      setRestaurants((prevData) => {
        if (prevData) {
          return [...prevData, ...simplifiedRestaurants];
        } else {
          return simplifiedRestaurants;
        }
      });

      setSelectedRestaurant(simplifiedRestaurants?.find((r) => r.id === Number(restaurantId)));
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingRestaurants(false);
    }
  };

  const onRestaurantClick = (restaurant) => {
    setSelectedRestaurant(restaurant);
    setRestaurantId(restaurant.id);
  };

  const getShow = async () => {
    try {
      const result = await welletContext.apis.tickets.get(`/shows/get/`, {
        params: {
          showId: restaurantId,
        },
      });

      const todayDate = new Date(getDateFromOffset(result.data.utcOffset));
      const yesterdayDate = new Date(todayDate);
      yesterdayDate.setDate(yesterdayDate.getDate() - 1);
      const firstDate = new Date(todayDate.getFullYear(), todayDate.getMonth(), 1);

      setToday(
        new Date(reservationInfo?.reservationDate),
        "EEE MMM dd yyyy HH:mm:ss 'GMT'xxx (zzz)",
      );
      setCompareToday(addMinutes(todayDate, 20));
      setMinDate(new Date(todayDate.getFullYear(), todayDate.getMonth(), 0 + 1));
      setDisabledDates(generateDisabledDates(firstDate, yesterdayDate, locale));
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const getSchedule = async (date = today) => {
    setLoadingSchedule(true);
    try {
      const result = await welletContext.apis.tickets.get(
        `/shows/GetSchedule?showId=${restaurantId}&date=${format(
          date,
          'yyyy-MM-dd',
        )}&reservationType=${reservationType}`,
      );
      setSchedule(result.data);

      if (result.data.length === 0) {
        setIntervals([]);
      }

      const formattedTimes = result.data?.map((time) => {
        const time24 = new Date(
          format(date, 'yyyy-MM-dd') + 'T' + convertTime12to24(time.timeStart),
        );

        if (time.timeMinutesStart >= 0 && time.timeMinutesStart <= 360)
          time24.setDate(time24.getDate() + 1);

        return { time24, soldOut: time.isSoldOut };
      });
      formattedTimes.sort();

      const allIntervals = formattedTimes.map(({ time24, soldOut }) => ({
        time: time24,
        soldOut,
      }));

      setIntervals(allIntervals);
      setLoadingSchedule(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getShow();
    getRestaurants();
  }, []);

  useEffect(() => {
    if (today !== null) getSchedule(today);
  }, [today, restaurantId]);

  const enableSelect = () => {
    setSelectDisabled(false);
  };

  const handleChangeDate = async (day) => {
    setSelectedOccasion(null);
    setSelectedPerformance(null);
    if (day !== undefined) {
      setSelectedDate(day);
      getSchedule(day, selectedOccasion);
    }
    setCalendarVisible(!calendarVisible);
  };

  const restaurantSelectStyles = {
    control: (provided) => ({
      ...provided,
      paddingLeft: '.4rem',
      boxShadow: 'none',
    }),
    valueContainer: (provided) => ({
      ...provided,
      boxShadow: 'none',
    }),
  };

  const onSelectPerformance = (performance) => {
    setSelectedPerformance(performance);
  };

  const datePerformance =
    reservationInfo && format(selectedDate, 'yyyy-MM-dd') + ' ' + selectedPerformance;

  const handleModify = async () => {
    setModifyingReservation(true);
    try {
      const response = await welletContext.apis.tickets.put('/reservation/ModifyReservation', {
        showId: restaurantId,
        paxs: selectedPax,
        CustomerName: reservationInfo?.customerName ?? '',
        CustomerPhone: reservationInfo?.customerPhone ?? '',
        Email: reservationInfo?.customerEmail ?? '',
        Comments: reservationInfo?.customerComments ?? '',
        Occasion: reservationInfo?.customerOccasion ?? null,
        CountryCode: 'MX',
        ReservationDateLocalTime: datePerformance,
        OldReservationId: reservationInfo?.reservationId,
      });
      if (response?.status === 200) {
        setModifyingReservation(false);
        setResponse(true);
      }
    } catch (error) {
      switch (error.response.data.errorType) {
        case 'RESERVATION_NOT_FOUND':
          setErrorMessage(t('modifyReservationError.RESERVATION_NOT_FOUND'));
          break;
        case 'RESERVATION_NOT_ALLOWED_FOR_MODIFY':
          setErrorMessage(t('modifyReservationError.RESERVATION_NOT_ALLOWED_FOR_MODIFY'));
          break;
        case 'EXCEEDED_TIME_FOR_MODIFICATION':
          setErrorMessage(t('modifyReservationError.EXCEEDED_TIME_FOR_MODIFICATION'));
          break;
        case 'HAS_MODIFICATION':
          setErrorMessage(t('modifyReservationError.HAS_MODIFICATION'));
          break;
        case 'OLD_ORDER_ID_REQUIRED':
          setErrorMessage(t('modifyReservationError.OLD_ORDER_ID_REQUIRED'));
          break;
        default:
          setErrorMessage(t('modifyReservationError.DEFAULT'));
      }
      setModifyingReservation(false);
    }
  };

  return (
    <>
      {response ? (
        <SuccessScreen
          title={`${t('done')}!`}
          subtitle={`${t('modifyReservationSuccess')}`}
          path={'/'}
        />
      ) : (
        <>
          <ProductModal
            open={calendarVisible}
            fullScreen
            animationClass='fade-up-horizontal'
            hasBackground
            showCloseButton={false}
          >
            <Header onClick={() => setCalendarVisible(false)} showBack />
            <Calendar
              minDate={minDate}
              disabledDates={disabledDates}
              onSelectDate={handleChangeDate}
              selectedDate={selectedDate}
            />
          </ProductModal>
          <Header title={<div className='text-capitalize px-3'>{t('modifyReservation')}</div>} />
          <ContentApp menu={true}>
            {!loading && compareToday !== null && selectedDate !== null ? (
              <>
                <div className='text-secondary bold'>{t('restaurantVenue')}</div>
                <RestaurantSelector
                  restaurants={restaurants}
                  selectedRestaurant={selectedRestaurant}
                  handleRestaurantChange={onRestaurantClick}
                  selectStyles={restaurantSelectStyles}
                  selectClassName='product-selector'
                />
                <div className='mt-4 '>
                  <ReservationSelector
                    selectedPax={selectedPax}
                    setSelectedPax={setSelectedPax}
                    selectedDate={selectedDate}
                    selectDisabled={selectDisabled}
                    enableSelect={enableSelect}
                    handleChangeDate={handleChangeDate}
                    loading={loadingSchedule}
                    setCalendarVisible={setCalendarVisible}
                    calendarVisible={calendarVisible}
                    setSelectDisabled={setSelectDisabled}
                    calendarRef={calendarRef}
                    initialSlide={0}
                  />
                </div>

                {/* <div className='mt-4 '>
                  <ReservationSelector
                    occasions={filteredOccasions}
                    selectedPax={selectedPax}
                    setSelectedPax={setSelectedPax}
                    selectedDate={selectedDate}
                    selectedOccasion={selectedOccasion}
                    selectDisabled={selectDisabled}
                    enableSelect={enableSelect}
                    handleChangeDate={handleChangeDate}
                    handleChangeOccasion={handleChangeOccasion}
                    loading={loadingSchedule}
                    setCalendarVisible={setCalendarVisible}
                    calendarVisible={calendarVisible}
                    setSelectDisabled={setSelectDisabled}
                    calendarRef={calendarRef}
                    initialSlide={selectedPax - 1}
                  />
                </div> */}

                {errorMessage && <ErrorBox title={errorMessage} />}
                {!loadingSchedule ? (
                  <>
                    {intervals?.length > 0 && !loadingSchedule ? (
                      <div className='fade-up'>
                        <div className='mt-3 mb-1 text-secondary bold'>{t('selectTime')}</div>
                        <div className='mt-3'>
                          <ProductPerformanceSelector
                            performances={intervals}
                            onSelect={onSelectPerformance}
                            selectedPerformance={selectedPerformance}
                            language={language}
                            selectedDate={selectedDate}
                            loadingSchedule={loadingSchedule}
                            today={compareToday}
                            selectedOccasion={selectedOccasion}
                          />
                        </div>
                      </div>
                    ) : !loadingSchedule ? (
                      <EmptyActivity icon={'notification'} title={'No hay horarios disponibles'} />
                    ) : (
                      skeletonSchedule()
                    )}
                  </>
                ) : (
                  <>{skeletonEmptyPerformances()}</>
                )}

                <div
                  className='position-fixed w-100'
                  style={{
                    bottom: '0',
                    left: 0,
                    backgroundColor: '#000',
                    paddingBottom: 'env(safe-area-inset-bottom)',
                    zIndex: 2,
                  }}
                >
                  <div className='container'>
                    <div className='row justify-content-center'>
                      <div className='col-12 col-md-7 col-lg-6'>
                        <Button
                          text={
                            modifyingReservation ? (
                              <div className='d-flex justify-content-center'>
                                <Spinner size={25} color='#000' />
                              </div>
                            ) : (
                              <div>{t('confirmChanges')}</div>
                            )
                          }
                          size='large'
                          className={`mt-2 mb-3 ${styles.btn}`}
                          onClick={handleModify}
                          icon={true}
                          disabled={!selectedPax || !selectedPerformance}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : null}
          </ContentApp>
        </>
      )}
    </>
  );
};

export default ModifyReservation;
