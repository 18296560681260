import React from 'react';
import NotificactionScreen from '../../../components/Common/NotificactionScreen/NotificactionScreen';
import WarningIcon from '../../../components/icons/WarningIcon';
import { useDispatch } from 'react-redux';
import { logout } from '../../../actions/associatesActions';
import { useHistory } from 'react-router-dom';
import ContentApp from '../../../components/ContentApp';

const Unauthorized = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const handleSignOut = () => {
    dispatch(logout());
    history.push('/login');
  };

  return (
    <NotificactionScreen>
      <ContentApp>
        <div className='d-flex align-items-center justify-content-center'>
          <WarningIcon color='var(--color-primary)' size='120px' />
        </div>
        <div className='text-left px-4'>
          <div
            className='font-large bold lh-title mt-5 px-1'
            style={{ color: 'var(--color-font-primary)' }}
          >
            Lo sentimos, no puedes acceder
          </div>
          <div className='text-secondary font-normal mt-2 px-1'>
            Por favor, contáctate con soporte para solicitar más información.
          </div>
        </div>
        <div
          className='text-center mt-3 text-secondary'
          style={{ position: 'fixed', bottom: 40, width: '100%', left: 0 }}
          onClick={handleSignOut}
        >
          Cerrar sesión
        </div>
      </ContentApp>
    </NotificactionScreen>
  );
};

export default Unauthorized;
