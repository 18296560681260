import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styles from './welcomeScreens.module.css';
import Button from '../../../components/Common/Button/Button';
import NotificactionScreen from '../../../components/Common/NotificactionScreen/NotificactionScreen';
import { WelletContext } from '../../../context/WelletContext';
import { firstLogin } from '../../../actions/associatesActions';
import { useTranslation } from 'react-i18next';
import ContentApp from '../../../components/ContentApp';
import FixedButton from '../../../components/Common/FixedButton/FixedButton';


const WelcomeHomeScreen = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const welletContext = useContext(WelletContext);
  const history = useHistory();
  const app = useSelector((state) => state.app)
  const onHandleOmit = () => {
    dispatch(firstLogin(welletContext));
    history.push('/');
  };

  return (
    <NotificactionScreen>
      <ContentApp>
        <div className={styles.container}>
          <div className={`d-flex align-items-center justify-content-center`}>
            <img src={app.logoType} alt='logo' className={styles.img} />
          </div>
          <div className={`text-center font-large bold ${styles.text}`}>
            {t('onboarding.welcome')}
          </div>
          <div className={`text-center text-secondary mt-3 px-4`}>


            {t('onboarding.welcomeText', {
                      organization: app.name ,
                    })}


          </div>
        </div>
        <FixedButton>
          <Button
            text={t('onboarding.learnMore')}
            size='large'
            className={`mt-2 ${styles.btn}`}
            onClick={() => history.push('/onboarding')}
          />
          <Button
            text={t('onboarding.skip')}
            size='large'
            className={`mt-2 mb-3 ${styles.btnOmit}`}
            onClick={() => onHandleOmit()}
            color='var(--color-primary)'
          />
        </FixedButton>
      </ContentApp>
    </NotificactionScreen>
  );
};

export default WelcomeHomeScreen;
