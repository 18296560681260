import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

/**
 * Renders route or redirects to redirectPath if order is null or doesn't meet validate criteria
 */
export default function RouteReservationDependant({ path, render, redirectPath }) {
  const reservation = useSelector((state) => state.reservation);
  const renderRoute = (props) => {
    if (reservation.showId !== null) {
      return render(props);
    } else {
      return <Redirect to={{ pathname: redirectPath }} />;
    }
  };
  return <Route path={path} render={renderRoute} />;
}
