export const getReservations = async (welletContext, body) => {
  try {
    const result = await welletContext.apis.tickets.post(`/reservation/all`, body);
    return result.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const fetchCities = async (welletContext) => {
  try {
    const result = await welletContext.apis.tickets.get('/shows/cities');
    return result.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};
