import React from 'react';

const UserIcon = ({ size, color, styles }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      style={styles}
      viewBox='0 0 512.000000 512.000000'
      preserveAspectRatio='xMidYMid meet'
    >
      <g
        transform='translate(0.000000,512.000000) scale(0.100000,-0.100000)'
        fill={color}
        stroke='none'
      >
        <path
          d='M2380 4785 c-293 -47 -576 -227 -747 -476 -83 -121 -153 -299 -178
-454 -44 -272 24 -579 178 -804 174 -253 455 -431 757 -476 612 -93 1183 326
1275 935 73 479 -168 951 -596 1166 -220 110 -450 146 -689 109z m370 -328
c209 -57 368 -171 480 -344 89 -136 125 -259 125 -433 0 -174 -36 -297 -125
-433 -238 -366 -718 -474 -1087 -247 -226 139 -363 368 -380 636 -26 400 254
753 662 833 67 13 257 6 325 -12z'
        />
        <path
          d='M2175 2224 c-600 -81 -1106 -449 -1367 -994 -98 -206 -160 -464 -162
-682 -1 -138 5 -155 68 -202 l27 -21 1819 0 1819 0 27 21 c72 53 85 113 65
301 -38 355 -169 676 -387 944 -65 80 -218 228 -300 291 -247 189 -557 312
-876 347 -136 15 -609 12 -733 -5z m832 -332 c477 -108 844 -408 1035 -845 39
-89 89 -263 102 -359 l7 -48 -1591 0 -1592 0 7 42 c91 587 508 1050 1074 1193
153 39 217 44 551 40 276 -2 327 -5 407 -23z'
        />
      </g>
    </svg>
  );
};

export default UserIcon;
