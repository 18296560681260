import React, { useContext } from 'react';
import { WelletContext } from '../../context/WelletContext';
import { Skeleton } from '@mui/material';
import { useActivities } from '../../hooks/useActivities';
import ContentApp from '../ContentApp';
import ActivityItem from '../Activities/ActivityItem/ActivityItem';
import EmptyActivity from '../Common/EmptyActivity/EmptyActivity';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Header from '../Common/Header/Header';

const ReservationsDisputed = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const welletContext = useContext(WelletContext);
  const reservationsInfo = useActivities({
    pageSize: 10,
    endpoint: '/Reservation/Disputed',
    api: welletContext.apis.tickets,
    isReservation: true,
  });
  return (
    <>
      <Header title={t('menu.disputedReservations')} />
      <ContentApp>
        {reservationsInfo.loading ? (
          <Skeleton
            variant='rectangular'
            sx={{ bgcolor: 'grey.900', width: '100%', borderRadius: '.4rem', height: 400 }}
          />
        ) : (
          <div className='card mb-2 py-2'>
            {reservationsInfo.activities?.length > 0 ? (
              <div style={{ borderRadius: '10px' }}>
                {reservationsInfo.activities?.map((p, index) => (
                  <div className='px-0 fade-up' key={index}>
                    <ActivityItem
                      activity={p}
                      date={p.createdDate}
                      className={'px-3 py-2'}
                      rightText={p.status}
                      handleClick={() => history.push(`/operation-details?id=${p.id}`)}
                    />
                    <div
                      className={`${
                        index < reservationsInfo.activities.length - 1
                          ? 'activity-item-border mt-2'
                          : 'display-none'
                      }`}
                    ></div>
                  </div>
                ))}
              </div>
            ) : (
              <EmptyActivity title={t('noDisputedReservations')} />
            )}
          </div>
        )}
      </ContentApp>
    </>
  );
};

export default ReservationsDisputed;
