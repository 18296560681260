import React, { useContext, useState } from 'react';
import Header from '../../../../components/Common/Header/Header';
import ContentApp from '../../../../components/ContentApp';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styles from './LinkRestaurant.module.css';
import ShareIcon from '../../../../components/icons/ShareIcon';
import CopyIcon from '../../../../components/icons/copyIcon';
import UserIcon from '../../../../components/icons/userIcon';
import { Toast } from '../../../../components/Common/Toast/Toast';
import { getQrImage } from '../../../../helpers/qr';
import { WelletContext } from '../../../../context/WelletContext';
import Qr from '../../../../components/Common/Qr/Qr';

const LinkQrRestaurant = () => {
  const app = useSelector((state) => state.app);
  const user = useSelector((state) => state.associates.user);
  const { t } = useTranslation();
  const welletContext = useContext(WelletContext);
  const [copied, setCopied] = useState(false);

  const handleShare = async () => {
    try {
      if (navigator.share) {
        await navigator.share({
          title: app.name,
          url: user.qrUserUrl,
        });
      } else {
        console.warn('Web Share API not supported in this browser.');
      }
    } catch (error) {
      console.error('Error sharing:', error);
    }
  };

  const onCopy = (link) => {
    navigator.clipboard.writeText(link);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  return (
    <>
      <Header />
      <ContentApp classes='px-4'>
        <div className={styles.imageContainer}>
          {user.profileImage ? (
            <img
              src={user.profileImage}
              alt={user.displayName}
              className={styles.associateUserImage}
            />
          ) : (
            <UserIcon color='var(--color-light-gray)' size='40px' />
          )}
        </div>
        <div className='qr-container card align-items-center p-3 position-relative mt-5 qr-container'>
          <div className='font-large fw-semibold text-center mt-5'>{user.name}</div>
          <Qr
            qrUrl={user.qrUserUrl}
            getQrImage={getQrImage}
            welletContext={welletContext}
            logo={app.qrCodeLogo}
            logoBorder='NONE'
          />
          <div className=' text-center fw-semibold m-3 px-3'>{t('shareQr')}</div>
        </div>
        <div className={styles.buttonsContainer}>
          <div
            onClick={() => onCopy(user.qrUserUrl)}
            className={`text-center col p-3 ${styles.copyButton}`}
          >
            <CopyIcon size={'30px'} fillColor='#fff' />
            <div className='mt-2'>{t('copy')}</div>
          </div>
          <div onClick={handleShare} className={`text-center col ml-2 p-3 ${styles.shareButton}`}>
            <ShareIcon size={'30px'} color='#000' />
            <div className='mt-2'>{t('share')}</div>
          </div>
        </div>
        {copied && (
          <Toast
            bottom={'15px'}
            zIndex={100000}
            type={'success'}
            message={t('linkCopied')}
            delay={3}
          />
        )}
      </ContentApp>
    </>
  );
};

export default LinkQrRestaurant;
