import React, { useState } from 'react';
import './Countdown.css';
import UseCountdown from '../../../hooks/useCountdown';

export function Countdown({ initialDate, onCountdownFinish }) {
  const [isFinished, setIsFinished] = useState(false);
  const [days, hours, minutes, seconds] = UseCountdown({
    targetDate: initialDate,
    timeLeft: (a) => {
      if (!isFinished && a <= 0) {
        if (onCountdownFinish) onCountdownFinish();
        setIsFinished(true);
      }
    },
  });

  const addLeadingZeros = (num, totalLength) => {
    return String(num).padStart(totalLength, '0');
  };

  const DateTimeDisplay = ({ value, type, isDanger }) => {
    return <span>{value}</span>;
  };

  const ShowCounter = ({ days, hours, minutes, seconds }) => {
    return (
      <div className='show-counter'>
        {hours > 0 ? (
          <>
            <DateTimeDisplay value={hours} type='Hours' isDanger={false} />
            <span>:</span>
          </>
        ) : null}
        <DateTimeDisplay value={addLeadingZeros(minutes, 2)} type={'Mins'} isDanger={false} />
        <span>:</span>
        <DateTimeDisplay value={addLeadingZeros(seconds, 2)} type={'Seconds'} isDanger={false} />
      </div>
    );
  };

  const ExpiredTime = () => {
    return (
      <div className='show-counter'>
        <DateTimeDisplay value={addLeadingZeros(0, 2)} type={'Mins'} isDanger={false} />
        <span>:</span>
        <DateTimeDisplay value={addLeadingZeros(0, 2)} type={'Seconds'} isDanger={false} />
      </div>
    );
  };

  return isFinished ? (
    <ExpiredTime />
  ) : (
    <ShowCounter days={days} hours={hours} minutes={minutes} seconds={seconds} />
  );
}
