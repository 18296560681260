import { createContext, useState } from 'react';

const DataManager = createContext();

const DataManagerProvider = ({ children }) => {
  const [citySelected, setCitySelected] = useState(null);
  const [showAmount, setShowAmount] = useState(() => {
    const storedValue = null; // localStorage.getItem('showAmount'); //todo: commented due to security problem in Safari
    return storedValue !== null ? JSON.parse(storedValue) : true;
  });

  return (
    <DataManager.Provider
      value={{
        showAmount,
        setShowAmount,
        citySelected,
        setCitySelected,
      }}
    >
      {children}
    </DataManager.Provider>
  );
};

export { DataManagerProvider };
export default DataManager;
