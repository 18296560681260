import React, { useState, useContext } from 'react';
import { WelletContext } from '../../../context/WelletContext';
import { useDispatch } from 'react-redux';
import { logout } from '../../../actions/associatesActions';
import ContentApp from '../../../components/ContentApp';
import Button from '../../../components/Common/Button/Button';
import './DeleteAccountScreen.css';
import { useTranslation } from 'react-i18next';
import { Toast } from '../../../components/Common/Toast/Toast';
import { ExclamationIcon } from '../../../components/icons/ExclamationIcon';

const DeleteAccountScreen = ({ history }) => {
  const { t } = useTranslation();
  const welletContext = useContext(WelletContext);
  const [userNotFound, setUserNotFound] = useState(false);
  const [operationSuccess, setOperationSuccess] = useState(false);
  const dispatch = useDispatch();

  const handleDeleteAccount = async () => {
    try {
      const result = await welletContext.apis.tickets.delete(`/users/delete-me`, {
        token: welletContext.user?.jwt,
      });
      if (result.status === 200) {
        setOperationSuccess(true);
      } else {
        setOperationSuccess(false);
      }
    } catch (err) {
      setUserNotFound(true);
      console.error(err);
    }
  };

  const onCancel = () => {
    history.goBack();
  };

  const onDeleted = () => {
    dispatch(logout());
  };

  return (
    <ContentApp background={true}>
      <div className='container delete-account-screen-container'>
        <ExclamationIcon size='120px' color='var(--color-primary)' />
        <div className=' font-medium bold' style={{ color: 'var(--color-font-primary)' }}>
          {t('deleteAccountTitle')}
        </div>
        <div>
          <p style={{ color: 'var(--color-font-gray)' }}>{t('deleteAccountDescription')}</p>
        </div>
        <Button
          size='large'
          text={t('delete')}
          onClick={handleDeleteAccount}
          className='btn-primary'
        />
        <Button
          size='large'
          text={t('cancel')}
          backgroundColor='#fff'
          color='#000'
          onClick={onCancel}
        />
        {userNotFound && (
          <Toast type={'error'} message={'No se pudo eliminar su cuenta.'} delay={3} />
        )}
        {operationSuccess && (
          <Toast
            type={'error'}
            message={'Se ha eliminado tu cuenta con éxito!'}
            delay={3}
            onCallback={() => onDeleted()}
          />
        )}
      </div>
    </ContentApp>
  );
};

export default DeleteAccountScreen;
