import React from 'react';

const MembershipActiveIcon = ({ size = '25px', color = 'var(--color-primary)' }) => {
  return (
    <svg
      id='b'
      data-name='Capa 2'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 35.5 37.04'
      width={size}
      height={size}
    >
      <g id='c' data-name='Capa 1'>
        <g>
          <path
            d='M13.58,14.77c1.03,0,1.86-.84,1.86-1.86s-.83-1.87-1.86-1.87-1.86.84-1.86,1.87.83,1.86,1.86,1.86Z'
            fill={color}
            strokeWidth='0'
          />
          <path
            d='M21.91,22.27c-1.03,0-1.86.84-1.86,1.86s.83,1.87,1.86,1.87,1.86-.84,1.86-1.87-.83-1.86-1.86-1.86Z'
            fill={color}
            strokeWidth='0'
          />
          <path
            d='M35.03,21.9l-1.77-2.65c-.3-.44-.3-1.02,0-1.46l1.68-2.51c.42-.64.65-1.41.5-2.15-.18-.95-.83-1.71-1.72-2.04l-2.99-1.1c-.5-.18-.84-.65-.86-1.18l-.12-3.03c-.03-.76-.3-1.51-.86-2.03-.71-.66-1.67-.89-2.59-.63l-3.06.87c-.52.15-1.07-.03-1.4-.45l-1.9-2.43c-.33-.42-.75-.78-1.26-.95-1.17-.4-2.36-.02-3.06.87l-1.97,2.51c-.33.42-.88.6-1.4.45l-2.9-.82c-.73-.21-1.54-.19-2.2.18-.85.47-1.37,1.33-1.4,2.27l-.13,3.19c-.02.53-.36,1-.86,1.18l-2.89,1.07c-.5.18-.98.47-1.3.9-.74.98-.75,2.23-.11,3.18l1.77,2.65c.3.44.3,1.02,0,1.46l-1.68,2.51c-.42.63-.65,1.4-.5,2.15.18.95.83,1.71,1.72,2.04l2.99,1.1c.5.18.84.65.86,1.18l.12,3.02c.03.76.3,1.52.86,2.04.71.66,1.68.89,2.59.63l3.06-.87c.52-.15,1.07.03,1.4.45l1.9,2.43c.34.42.76.78,1.26.95,1.17.4,2.36.02,3.06-.87l1.97-2.51c.33-.42.88-.6,1.4-.45l2.9.82c.73.21,1.54.19,2.2-.18.85-.47,1.37-1.33,1.4-2.27l.13-3.19c.02-.53.36-1,.86-1.18l2.89-1.07c.5-.18.98-.47,1.3-.9.74-.98.75-2.23.11-3.18ZM13.58,9.04c2.13,0,3.86,1.74,3.86,3.87s-1.73,3.86-3.86,3.86-3.86-1.73-3.86-3.86,1.73-3.87,3.86-3.87ZM21.91,28c-2.13,0-3.86-1.74-3.86-3.87s1.73-3.86,3.86-3.86,3.86,1.73,3.86,3.86-1.73,3.87-3.86,3.87ZM25.9,11.77l-14.9,14.91c-.2.19-.45.29-.71.29s-.51-.1-.7-.29c-.4-.39-.4-1.02,0-1.42l14.9-14.9c.39-.39,1.02-.39,1.41,0s.39,1.02,0,1.41Z'
            fill={color}
            strokeWidth='0'
          />
        </g>
      </g>
    </svg>
  );
};

export default MembershipActiveIcon;
