import React from 'react';
import ChevronIcon from '../../icons/chevronIcon';
import styles from './buttonProccessing.module.css';
import { useTranslation } from 'react-i18next';
import { ButtonCheckIcon } from '../../icons/ButtonCheckIcon';

export const ButtonStatus = {
  Normal: 'NORMAL',
  InProgress: 'INPROGRESS',
  ProgressFinished: 'FINISHED',
  Error: 'ERROR',
};
const ButtonProccessing = ({
  text,
  color = '#FFF',
  size,
  arrow = false,
  type = 'submit',
  onClick,
  className,
  disabled = false,
  backgroundColor = disabled ? 'var(--color-dark-gray)' : '#000',
  inProgressText = '',
  status,
  fontSize = '1.0625rem',
  widthConfig = null,
  heightConfig = null,
  hasBorder = false,
  iconColor = '#fff',
}) => {
  if (!status) status = ButtonStatus.Normal;
  if (!inProgressText) inProgressText = text;
  const { t } = useTranslation();

  let statusClass = '';
  switch (status) {
    case ButtonStatus.InProgress:
      statusClass = styles.btnActive;
      break;
    case ButtonStatus.ProgressFinished:
      statusClass = styles.btnComplete;
      break;
    case ButtonStatus.Error:
      statusClass = styles.btnBorderError;
      break;
    default:
      statusClass = '';
      break;
  }

  if (disabled) {
    statusClass = styles.disabled;
  }

  const sizeConfig = {
    large: {
      width: '100%',
      height: '4rem',
    },
    medium: {
      width: widthConfig ? widthConfig : '322px',
      height: '45px',
    },
    small: {
      width: widthConfig ? widthConfig : '100px',
      height: '45px',
    },
    custom: {
      height: heightConfig,
      width: widthConfig,
    },
  };

  const { width, height } = sizeConfig[size];

  const buttonStyles = {
    color,
    backgroundColor,
    width,
    height,
  };

  return (
    <button
      style={buttonStyles}
      className={`${styles.buttonWellet} ${statusClass} ${className} ${styles.fillBack} ${
        hasBorder ? styles.border : ''
      }`}
      type={type}
      onClick={onClick}
      disabled={disabled}
    >
      <div className={styles.text}>
        <span className={styles.btnContent}>
          <div
            className='d-flex align-items-center justify-content-center ml-2'
            style={{ fontSize: fontSize }}
          >
            {text}
            {arrow ? (
              <span className='ml-2'>
                <ChevronIcon size={20} fillColor={iconColor} />
              </span>
            ) : (
              ''
            )}
          </div>
        </span>
        <span className={styles.btnProcessing} style={{ fontSize: fontSize }}>
          {inProgressText ? inProgressText : t('processing')}
        </span>
        <div className={styles.btnProgress}></div>
        <span className={`${styles.btnCheck}`}>
          <ButtonCheckIcon size={'20px'} color='#fff' />
        </span>
      </div>
    </button>
  );
};

export default ButtonProccessing;
