import React from 'react';

const ContentApp = ({ children, header = false, menu = false, classes = '', style }) => {
  const contentAppClassName = `content-app container ${menu ? 'menu-fixed' : ''} ${
    header ? 'header-fixed' : ''
  }`;

  return (
    <div className={contentAppClassName + ' ' + classes} style={{ style }}>
      <div className='row justify-content-center'>
        <div className='col-12 col-md-7 col-lg-6'>{children}</div>
      </div>
    </div>
  );
};

export default ContentApp;
