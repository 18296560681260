import React from 'react';

const BankIcon = ({ size, color }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      version='1.0'
      width={size}
      height={size}
      viewBox='0 0 64.000000 50.000000'
      preserveAspectRatio='xMidYMid meet'
    >
      <g
        transform='translate(0.000000,50.000000) scale(0.100000,-0.100000)'
        fill={color}
        stroke='none'
      >
        <path d='M187 448 c-111 -48 -118 -52 -115 -77 l3 -26 245 0 245 0 3 26 c3 25 -4 29 -119 78 -67 28 -127 51 -133 50 -6 0 -64 -23 -129 -51z m233 -48 c0 -6 -40 -10 -100 -10 -60 0 -100 4 -100 10 0 6 40 10 100 10 60 0 100 -4 100 -10z' />
        <path d='M120 240 l0 -80 35 0 35 0 0 80 0 80 -35 0 -35 0 0 -80z' />
        <path d='M450 240 l0 -80 35 0 35 0 0 80 0 80 -35 0 -35 0 0 -80z' />
        <path d='M291 264 c-16 -21 -18 -30 -10 -46 6 -11 20 -23 30 -25 10 -3 19 -12 19 -19 0 -19 -10 -18 -25 3 -7 9 -17 13 -24 9 -9 -6 -7 -16 8 -39 25 -40 37 -40 61 -1 18 27 18 32 4 52 -8 12 -22 22 -30 22 -8 0 -14 5 -14 10 0 14 13 13 28 -2 8 -8 15 -9 22 -2 7 7 4 19 -10 37 -27 33 -33 34 -59 1z' />
        <path d='M107 134 c-4 -4 -7 -16 -7 -26 0 -15 8 -18 56 -18 52 0 55 1 52 23 -3 19 -10 22 -48 25 -25 2 -49 0 -53 -4z' />
        <path d='M437 134 c-4 -4 -7 -16 -7 -26 0 -15 8 -18 56 -18 52 0 55 1 52 23 -3 19 -10 22 -48 25 -25 2 -49 0 -53 -4z' />
        <path d='M83 63 c-7 -2 -13 -18 -13 -34 l0 -29 251 0 250 0 -3 33 -3 32 -235 2 c-129 1 -241 -1 -247 -4z' />
      </g>
    </svg>
  );
};

export default BankIcon;
