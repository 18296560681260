export const errorText = (message, setErrorUser, t) => {
  switch (message) {
    case 'USER_NOT_FOUND':
      setErrorUser(t('emailError.USER_DOES_NOT_EXIST'));
      break;
    case 'USER_DISABLED':
      setErrorUser(t('emailError.USER_DISABLED'));
      break;
    case 'USER_DELETED':
      setErrorUser(t('emailError.USER_DELETED'));
      break;
    default:
      setErrorUser(t('emailError.USER_ALREADY_IN_USE'));
      break;
  }
};
