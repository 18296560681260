import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAssociateSaleSetup, logout } from '../../../../actions/associatesActions';
import { useTranslation } from 'react-i18next';
import KycStatusIcon from '../../../../components/icons/Kyc/KycStatusIcon';
import NotificactionScreen from '../../../../components/Common/NotificactionScreen/NotificactionScreen';
import './Kyc.css';
import ContentApp from '../../../../components/ContentApp';

const KycStatus = ({ history }) => {
  const { t } = useTranslation();
  const associates = useSelector((state) => state.associates);
  const dispatch = useDispatch();
  const app = useSelector((state) => state.app);
  useEffect(() => {
    const fetchData = () => {
      dispatch(getAssociateSaleSetup(associates.jwt));
    };
    const intervalId = setInterval(fetchData, 20000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (associates.user.kycStatus === 'APPROVED') {
      history.push('/');
    }
  }, [associates.user.kycStatus]);

  const handleSignOut = () => {
    dispatch(logout());
  };

  return (
    <ContentApp>
      <div className='kyc-status'>
        <div className='d-flex justify-content-center align-items-center'>
          <KycStatusIcon size={140} color={'#fff'} />
        </div>
        <div className='text-left p-2 mt-3'>
          <h2 className='font-large bold' style={{ color: 'var(--color-font-primary)' }}>
            {t('kyc.kycStatusTitle')}
          </h2>
          <div className='text-secondary mt-2 font-normal' style={{ lineHeight: '1.4rem' }}>
            {t('kyc.kycStatusFirstDescription')}
          </div>
          <div className='text-secondary mt-2 font-normal' style={{ lineHeight: '1.4rem' }}>
            {t('kyc.kycStatusSecondDescription')}
          </div>
          <div className='text-secondary mt-2 font-normal' style={{ lineHeight: '1.4rem' }}>
            {t('kyc.kycStatusThirdDescription', {
              organization: app.name,
            })}
          </div>
        </div>
        <div
          className='text-center mt-3 text-secondary'
          style={{ position: 'fixed', bottom: 40, width: '100%', left: 0 }}
          onClick={handleSignOut}
        >
          {t('menu.logout')}
        </div>
      </div>
    </ContentApp>
  );
};

export default KycStatus;
