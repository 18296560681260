import { Skeleton } from '@mui/material';

export const skeletonFrequentQuestions = () => {
  return (
    <div className='mt-2'>
      <Skeleton
        variant='rectangular'
        sx={{ bgcolor: 'grey.900', width: '100%', borderRadius: '.4rem', height: 140 }}
      />
    </div>
  );
};
