import React, { useContext, useEffect, useState } from 'react';
import { WelletContext } from '../../context/WelletContext';
import { useTranslation } from 'react-i18next';
import styles from './userRp.module.css';

const UserRp = ({ image, name, email, position, company, className }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(null);
  const [profileImage, setProfileImage] = useState(null);
  const welletContext = useContext(WelletContext);

  const getProfileImage = async (image) => {
    setLoading(true);
    try {
      const response = await welletContext.apis.tickets.get(image, {
        responseType: 'blob',
      });
      const fileReaderInstance = new FileReader();
      fileReaderInstance.readAsDataURL(response.data);
      fileReaderInstance.onload = () => {
        setProfileImage(fileReaderInstance.result);
      };
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (image) {
      getProfileImage(image);
    }
  }, []);

  return (
    <div className={`px-2`}>
      <div className={`py-2 ${className}`}>
        <div className='row '>
          <div
            className={`col-auto my-auto pr-3 ${styles.imgContainer}`}
            style={{ minWidth: '72px' }}
          >
            {!loading && <img src={profileImage} alt='img' className={styles.userImg} />}
          </div>
          <div className={`${image ? 'col my-auto pl-0' : 'col'} bold`}>
            {name}
            <div className='light'>
              <div className='text-secondary'>{email && email}</div>
              <div className='text-secondary'>{company && `${t('company')}: ${company}`}</div>
              <div className='text-secondary text-capitalize'>
                {position && `${t('position')}: ${position}`}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserRp;
