import gigamont from '../api/gigamont';
import axios from 'axios';
import { setExtraProductStatus } from './extraProductActions';

const CancelToken = axios.CancelToken;
let cancel;

const loadingProducts = () => {
  return {
    type: 'LOADING_PRODUCTS',
  };
};

export const reloadProducts = () => {
  return {
    type: 'REQUIRES_LOADING_PRODUCTS',
  };
};

export const disableProducts = () => {
  return {
    type: 'DISABLE_PRODUCTS',
  };
};
export const resetProductsState = () => {
  return {
    type: 'RESET_PRODUCTS_STATE',
  };
};

export const setPerformance = (performance) => {
  return {
    type: 'PRODUCTS_SHOW_TIME_SELECTED',
    payload: { performance },
  };
};

export const setProductList = (responseData) => {
  return {
    type: 'READY_PRODUCTS',
    payload: {
      products: responseData.options.length > 0 ? responseData.options[0].products : [],
      performance: responseData.options.length > 0 ? responseData.options[0].performance : [],
      allShows: responseData.options,
    },
  }; //todo: esto asume que hay un solo show time
};

export const selectProduct = (product, quantity, price) => {
  return (dispatch, getState) => {
    dispatch({
      type: 'PRODUCT_SELECTED',
      payload: { product, quantity, price },
    });
  };
};

export const selectExtraPax = (product, quantity) => {
  return (dispatch) => {
    dispatch({
      type: 'PRODUCT_EXTRA_PAX_SELECTED',
      payload: { product, quantity },
    });
  };
};

export const update = async (productId, stock, jwt) => {
  const result = await gigamont()
    .post(
      '/products/update',
      {
        productId: productId,
        stock: stock,
      },
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      },
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });

  return result;
};