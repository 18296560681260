import { Skeleton } from '@mui/material';

export const SkeletonPayments = () => {
  return (
    <div className='mt-2'>
      <Skeleton
        variant='rectangular'
        sx={{
          bgcolor: 'grey.900',
          width: '100%',
          borderRadius: '.4rem',
          height: 200,
        }}
      />
    </div>
  );
};

export const SkeletonCashierOperation = () => {
  return (
    <div className='p-3'>
      <Skeleton
        variant='rectangular'
        sx={{
          bgcolor: 'grey.900',
          width: '100%',
          borderRadius: '.4rem',
          height: 392,
        }}
      />
    </div>
  );
};
