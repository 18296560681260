import React from 'react';

const WarningIcon = ({ size, color }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 120 136'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        id='Vector'
        d='M115 20.3998C97.2467 20.3998 79.9467 13.9874 63 1.01999C62.1345 0.357904 61.0818 0 60 0C58.9181 0 57.8655 0.357904 57 1.01999C40.0533 13.9874 22.7533 20.3998 5 20.3998C3.67392 20.3998 2.40215 20.9371 1.46447 21.8935C0.526784 22.8499 0 24.1471 0 25.4997V61.1993C0 95.2057 19.72 120.195 58.1667 135.645C59.3448 136.118 60.6552 136.118 61.8333 135.645C100.28 120.195 120 95.1989 120 61.1993V25.4997C120 24.1471 119.473 22.8499 118.536 21.8935C117.598 20.9371 116.326 20.3998 115 20.3998ZM10 30.45C27.18 29.4165 43.8667 23.0381 60 11.4103C76.1333 23.0381 92.82 29.4165 110 30.45V61.1993C110 90.1397 93.6467 111.376 60 125.404C26.3533 111.376 10 90.1397 10 61.1993V30.45ZM64.9533 38.4331C64.7807 37.1531 64.138 35.9876 63.1553 35.1721C62.1726 34.3567 60.9231 33.9521 59.6592 34.0402C58.3953 34.1283 57.2113 34.7024 56.3465 35.6466C55.4816 36.5908 55.0004 37.8346 55 39.1267V83.3126L55.0467 84.0062C55.2193 85.2863 55.862 86.4518 56.8447 87.2672C57.8274 88.0827 59.0769 88.4872 60.3408 88.3991C61.6047 88.3111 62.7887 87.7369 63.6535 86.7928C64.5184 85.8486 64.9996 84.6048 65 83.3126V39.1267L64.9533 38.4331ZM60 108.799C61.7681 108.799 63.4638 108.082 64.714 106.807C65.9643 105.532 66.6667 103.802 66.6667 101.999C66.6667 100.195 65.9643 98.4658 64.714 97.1905C63.4638 95.9153 61.7681 95.1989 60 95.1989C58.2319 95.1989 56.5362 95.9153 55.286 97.1905C54.0357 98.4658 53.3333 100.195 53.3333 101.999C53.3333 103.802 54.0357 105.532 55.286 106.807C56.5362 108.082 58.2319 108.799 60 108.799Z'
        fill={color}
      />
    </svg>
  );
};

export default WarningIcon;
