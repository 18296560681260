import React from 'react';

const FilterIcon = ({ size }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 17 12'>
      <path
        d='M3.5 6H13.5M1 1H16M6 11H11'
        stroke='#777777'
        strokeWidth='1.66667'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default FilterIcon;
