import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/Common/Button/Button';
import styles from './LoginScreen.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { CircleSpinner } from 'react-spinners-kit';
import { setCanRefresh } from '../../../actions/appActions';

const Login = ({ history }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const app = useSelector((state) => state.app);
  const [loadingBackground, setLoadingBackground] = useState(true);
  const [loadingLogo, setLoadingLogo] = useState(true);
  const videoRef = useRef(null);
  const [isVisible, setIsVisible] = useState(true);
  const hasMarkets = app.markets.length > 1;

  useEffect(() => {
    dispatch(setCanRefresh(false));
    return () => {
      dispatch(setCanRefresh(true));
    };
  }, []);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'hidden') {
        setIsVisible(false);
      } else if (document.visibilityState === 'visible') {
        setIsVisible(true);
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    if (videoRef.current) {
      if (isVisible) {
        videoRef.current.currentTime = 0;
        videoRef.current.play();
      } else {
        videoRef.current.pause();
      }
    }
  }, [isVisible]);

  const onSignInHandle = () => {
    history.push({
      pathname: '/login-user',
    });
  };

  const onSignUpHandle = () => {
    if (hasMarkets) {
      history.push({
        pathname: '/market-selector',
      });
    } else {
      history.push({
        pathname: '/sign-up-terms',
      });
    }
  };

  const handleImageLoad = () => {
    setLoadingBackground(false);
  };

  const handleLogoLoad = () => {
    setLoadingLogo(false);
  };

  return (
    <div className={styles.container}>
      {loadingBackground && loadingLogo && (
        <div className='loading-app'>
          <div className='loaderDiv'>
            <CircleSpinner
              className='spinner-app mx-auto'
              style={{ margin: 'auto' }}
              size={40}
              color='var(--color-font-primary)'
            />
          </div>
        </div>
      )}
      {app.logoType && (
        <div className='position-absolute w-100'>
          <div className='row justify-content-center'>
            <div className='col-6 col-md-3 col-lg-3 p-0'>
              <img
                onLoad={handleLogoLoad}
                src={app.logoType}
                className={`${styles.logoType} ${styles.noSelect}`}
                alt='background-img'
              />
            </div>
          </div>
        </div>
      )}

      {app.videoBackground ? (
        <video
          ref={videoRef}
          className={styles.imgBkg}
          style={{
            display: loadingBackground ? 'none' : 'block',
          }}
          autoPlay
          muted
          loop={isVisible}
          playsInline
          onLoadStart={handleImageLoad}
          webkit-playsinline='true'
          x5-playsinline='true'
          src={app.videoBackground}
        />
      ) : app.backGroundImageUrl ? (
        <img
          src={app.backGroundImageUrl}
          className={styles.imgBkg}
          style={{
            display: loadingBackground ? 'none' : 'block',
          }}
          onLoad={handleImageLoad}
          alt='background-img'
        />
      ) : null}

      {!loadingBackground && (
        <div className='mb-5 px-3' style={{ position: 'absolute', bottom: '0', width: '100%' }}>
          <div className='row justify-content-md-center'>
            <div className='col-12 col-md-6 col-lg-5 p-md-0'>
              <div className='mt-3'>
                <Button
                  size={'large'}
                  onClick={onSignInHandle}
                  text={t('login.login')}
                  color='#000'
                  backgroundColor='var(--color-primary)'
                  className={styles.noSelect}
                />
                <div className='mt-2'>
                  <Button
                    size='large'
                    className={`${styles.noSelect} position-relative `}
                    onClick={onSignUpHandle}
                    text={t('login.newAccount')}
                    color='#000'
                    backgroundColor='#fff'
                    icon={true}
                    typeIcon='arrow'
                    iconColor='#000'
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Login;
