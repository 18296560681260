import React from 'react';

const PaymentsActiveIcon = ({ size = '25px', color = 'var(--color-primary)' }) => {
  return (
    <svg
      id='b'
      data-name='Capa 2'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 33.53 33.52'
      width={size}
      height={size}
    >
      <g id='c' data-name='Capa 1'>
        <path
          d='M16.76,0C7.52,0,0,7.52,0,16.76s7.52,16.76,16.76,16.76,16.76-7.52,16.76-16.76S26.01,0,16.76,0ZM14.67,15.02c.42.49,1.02.74,1.78.74h.63c1.36,0,2.5.5,3.3,1.44.69.81,1.08,1.91,1.08,3.08,0,2.11-1.32,4.33-3.78,4.74v1.52c0,.55-.45,1-1,1s-1-.45-1-1v-1.55c-2.76-.49-3.58-2.7-3.61-3.93-.01-.55.43-1.01.98-1.02.57.02,1.01.43,1.02.98.02.34.22,2.08,2.78,2.08,1.79,0,2.61-1.46,2.61-2.82,0-.7-.21-1.33-.6-1.78-.42-.49-1.02-.74-1.78-.74h-.63c-1.36,0-2.5-.5-3.3-1.44-.69-.81-1.07-1.91-1.07-3.08,0-2.06,1.26-4.23,3.61-4.72v-1.55c0-.55.45-1,1-1s1,.45,1,1v1.52c2.9.43,3.75,2.7,3.78,3.96.01.55-.43,1.01-.98,1.02-.52-.02-1-.42-1.02-.97-.02-.35-.23-2.09-2.78-2.09-1.79,0-2.61,1.46-2.61,2.82,0,.7.21,1.33.6,1.78Z'
          fill={color}
          strokeWidth='0'
        />
      </g>
    </svg>
  );
};

export default PaymentsActiveIcon;
