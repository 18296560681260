import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import * as Sentry from '@sentry/react';

import App from './components/App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import initTranslations from './i18n';
import reducers from './reducers';
import { configureApi } from './api/gigamont';
import { initNativeAppBridge } from './services/firebase/pushNotifications';
import { cleanLanguageCode } from './helpers/language';
import FusionCharts from 'fusioncharts';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
configureApi(process.env.REACT_APP_SALES_API_URL);

Sentry.init({
  dsn: 'https://35db079cde1afa7c3af4a3de7d206ded@o4506752576126976.ingest.sentry.io/4506752578158592',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  environment: process.env.REACT_APP_ENVIRONMENT,
  release: process.env.REACT_APP_VERSION,
  // Performance Monitoring
  tracesSampleRate: 0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  //tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  //replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  //replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const ignoreActions = ['ASSOCIATES_SALES_SETUP', 'ASSOCIATES_AUTHENTICATED', 'ASSOCIATES_INFO'];
const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options listed https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/redux/

  actionTransformer: (action) => {
    if (ignoreActions.indexOf(action.type) >= 0) {
      // Return null to not log the action to Sentry
      return null;
    }
    return action;
  },

  stateTransformer: (state) => {
    const transformedState = {
      ...state,
      associates: {
        ...state.associates,
        jwt: null,
      },
    };

    return transformedState;
  },
});

const store = createStore(reducers, composeEnhancers(applyMiddleware(thunk), sentryReduxEnhancer));

const getAppLanguage = (lang) => {
  if (lang === 'es') return lang;
  return 'en';
};

var container = document.getElementById('buytickets');
const languageNavigator = navigator.language;
const locale = new Intl.Locale(languageNavigator);
const browserLanguage = cleanLanguageCode(locale.toString());
const language = getAppLanguage(browserLanguage);

initTranslations(language);

// bypass auto scrolling.
if ('scrollRestoration' in window.history) {
  window.history.scrollRestoration = 'manual';
}

const startOpen = container.getAttribute('startOpen') === 'true' ? true : false;
const multiShow = container.getAttribute('multiShow') === 'true' ? true : false;
const root = ReactDOM.createRoot(container);

FusionCharts.options.license({
  key: 'zhF2i1J-7sB2A6D2B4D3H4A3B2B6D4C7A3B4pckH3B5ljf1B-9kC4D2bvgE3A3B2E4C3F3F3B4D6C6E3B3G2G4A19A32vikC6D3G4kD3H4A9B-21B-16tE3D4A2rveA4D4E2vnjH1B3C2A4C5A3E4B2G4E3C3megB5B2VD3isbD5D1B2sbE2EI2A3C-16xiC1A4B4E2F2C6C3A5B2F1E4C1E1G3q==',
  creditLabel: false,
});

initNativeAppBridge();

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App
        website={container.getAttribute('website')}
        language={language}
        currency={container.getAttribute('currency')}
        show={container.getAttribute('show')}
        date={container.getAttribute('date')}
        coupon={container.getAttribute('coupon')}
        filter={container.getAttribute('filter')}
        isMultiShow={multiShow}
        startOpen={startOpen}
        affiliateCode={container.getAttribute('affiliateCode')}
        refCode={container.getAttribute('refCode')}
        device={container.getAttribute('device')}
      />
    </BrowserRouter>
  </Provider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
  // Note (Gabriel): this will trigger a reload of the page when a new service worker is installed.
  // With every deployment: when page is refreshed, after a few seconds the page (in all open tabs) is reloaded.
  // so the latest service worker is taken into account.
  // We saw that this is not working properly in Safari, so reload doesn't happen there (nor iOS apps).
  // The whole onUpdate code can be removed if we don't want this reload effect (requires to close all tabs, and apps to
  // get service workers updates activated)
  onUpdate: (registration) => {
    const waitingServiceWorker = registration.waiting;

    if (waitingServiceWorker) {
      waitingServiceWorker.addEventListener('statechange', (event) => {
        if (event.target.state === 'activated') {
          window.location.reload();
        }
      });
      waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
    }
  },
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
