import { Skeleton } from '@mui/material';

export const SkeletonBalancePerMonthCard = () => {
  return (
    <>
      <div className='mt-2'>
        <Skeleton
          variant='rectangular'
          sx={{
            bgcolor: 'grey.900',
            width: '100%',
            borderRadius: '.4rem',
            height: 93,
          }}
        />
      </div>
      <div className='mt-3'>
        <Skeleton
          variant='rectangular'
          sx={{
            bgcolor: 'grey.900',
            width: '100%',
            borderRadius: '.4rem',
            height: 93,
          }}
        />
      </div>
      <div className='mt-3'>
        <Skeleton
          variant='rectangular'
          sx={{
            bgcolor: 'grey.900',
            width: '100%',
            borderRadius: '.4rem',
            height: 93,
          }}
        />
      </div>
    </>
  );
};

export const SkeletonBalancePerMonthCardDetails = () => {
  return (
    <>
      <div className='mt-2'>
        <Skeleton
          variant='rectangular'
          sx={{
            bgcolor: 'grey.900',
            width: '100%',
            borderRadius: '.4rem',
            height: 250,
          }}
        />
      </div>
    </>
  );
};
