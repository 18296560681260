import React, { useContext, useState, useEffect, useRef } from 'react';
import Input from 'react-phone-number-input/input';
import { useSelector } from 'react-redux';
import { WelletContext } from '../../../context/WelletContext';
import { Toast, ToastType } from '../../../components/Common/Toast/Toast';
import { SkeletonProfile } from '../../../components/Skeletons/SkeletonProfile';
import { useTranslation } from 'react-i18next';
import UserIcon from '../../../components/icons/userIcon';
import Header from '../../../components/Common/Header/Header';
import ContentApp from '../../../components/ContentApp';
import ItemProfile from './ItemProfile';
import ItemProfileEditable from './ItemProfileEditable';
import styles from './userProfile.module.css';

const UserProfile = ({ history }) => {
  const { t } = useTranslation();
  const user = useSelector((state) => state.associates.user);
  const welletContext = useContext(WelletContext);
  const [userInfo, setUserInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [address, setAddress] = useState(userInfo?.address || '');
  const [isAddressEditing, setIsAddressEditing] = useState(false);
  const [rfc, setRfc] = useState(userInfo?.taxIdentification || '');
  const [isRfcEditing, setIsRfcEditing] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [showToastError, setShowToastError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const phoneNumber = userInfo?.phoneNumber;
  const rfcInputRef = useRef(null);
  const addressInputRef = useRef(null);

  const updateAddress = async (address) => {
    if (address.trim() === '') {
      setErrorMessage(t('profileError.EMPTY_ADDRESS'));
      setShowToastError(true);
      return;
    }

    const minLength = 5;
    const maxLength = 50;

    const validCharactersRegex = /^[a-zA-Z0-9\s,.'-]+$/;

    if (address.length < minLength) {
      setErrorMessage(t('profileError.SHORT_ADDRESS'));
      setShowToastError(true);
      return;
    }

    if (address.length > maxLength) {
      setErrorMessage(t('profileError.LONG_ADDRESS'));
      setShowToastError(true);
      return;
    }

    if (!validCharactersRegex.test(address)) {
      setErrorMessage(t('profileError.INVALID_ADDRESS'));
      setShowToastError(true);
      return;
    }

    try {
      const result = await welletContext.apis.tickets.put(`/users/updateAddress`, {
        address: address,
      });
      if (result.status === 200) {
        setShowToast(true);
        setIsAddressEditing(false);
      }
    } catch (error) {
      setIsAddressEditing(false);
      console.error(error);
    }
  };

  const updateRfc = async (rfc) => {
    if (rfc.trim() === '') {
      setErrorMessage(t('profileError.EMPTY_RFC'));
      setShowToastError(true);
      return;
    }
    const validLengths = [11, 12, 13];

    if (!validLengths.includes(rfc.length)) {
      setErrorMessage(t('profileError.INVALID_RFC'));
      setShowToastError(true);
      return;
    }

    try {
      const result = await welletContext.apis.tickets.put(`/users/updateTaxIdentification`, {
        TaxIdentification: rfc,
        TaxIdentificationType: 'RFC',
      });
      if (result.status === 200) {
        setShowToast(true);
        setIsRfcEditing(false);
      }
    } catch (error) {
      setIsRfcEditing(false);
      console.error(error);
    }
  };

  const fetchUser = async () => {
    try {
      const response = await welletContext.apis.tickets.get(`/users/getprofile`);
      if (response.status === 200) {
        setUserInfo(response.data);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  if (errorMessage) {
    setTimeout(() => {
      setShowToastError(false);
      setErrorMessage(null);
    }, 4000);
  }

  useEffect(() => {
    if (userInfo == null) {
      fetchUser();
    }
    if (showToast) {
      setTimeout(() => {
        setShowToast(false);
      }, 4000);
    }
  }, [userInfo, showToast, setShowToast]);

  useEffect(() => {
    if (userInfo != null) {
      setAddress(userInfo.address || '');
      setRfc(userInfo.taxIdentification || '');
      setLoading(false);
    }
  }, [userInfo]);

  return (
    <>
      {showToast && <Toast top={false} zIndex={99} type={ToastType.Success} message={t('updated')} delay={2} />}
      {showToastError && (
        <Toast top={false} zIndex={99} type={ToastType.Error} message={errorMessage} delay={2} />
      )}
      <>
        <Header
          title={<div className='bold'>{t('profile.title')}</div>}
          history={history}
          hasBackground={true}
          pathName={'/'}
        />
        <ContentApp>
          {!loading ? (
            <div className='w-100 container fade-up'>
              <div className='user-profile card'>
                <div className='associate-user-photo-profile mt-2 row'>
                  {user.profileImage ? (
                    <img
                      src={user.profileImage}
                      alt={user.displayName}
                      className={styles.associateUserImage}
                    />
                  ) : (
                    <UserIcon color='var(--color-light-gray)' size='40px' />
                  )}
                </div>
                <div className='activity-feed mt-4'>
                  <ItemProfile title={t('profile.name')} subtitle={userInfo.fullName} />
                  <ItemProfile title='Email' subtitle={userInfo.email} />
                  <ItemProfile
                    title={t('profile.id')}
                    subtitle={userInfo.documentNumber ? userInfo.documentNumber : '-'}
                  />
                  <ItemProfile title={t('profile.nationality')} subtitle={userInfo.nationality} />
                  <ItemProfile
                    title={t('profile.phoneNumber')}
                    subtitle={
                      <Input
                        value={phoneNumber}
                        placeholder={phoneNumber}
                        international={true}
                        className='text-secondary p-0'
                      />
                    }
                  />
                  <ItemProfileEditable
                    title={t('profile.address')}
                    subtitle={
                      <input
                        type='text'
                        name='address'
                        placeholder={address || '-'}
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                        className='input-user-profile text-truncate text-secondary'
                        readOnly={!isAddressEditing}
                        ref={addressInputRef}
                      />
                    }
                    isEditing={isAddressEditing}
                    setIsEditing={setIsAddressEditing}
                    onClick={() => {
                      updateAddress(address);
                    }}
                    inputRef={addressInputRef}
                  />
                  <ItemProfileEditable
                    title='RFC'
                    subtitle={
                      <input
                        type='text'
                        name='rfc'
                        placeholder={rfc || '-'}
                        value={rfc}
                        onChange={(e) => setRfc(e.target.value)}
                        className='input-user-profile text-truncate text-secondary'
                        readOnly={!isRfcEditing}
                        ref={rfcInputRef}
                      />
                    }
                    isEditing={isRfcEditing}
                    setIsEditing={setIsRfcEditing}
                    onClick={() => updateRfc(rfc)}
                    className={user.userRp ? 'border-bottom border-secondary' : 'border-none'}
                    inputRef={rfcInputRef}
                  />
                  {user.userRp && (
                    <ItemProfile
                      title={t('profile.rp')}
                      subtitle={user.userRp.name}
                      className={'border-none mb-2'}
                    />
                  )}
                </div>
              </div>
            </div>
          ) : (
            SkeletonProfile()
          )}
        </ContentApp>
      </>
    </>
  );
};

export default UserProfile;
