import { useContext } from 'react';
import { useActivities } from '../../../hooks/useActivities';
import { WelletContext } from '../../../context/WelletContext';
import { format } from 'date-fns';
import { getLocale } from '../../../helpers/dates';
import ContentApp from '../../../components/ContentApp';
import Header from '../../../components/Common/Header/Header';
import Activity from '../../../components/Activities/Activity/Activity';
import Money from '../../../components/Common/Money/Money';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Spinner } from '../../../components/Common/Spinner/Spinner';

const AllPayments = () => {
  const { t } = useTranslation();
  const welletContext = useContext(WelletContext);
  const app = useSelector((state) => state.app);
  const locale = getLocale(app.language);

  const withdrawalsInfoData = useActivities({
    pageSize: 1000,
    endpoint: '/associates/GetAccreditedPayments',
    api: welletContext.apis.tickets,
    isReservation: true,
  });

  const formatDate = (dateTime, locale) => {
    const activityDate = new Date(dateTime);
    return format(activityDate, "dd MMM 'de' yyyy", { locale });
  };

  return (
    <>
      <Header title={t('myPayments')} hasBackground={true} showBack={true} />
      <ContentApp>
        <div className={`${withdrawalsInfoData.loading ? '' : 'card'} mt-2 py-2 px-3`}>
          {withdrawalsInfoData?.activities?.map((w, index) => (
            <Activity
              key={index}
              loading={withdrawalsInfoData.loading}
              hasMore={withdrawalsInfoData.hasMore}
              loadMore={withdrawalsInfoData.loadMore}
              item1={<div className='semi-bold'>{formatDate(w.createdAt, locale)}</div>}
              item2={
                <div className='semi-bold'>
                  <Money value={w.amount} lowDecimals decimal={false} symbolSize='0.85rem' />
                </div>
              }
            />
          ))}
        </div>
        {withdrawalsInfoData?.loading && (
          <div className='d-flex justify-content-center mt-3'>
            <Spinner size={25} color='#fff' />
          </div>
        )}
      </ContentApp>
    </>
  );
};
export default AllPayments;
