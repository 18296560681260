import React from 'react';
import { CircleSpinner } from 'react-spinners-kit';
import './spinner.css';

export function Spinner({ size = 40, color = '#fff' }) {
  return (
    <div className='wlt-spinner' style={{ height: size + 'px' }}>
      <CircleSpinner size={size} color={color} />
    </div>
  );
}
