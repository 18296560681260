import React from 'react';

const FixedButton = ({ children, backgroundColor }) => {
  return (
    <div
      className='position-fixed w-100'
      style={{
        bottom: 0,
        left: 0,
        paddingBottom: 'env(safe-area-inset-bottom)',
        backgroundColor: backgroundColor,
      }}
    >
      <div className='container'>
        <div className='row justify-content-center '>
          <div className='col-12 col-md-7 col-lg-6'>
            <div className='mb-3 mx-1'>{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FixedButton;
