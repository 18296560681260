export const fetchAllSocialNetworks = (welletContext, language) => {
  try {
    const response = welletContext.apis.tickets.get(`/Campaign/list-networks?lang=${language}`);
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const fetchCampaigns = (welletContext, body) => {
  try {
    const response = welletContext.apis.tickets.post(`/campaign/associate/all`, body);
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const fetchCampaignById = (welletContext, language, id) => {
  try {
    const response = welletContext.apis.tickets.get(`Campaign/details/${language}/${id}`);
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const fetchShows = (welletContext, id) => {
  try {
    const response = welletContext.apis.tickets.get(`/shows/ByOrganization?cityId=${id}`);
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const fetchMediaById = (welletContext, id, lang) => {
  try {
    const response = welletContext.apis.tickets.get(`/Campaign/get-media/${id}?lang=${lang}`);
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const fetchLink = (welletContext, id, language) => {
  try {
    const response = welletContext.apis.tickets.get(`/Campaign/instructions/${language}/${id}`);
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const fetchTypesCampaign = (welletContext, type, language) => {
  try {
    const response = welletContext.apis.tickets.get(
      `/campaign/network/${type}/type?lang=${language}`,
    );
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const createCampaign = (welletContext, body) => {
  try {
    const response = welletContext.apis.tickets.post('/Campaign/create', body);
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const fetchCampaignStatistics = (welletContext, body) => {
  try {
    const response = welletContext.apis.tickets.post(`/campaign/statistics`, body);
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const fetchCampaignOccassion = (welletContext, body) => {
  try {
    const response = welletContext.apis.tickets.post(`/campaign/occassion`, body);
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const fetchNetworkCampaignIncomes = (welletContext, body) => {
  try {
    const response = welletContext.apis.tickets.post(`/campaign/netWorkCampaignIncomes`, body);
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const fetchCampaignReservationsStats = (welletContext, body) => {
  try {
    const response = welletContext.apis.tickets.post(`/campaign/graph`, body);
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const updateCampaign = (welletContext, body) => {
  try {
    const response = welletContext.apis.tickets.put(`Campaign/update`, body);
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const fetchCampaignReservations = (welletContext, body, campaignId, filter) => {
  try {
    const response = welletContext.apis.tickets.post(
      `/Campaign/list/${campaignId}?campaignCreatedBy=${filter}`,
      body,
    );
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const fetchCampaignsFeed = (welletContext, body) => {
  try {
    const response = welletContext.apis.tickets.post(`/campaign/feedCampaigns`, body);
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const fetchCampaignsFeedShows = (welletContext) => {
  try {
    const response = welletContext.apis.tickets.get(`/campaign/feedCampaigns/header`);
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const saveCampaign = (welletContext, id) => {
  try {
    const response = welletContext.apis.tickets.post(`/campaign/feedCampaigns/flag/${id}`);
    return response;
  } catch (error) {
    console.error(error);
  }
};
