import React from 'react';

const CertificateIcon = ({ size, color }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      version='1.0'
      width={size}
      height={size}
      viewBox='0 0 64.000000 50.000000'
      preserveAspectRatio='xMidYMid meet'
    >
      <g
        transform='translate(0.000000,50.000000) scale(0.100000,-0.100000)'
        fill={color}
        stroke='none'
      >
        <path d='M87 482 c-14 -16 -17 -45 -17 -190 0 -214 -8 -204 166 -200 l119 3 3 50 c4 66 30 95 87 95 58 0 85 -30 85 -93 0 -48 7 -56 28 -35 8 8 12 63 12 183 0 154 -2 174 -18 188 -16 15 -48 17 -234 17 -195 0 -216 -2 -231 -18z m353 -62 c0 -6 -47 -10 -120 -10 -73 0 -120 4 -120 10 0 6 47 10 120 10 73 0 120 -4 120 -10z m60 -60 c0 -6 -68 -10 -185 -10 -117 0 -185 4 -185 10 0 6 68 10 185 10 117 0 185 -4 185 -10z m0 -65 c0 -13 -27 -15 -179 -15 -163 0 -203 5 -184 24 3 3 87 6 185 6 152 0 178 -2 178 -15z m-182 -107 c-3 -9 -31 -14 -83 -16 -83 -3 -110 2 -100 18 10 15 188 13 183 -2z' />
        <path d='M405 195 c-14 -13 -25 -31 -25 -40 0 -21 44 -65 65 -65 22 0 65 45 65 68 0 23 -40 62 -64 62 -9 0 -28 -11 -41 -25z' />
        <path d='M380 43 c0 -40 13 -48 43 -29 21 14 25 14 45 0 29 -20 42 -11 42 29 l0 32 -65 0 -65 0 0 -32z' />
      </g>
    </svg>
  );
};

export default CertificateIcon;
