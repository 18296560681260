import React from 'react';
import ContentApp from '../../components/ContentApp';
import SpinnerLoader from '../../components/Common/Spinner/SpinnerLoader';

const ProcessingScreen = ({ title, subtitle }) => {
  return (
    <ContentApp>
      <div className='text-center px-4' style={{ marginTop: '50%' }}>
        <SpinnerLoader />
        <div className='text-left mt-5'>
          <h2 className='text-white'>{title}</h2>
          <div className='text-secondary font-medium'>{subtitle}</div>
        </div>
      </div>
    </ContentApp>
  );
};

export default ProcessingScreen;
