import React, { useState } from 'react';
import styles from './accordion.module.css';
import ChevronIcon from '../../icons/chevronIcon';

const Accordion = ({
  title,
  content,
  categoryPoints,
  points,
  type,
  showText = false,
  videoUrl,
}) => {
  const [selected, setSelected] = useState(showText);

  const toggle = () => {
    setSelected(!selected);
  };

  const isVideoUrl = (url) => {
    const videoExtensions = ['.mp4', '.webm', '.ogg'];
    return (
      videoExtensions.some((ext) => url.includes(ext)) ||
      url.includes('youtube.com') ||
      url.includes('youtu.be')
    );
  };

  const getEmbedUrl = (url) => {
    const youtubeRegex =
      /(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/|youtube\.com\/shorts\/)([a-zA-Z0-9_-]{11})/;
    const match = url.match(youtubeRegex);
    if (match && match[1]) {
      return `https://www.youtube.com/embed/${match[1]}`;
    }
    return url;
  };

  return (
    <div className={styles.accordionContainer}>
      {type === 'points' && <div className={styles.line}></div>}
      <div className={styles.wrapperAccordion}>
        <div className={styles.accordion}>
          <div className={styles.item}>
            <div
              className='my-auto row justify-content-between px-2 align-items-center'
              onClick={toggle}
            >
              {categoryPoints ? (
                <div className='row'>
                  <span className={`${styles.points} ml-2 mr-2`}>{`${categoryPoints}`}</span>
                  {points >= categoryPoints ? (
                    <span className='mr-2 text-secondary'>•</span>
                  ) : (
                    <span className='mr-2 text-secondary'>○</span>
                  )}
                  <span className=''>{title}</span>
                </div>
              ) : (
                <span className={`${styles.title} pl-2 col`}>{title}</span>
              )}
              <div className='pr-0'>
                <ChevronIcon
                  fillColor=' var(--color-light-blue)'
                  size='20px'
                  rotation={selected ? -90 : 90}
                />
              </div>
            </div>
            <div
              className={
                selected
                  ? `${styles.content} ${styles.show} mt-2 font-small`
                  : `${styles.content} mt-2`
              }
            >
              {typeof content === 'string' ? (
                <div
                  dangerouslySetInnerHTML={{ __html: content }}
                  className={`${styles.contentContainer} font-small`}
                />
              ) : React.isValidElement(content) ? (
                content
              ) : Array.isArray(content) ? (
                <div>
                  {content.map((item, i) => (
                    <li className={styles.listContainer} key={i}>
                      {typeof item === 'string' && (
                        <div>
                          <span className={styles.bullet}>&bull;</span>
                          {item}
                        </div>
                      )}
                    </li>
                  ))}
                </div>
              ) : null}
              {videoUrl && isVideoUrl(videoUrl) && (
                <div className={`${styles.contentContainer} font-small`}>
                  <iframe
                    width='100%'
                    height='315'
                    src={getEmbedUrl(videoUrl)}
                    frameBorder='0'
                    allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                    allowFullScreen
                  ></iframe>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Accordion;
