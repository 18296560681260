import React from 'react';

const DolarActivityIcon = ({ size, color, styles }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      version='1.0'
      width={size}
      height={size}
      viewBox='0 0 64.000000 50.000000'
      preserveAspectRatio='xMidYMid meet'
      style={styles}
    >
      <g
        transform='translate(0.000000,50.000000) scale(0.100000,-0.100000)'
        fill={color}
        stroke='none'
      >
        <path d='M212 474 c-189 -94 -189 -354 0 -448 209 -105 437 123 332 332 -63 127 -208 178 -332 116z m210 -64 c61 -41 83 -84 83 -160 0 -76 -22 -119 -83 -161 -51 -34 -153 -34 -204 0 -73 50 -105 143 -79 223 14 41 64 95 106 115 45 20 136 12 177 -17z' />
        <path d='M239 387 c-106 -71 -106 -203 0 -274 98 -66 241 15 241 137 0 122 -143 203 -241 137z m111 -47 c33 -34 38 -50 15 -50 -8 0 -15 4 -15 9 0 13 -27 21 -45 15 -8 -4 -15 -14 -15 -24 0 -19 19 -30 50 -30 20 0 40 -27 40 -54 0 -8 -13 -29 -30 -46 l-30 -31 -30 31 c-33 34 -38 50 -15 50 8 0 15 -4 15 -9 0 -13 27 -21 45 -15 8 4 15 14 15 24 0 19 -19 30 -50 30 -20 0 -40 27 -40 54 0 12 46 73 58 75 1 1 16 -13 32 -29z' />
      </g>
    </svg>
  );
};

export default DolarActivityIcon;
