import { useContext } from 'react';
import { WelletContext } from '../../context/WelletContext';
import { format, parseISO } from 'date-fns';
import { useSelector } from 'react-redux';
import Header from '../../components/Common/Header/Header';
import ContentApp from '../../components/ContentApp';
import Money from '../../components/Common/Money/Money';
import { useActivities } from '../../hooks/useActivities';
import { getLocale } from '../../helpers/dates';
import { Spinner } from '../../components/Common/Spinner/Spinner';

const CashierPayments = () => {
  const welletContext = useContext(WelletContext);
  const app = useSelector((state) => state.app);
  const locale = getLocale(app.language);

  const cashierPayments = useActivities({
    // solución provisoria, hay que agregar scroll infinito
    pageSize: 1000,
    endpoint: '/cashier/payments',
    api: welletContext.apis.tickets,
    isReservation: true,
  });

  return (
    <>
      <Header title={'Mis pagos'} hasBackground={true} showBack={true} />
      <ContentApp>
        <div className={`${cashierPayments.loading ? '' : 'card'} mt-2 py-2 px-3`}>
          {cashierPayments?.activities?.map((w, index) => (
            <div key={index}>
              <div className={`row text-white p-2`}>
                <div className='col'>
                  <div className=''>{w.affiliateUserName}</div>
                  <div className='font-small mb-2'>
                    <div className=''>
                      {format(parseISO(w.createdAt), "d 'de' LLLL HH:mm", {
                        locale,
                      })}
                    </div>
                  </div>
                </div>
                <div className='col-auto text-right mt-3'>
                  <Money value={w.amount} decimal={false} lowDecimals symbolSize='.85rem' />
                </div>
              </div>
              <div
                style={{ width: '94%' }}
                className={`${
                  index < cashierPayments.activities.length - 1
                    ? 'activity-item-border m-auto'
                    : 'display-none'
                }`}
              ></div>
            </div>
          ))}
        </div>
        {cashierPayments?.loading && (
          <div className='d-flex justify-content-center mt-3'>
            <Spinner size={25} color='#fff' />
          </div>
        )}
      </ContentApp>
    </>
  );
};

export default CashierPayments;
