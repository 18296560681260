import React from 'react';

const LogoutIcon = ({ size, color, styles }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      style={styles}
      viewBox='0 0 512.000000 512.000000'
      preserveAspectRatio='xMidYMid meet'
    >
      <g
        transform='translate(0.000000,512.000000) scale(0.100000,-0.100000)'
        fill={color}
        stroke='none'
      >
        <path
          d='M885 4471 c-90 -22 -172 -90 -215 -176 l-25 -50 0 -1685 0 -1685 23
           -45 c35 -72 75 -114 144 -151 l63 -34 632 0 c622 0 632 0 659 21 53 39 69 71
           69 134 0 63 -16 95 -69 134 -27 21 -39 21 -617 24 l-589 2 0 1600 0 1600 589
           2 c578 3 590 3 617 24 53 39 69 71 69 134 0 63 -16 95 -69 134 -27 21 -38 21
           -639 23 -337 0 -625 -2 -642 -6z'
        />
        <path
          d='M3415 3544 c-95 -49 -95 -51 -95 -468 l0 -356 -649 -2 c-638 -3 -650
           -3 -677 -24 -53 -39 -69 -71 -69 -134 0 -63 16 -95 69 -134 27 -21 39 -21 677
           -24 l649 -2 0 -356 c0 -394 1 -401 64 -449 41 -31 100 -42 147 -26 25 8 162
           139 486 463 396 397 452 458 458 491 17 92 31 75 -453 561 -254 254 -461 454
           -479 462 -42 18 -91 18 -128 -2z'
        />
      </g>
    </svg>
  );
};

export default LogoutIcon;
