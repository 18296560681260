import React from 'react';
import Header from '../../components/Common/Header/Header';
import RestaurantCard from '../../components/RestaurantCard/RestaurantCard';
import ContentApp from '../../components/ContentApp';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { setMarket } from '../../actions/appActions';

const MarketSelector = ({ history }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const markets = useSelector((state) => state.app.markets);

  const handleSelectMarket = (market) => {
    history.push(`/sign-up-terms?country=${market.countryCode}&marketId=${market.marketSetupId}`);
    dispatch(setMarket(market));
  };

  return (
    <>
      <Header pathName={'/'} title={t('marketSelector')} />
      <ContentApp>
        {markets.map((market, index) => (
          <RestaurantCard
            key={index}
            restaurant={market}
            onRestaurantClick={() => handleSelectMarket(market)}
            id={market.marketSetupId}
            name={market.countryName}
            logoUrl={`https://cdn1.wellet.mx/flags/round/${market.countryCode}.png`}
          />
        ))}
      </ContentApp>
    </>
  );
};

export default MarketSelector;
