import React, { useContext, useEffect, useState } from 'react';
import { WelletContext } from '../../context/WelletContext';
import { skeletonQR } from '../../components/Skeletons/SkeletonWithdrawal';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Header from '../../components/Common/Header/Header';
import ContentApp from '../../components/ContentApp';
import Money from '../../components/Common/Money/Money';
import styles from './withdrawal.module.css';
import SuccessScreen from '../Common/SuccessScreen';

const Withdrawal = ({ method }) => {
  const { t } = useTranslation();
  const welletContext = useContext(WelletContext);
  const [qrCode, setQrCode] = useState(null);
  const [loading, setLoading] = useState(true);
  const [amount, setAmount] = useState(null);
  const [error, setError] = useState(false); //todo: handle error messages
  const [secondsRemaining, setSecondsRemaining] = useState(null);
  const [status, setStatus] = useState(0);
  const history = useHistory();
  const methodType = method?.toLowerCase();

  const getQrCode = async () => {
    setLoading(true);
    try {
      let url = '';
      if (methodType === 'cash') {
        url = '/associates/GetCashPaymentToken';
      } else if (methodType === 'certificate') {
        url = '/associates/GetCertificatePaymentToken';
      } else {
        throw new Error('Invalid method type');
      }
      const result = await welletContext.apis.tickets.get(url);
      if (result.status !== 200) throw new Error('Error getting QR code');
      const qrEncoded = encodeURIComponent(result.data.token);
      setAmount(result.data.amount);
      setQrCode(qrEncoded);
      const expirationSeconds = result.data.expirationSeconds;
      setSecondsRemaining(expirationSeconds);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setError(true);
      setLoading(false);
    }
  };

  const getQrCodeStatus = async (token) => {
    try {
      const result = await welletContext.apis.tickets.get(
        `/associates/GetPaymentTokenStatus?token=${token}`,
      );
      if (result.status !== 200) {
        throw new Error('Error getting QR code status');
      } else {
        setStatus(result.data.status);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getQrCode();
  }, [method]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      getQrCodeStatus(qrCode);
    }, 10000);

    return () => clearInterval(intervalId);
  }, [qrCode]);

  useEffect(() => {
    if (secondsRemaining === 0) {
      history.push('/my-money');
    } else if (secondsRemaining > 0) {
      const timeoutId = setTimeout(() => {
        setSecondsRemaining((prevSeconds) => prevSeconds - 1);
      }, 1000);

      return () => clearTimeout(timeoutId);
    }
  }, [secondsRemaining, history]);

  return (
    <>
      {status === 'PAID' ? (
        <SuccessScreen
          title={`${t('withdrawalSuccess.title')}!`}
          subtitle={t('withdrawalSuccess.subtitle')}
          path={'/my-money'}
        />
      ) : (
        <>
          <Header title={t(`${methodType}Withdrawal`)} />
          <ContentApp>
            <div
              className={`card d-flex flex-column align-items-center justify-content-center p-4 mt-3 ${styles.container}`}
            >
              <div className='font-large bold text-center'>
                {!loading && (
                  <div className='fade-up'>
                    <Money value={amount} symbolSize='24px' lowDecimals decimal={false} />
                  </div>
                )}
                <div className='mt-4'>
                  <div className={styles.imgContainer}>
                    {loading
                      ? skeletonQR()
                      : !error && (
                          <div className='fade-up'>
                            <img
                              alt='QR Code'
                              src={`https://api.qrserver.com/v1/create-qr-code/?size=293x293&format=png&data=${qrCode}`}
                            />
                          </div>
                        )}
                  </div>
                </div>
              </div>
              <div className='font-normal mt-4 bold'>
                {secondsRemaining !== null && (
                  <span>
                    {Math.floor(secondsRemaining / 60)}:
                    {(secondsRemaining % 60).toString().padStart(2, '0')}
                  </span>
                )}
              </div>
            </div>
            <div className='pl-3'>
              <div className={`mt-4 font-large bold ${styles.title}`}>{t('showQr')}</div>
              <div className='text-secondary font-normal mt-3'>{t('showQrDescription')}</div>
            </div>
          </ContentApp>
        </>
      )}
    </>
  );
};

export default Withdrawal;
