import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react';
import './Slider.css';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import ChevronIcon from '../../icons/chevronIcon';

// Slide component (optional)
const Slide = forwardRef(({ children, slideSpacing, classNames }, ref) => (
  <div ref={ref} style={{ marginRight: slideSpacing }} className={classNames}>
    {children}
  </div>
));

// Slider component
const Slider = forwardRef(
  (
    { children, touchScroll, slideSpacing, showBtns = false, initialSlide, visibleSlides = 'all' },
    ref,
  ) => {
    const totalSlides = React.Children.count(children);
    const [currentSlide, setCurrentSlide] = useState(initialSlide);
    const containerRef = useRef(null);
    const slideRefs = useRef([]);
    const location = useLocation();
    useEffect(() => {
      setCurrentSlide(initialSlide);
    }, [initialSlide]);

    const handleSlideNext = () => {
      if (currentSlide === totalSlides - 2) {
        setCurrentSlide(currentSlide);
      } else {
        const nextSlide = (currentSlide + 1) % totalSlides;
        setCurrentSlide(nextSlide);
      }
    };

    const handleSlidePrev = () => {
      if (currentSlide === 0) {
        setCurrentSlide(0);
      } else {
        const prevSlide = (currentSlide - 1 + totalSlides) % totalSlides;
        setCurrentSlide(prevSlide);
      }
    };

    useImperativeHandle(ref, () => ({
      handleSlideNext,
      handleSlidePrev,
    }));

    const getVisibleSlides = () => {
      if (visibleSlides === 'all') {
        return React.Children.toArray(children);
      }
      return React.Children.toArray(children).slice(0, visibleSlides);
    };

    const renderSlides = () => {
      const slides = getVisibleSlides();
      const totalVisibleSlides = slides.length;
      const slidesPerRow = totalVisibleSlides > 8 && location.pathname === '/' ? 2 : 1;

      const slideRows = [];
      for (let i = 0; i < totalVisibleSlides; i += slidesPerRow) {
        const rowSlides = slides.slice(i, i + slidesPerRow);
        const row = (
          <div key={`row_${i}`} className='slide-row'>
            {rowSlides.map((child, index) => (
              <Slide
                key={index}
                ref={(slideRef) => (slideRefs.current[i + index] = slideRef)}
                slideSpacing={slideSpacing}
                classNames={i + index === currentSlide ? 'slide active slide-transition' : 'slide'}
              >
                {child}
              </Slide>
            ))}
          </div>
        );
        slideRows.push(row);
      }

      return slideRows;
    };

    const renderNavigationButtons = () => {
      if (!showBtns) return null;

      return (
        <div className='d-flex w-100 justify-content-center pt-4'>
          <div onClick={handleSlidePrev} className='btn-slider-prev'>
            <ChevronIcon fillColor='#fff' size={'26px'} />
          </div>
          <div onClick={handleSlideNext} className='btn-slider-next'>
            <ChevronIcon fillColor='#fff' size={'26px'} />
          </div>
        </div>
      );
    };

    useEffect(() => {
      if (containerRef.current) {
        const slideWidth = slideRefs.current[currentSlide]?.offsetWidth + slideSpacing; // Ancho de slide + el spaecing
        const maxOffset = (totalSlides - 2) * slideWidth;
        const newSlideOffset = Math.min(currentSlide * slideWidth, maxOffset);

        containerRef.current.scrollLeft = newSlideOffset;
      }
    }, [currentSlide, slideSpacing, totalSlides]);

    return (
      <>
        <div
          ref={containerRef}
          style={{
            display: 'flex',
            overflowX: touchScroll ? 'auto' : 'hidden',
            scrollBehavior: 'smooth', // Agrega esta línea para que se aplique la transición en el scroll
            overflowY: 'hidden',
          }}
        >
          <div style={{ display: 'flex' }}>{renderSlides()}</div>
        </div>
        {renderNavigationButtons()}
      </>
    );
  },
);

export default Slider;
