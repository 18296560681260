import React, { useRef, useEffect, useState } from 'react';
import { Camera } from 'react-camera-pro';
import './ScanFaceScreen.css';
import { useTranslation } from 'react-i18next';

const ScanFaceScreen = ({ onCaptureFace }) => {
  const { t } = useTranslation();
  const [showCamera, setShowCamera] = useState(true);
  const FRONT_CAMERA = 'user';
  const camera = useRef(null);

  const onClickCapture = async (img) => {
    onCaptureFace(img);
  };

  const errorMessages = {
    noCameraAccessible: 'Camera not accessible',
    permissionDenied: 'Permission denied',
    switchCamera: undefined,
    canvas: undefined,
  };

  // const handleCameraAccess = async () => {
  //   try {
  //     await navigator.mediaDevices.getUserMedia({ video: true });
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  // const requestCameraPermission = async () => {
  //   try {
  //     await navigator.mediaDevices.getUserMedia({ video: true });
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  useEffect(() => {
    // requestCameraPermission();
    // handleCameraAccess();

    return () => {
      setShowCamera(false);
    };
  }, []);

  return (
    <div className='text-center'>
      <div className='font-normal bold' style={{ color: '#fff' }}>
        {t('kyc.selfieTitle')}
      </div>
      <div className=' text-secondary mt-2'>{t('kyc.selfieDescription')}</div>
      <div className='mt-4'>
        <div
          className='camera-container'
          style={{ width: '100%', height: '315px', overflow: 'hidden' }}
        >
          {showCamera ? (
            <Camera errorMessages={errorMessages} ref={camera} facingMode={FRONT_CAMERA} />
          ) : null}
          <div className='face'></div>
        </div>
      </div>

      <div style={{ position: 'fixed', bottom: '5%', left: 0, right: 0 }}>
        <div className='text-white mb-4'>{t('kyc.selfieText')}</div>
        <button
          onClick={() => {
            camera.current && onClickCapture(camera.current.takePhoto());
          }}
          className='picture-button'
        ></button>
      </div>
    </div>
  );
};
export default ScanFaceScreen;
