import React from 'react';

const PaymentsInactiveIcon = ({ size = '25px', color = '#fff' }) => {
  return (
    <svg
      id='b'
      data-name='Capa 2'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 33.59 33.59'
      width={size}
      height={size}
    >
      <g id='c' data-name='Capa 1'>
        <g>
          <circle
            cx='16.8'
            cy='16.8'
            r='16.05'
            fill='none'
            stroke={color}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='1.5'
          />
          <g>
            <path
              d='M20.49,12.51s-.07-3.06-3.78-3.06c-4.63,0-4.9,7.34-.23,7.34h.63c4.67,0,4.4,7.34-.23,7.34-3.71,0-3.78-3.06-3.78-3.06'
              fill='none'
              stroke={color}
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth='1.5'
            />
            <line
              x1='16.71'
              y1='9.46'
              x2='16.71'
              y2='7.01'
              fill='none'
              stroke={color}
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth='1.5'
            />
            <line
              x1='16.71'
              y1='26.58'
              x2='16.71'
              y2='24.14'
              fill='none'
              stroke={color}
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth='1.5'
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default PaymentsInactiveIcon;
