import { useState, useEffect } from 'react';

export function useActivities({ pageSize = 5, endpoint, api, isReservation = false }) {
  const [loading, setLoading] = useState(true);
  const [activities, setActivities] = useState(null);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(false);

  const getActivities = async () => {
    setLoading(true);
    try {
      const skip = page * pageSize;
      const response = !isReservation
        ? await api.get(`${endpoint}/${pageSize}/${skip}`)
        : await api.get(`${endpoint}?limit=${pageSize}&skip=${skip}`);

      setActivities(response.data);

      setHasMore(response.data.length > 0);
    } catch (error) {
      console.error(error);
      //to do: show error in the UI
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setPage(0);
  }, []);

  useEffect(() => {
    getActivities();
  }, [page]);

  const loadMore = () => {
    setPage(page + 1);
  };

  return {
    activities,
    loading,
    hasMore,
    loadMore,
  };
}
