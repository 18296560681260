import React from 'react';

const StickerIcon = ({ size }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 38 38'
      fill='none'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0 11.5C0 5.14872 5.14873 0 11.5 0H26.5C32.8513 0 38 5.14873 38 11.5H35C35 6.80558 31.1944 3 26.5 3H11.5C6.80558 3 3 6.80558 3 11.5V26.5C3 31.1944 6.80558 35 11.5 35V38C5.14872 38 0 32.8513 0 26.5V11.5Z'
        fill='#000'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.5 14C12.5 15.3807 11.3807 16.5 10 16.5C8.61929 16.5 7.5 15.3807 7.5 14C7.5 12.6193 8.61929 11.5 10 11.5C11.3807 11.5 12.5 12.6193 12.5 14ZM27 16.5C28.3807 16.5 29.5 15.3807 29.5 14C29.5 12.6193 28.3807 11.5 27 11.5C25.6193 11.5 24.5 12.6193 24.5 14C24.5 15.3807 25.6193 16.5 27 16.5Z'
        fill='#000'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10 36.5C10 35.6716 10.6716 35 11.5 35H20C20.8284 35 21.5 35.6716 21.5 36.5C21.5 37.3284 20.8284 38 20 38H11.5C10.6716 38 10 37.3284 10 36.5Z'
        fill='#000'
      />
      <path d='M18.4283 33.5107L21.4012 33.5003L21.5 33.5V36.5H18.5L18.4283 33.5107Z' fill='#000' />
      <path d='M32.5818 18.5H37.5V21.5L32.4282 21.5107L32.5818 18.5Z' fill='#000' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M36.5 10C37.3284 10 38 10.6716 38 11.5V20.5C38 21.3284 37.3284 22 36.5 22C35.6716 22 35 21.3284 35 20.5V11.5C35 10.6716 35.6716 10 36.5 10Z'
        fill='#000'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M32.5818 18.5C31.2596 18.4839 29.5547 18.5792 27.9728 18.9413C25.1675 19.5834 22.2787 21.1142 20.424 24.3903C18.366 24.522 16.8882 24.2829 15.7512 23.8327C14.5146 23.3431 13.5584 22.5555 12.6476 21.4735C12.114 20.8397 11.1678 20.7584 10.534 21.292C9.90025 21.8255 9.819 22.7717 10.3525 23.4055C11.4825 24.7478 12.8222 25.8995 14.6468 26.622C15.9494 27.1378 17.4349 27.4094 19.1748 27.4255C18.6834 29.1511 18.4203 31.1653 18.4283 33.5107L21.4012 33.5003C20.5961 34.2022 19.8607 34.8238 19.2301 35.3339C18.586 35.8549 18.4863 36.7994 19.0073 37.4435C19.5283 38.0876 20.4728 38.1873 21.1169 37.6663C23.3275 35.878 26.6941 32.8449 29.8771 29.7657C33.042 26.7041 36.1208 23.5057 37.7 21.4001C38.1971 20.7373 38.0628 19.7971 37.4 19.3001C36.7373 18.803 35.7971 18.9373 35.3 19.6001C33.8792 21.4945 30.9581 24.546 27.7913 27.6095C25.6192 29.7107 23.3766 31.7743 21.4998 33.4142C21.4992 31.1045 21.7931 29.27 22.2685 27.8141C23.4961 24.0544 26.0273 22.5518 28.6551 21.9503C29.9227 21.6602 31.2518 21.4964 32.4282 21.5107L32.5818 18.5ZM19.1748 27.4255C19.8548 27.4318 20.5737 27.3991 21.3358 27.3267C22.1605 27.2483 22.7656 26.5162 22.6872 25.6915C22.6088 24.8668 21.8767 24.2618 21.052 24.3401C20.8368 24.3606 20.6276 24.3773 20.424 24.3903C20.0104 25.1209 19.6482 25.9384 19.3498 26.8521C19.2886 27.0396 19.2302 27.2307 19.1748 27.4255Z'
        fill='#000'
      />
      <path
        d='M22.6872 25.6915C22.6088 24.8668 21.8767 24.2618 21.052 24.3401C20.8368 24.3606 20.6276 24.3773 20.424 24.3903C20.0104 25.1209 19.6482 25.9384 19.3498 26.8521C19.2886 27.0396 19.2302 27.2307 19.1748 27.4255C19.8548 27.4318 20.5737 27.3991 21.3358 27.3267C22.1605 27.2483 22.7656 26.5162 22.6872 25.6915Z'
        fill='#000'
      />
    </svg>
  );
};

export default StickerIcon;
