import './reservation-type.css';

const ReservationTypeCard = ({ title, image, onHandleClick }) => {
  return (
    <div className='card-reservation-type' onClick={() => onHandleClick()}>
      <img src={image} alt={title} />
      {title ? <div className='card-title'>{title}</div> : null}
    </div>
  );
};

export default ReservationTypeCard;
