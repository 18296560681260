import React from 'react';
import { Skeleton } from '@mui/material';

export const SkeletonReservationDetail = () => {
  return (
    <div className='mt-2'>
      <Skeleton
        variant='rectangular'
        sx={{
          bgcolor: 'grey.900',
          width: '100%',
          borderRadius: '.4rem',
          height: 595,
        }}
      />
    </div>
  );
};
