import React from 'react';

const WhatsAppIcon = ({ size = '25px', color = '#fff' }) => {
  return (
    <svg
      version='1.0'
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 509.000000 509.000000'
      preserveAspectRatio='xMidYMid meet'
    >
      <g
        transform='translate(0.000000,509.000000) scale(0.100000,-0.100000)'
        fill={color}
        stroke='none'
      >
        <path
          d='M2395 5080 c-572 -51 -1101 -290 -1508 -680 -416 -398 -667 -895
-748 -1480 -18 -124 -18 -496 0 -620 46 -329 145 -629 298 -903 l38 -69 -219
-651 c-121 -359 -221 -657 -223 -663 -2 -6 303 87 679 208 l683 219 125 -60
c402 -191 824 -273 1245 -241 407 31 776 146 1109 348 623 379 1038 989 1168
1717 28 162 36 536 14 705 -63 477 -238 891 -530 1254 -402 499 -987 825
-1626 906 -116 15 -396 20 -505 10z m505 -406 c771 -111 1427 -661 1674 -1404
77 -230 106 -413 106 -660 0 -246 -29 -430 -105 -659 -243 -727 -868 -1265
-1627 -1396 -453 -79 -956 6 -1353 227 -49 28 -102 59 -116 69 -26 18 -27 18
-425 -110 -219 -70 -401 -127 -402 -125 -2 1 55 175 126 387 l130 383 -59 90
c-72 108 -176 317 -218 438 -418 1200 309 2484 1551 2740 229 47 481 54 718
20z'
        />
        <path
          d='M1727 3730 c-125 -32 -240 -162 -303 -345 -25 -72 -28 -93 -28 -215
0 -118 4 -146 28 -225 38 -125 108 -261 210 -407 284 -409 600 -705 924 -867
135 -67 368 -155 508 -191 201 -52 334 -41 504 42 64 31 103 59 156 112 62 62
75 80 92 138 25 80 43 205 35 238 -3 14 -11 31 -17 39 -14 18 -455 234 -524
256 -77 26 -103 10 -200 -122 -113 -153 -131 -168 -195 -168 -43 0 -73 10
-169 57 -266 128 -475 311 -648 565 -78 113 -94 147 -85 180 3 13 43 66 89
119 85 96 116 145 116 182 0 40 -221 556 -251 586 -26 28 -34 30 -116 32 -48
2 -105 -1 -126 -6z'
        />
      </g>
    </svg>
  );
};

export default WhatsAppIcon;
