import React from 'react';

const CheckIcon = ({ checkColor = '', circleColor = '#fff', size, styles }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 512 512'
    width={size}
    height={size}
    style={styles}
  >
    <circle cx='256' cy='256' r='248' fill={circleColor} />
    <path
      fill={checkColor}
      d='M227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z'
    ></path>
  </svg>
);

export default CheckIcon;
