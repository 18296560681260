import React from 'react';

const CalendarReservationIcon = ({ size, color, styles }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      version='1.0'
      width={size}
      height={size}
      viewBox='0 0 512.000000 512.000000'
      preserveAspectRatio='xMidYMid meet'
      style={styles}
    >
      <g
        transform='translate(0.000000,512.000000) scale(0.100000,-0.100000)'
        fill={color}
        stroke='none'
      >
        <path d='M1648 4898 c-21 -5 -58 -28 -82 -50 -60 -52 -76 -105 -76 -255 l0 -113 -98 0 c-275 0 -421 -49 -562 -190 -110 -110 -159 -213 -180 -380 -14 -116 -14 -2584 0 -2700 20 -160 70 -270 170 -370 99 -100 186 -150 320 -183 65 -16 2628 -22 2764 -7 172 20 279 70 391 185 106 108 155 213 175 375 14 116 14 2584 0 2700 -21 166 -70 270 -180 380 -140 140 -287 190 -562 190 l-98 0 0 111 c0 140 -12 187 -62 244 -98 112 -275 88 -342 -46 -24 -49 -26 -63 -26 -181 l0 -128 -640 0 -640 0 0 129 c0 121 -2 132 -28 182 -46 89 -149 134 -244 107z m2258 -859 c59 -15 118 -74 133 -133 6 -25 11 -97 11 -160 l0 -116 -1490 0 -1490 0 0 118 c0 189 29 249 139 288 49 18 2628 20 2697 3z m144 -1810 c0 -682 -3 -983 -11 -1015 -16 -64 -75 -120 -142 -134 -37 -8 -426 -10 -1367 -8 l-1315 3 -42 24 c-49 28 -77 64 -92 117 -8 27 -11 342 -11 1012 l0 972 1490 0 1490 0 0 -971z' />
        <path d='M1620 2754 c-167 -72 -165 -318 2 -389 139 -57 298 47 298 195 0 149 -162 254 -300 194z' />
        <path d='M2460 2748 c-69 -37 -104 -94 -108 -176 -3 -56 0 -74 20 -112 37 -69 94 -104 176 -108 56 -3 74 0 112 20 69 37 104 94 108 176 3 56 0 74 -20 112 -37 69 -94 104 -176 108 -56 3 -74 0 -112 -20z' />
        <path d='M3325 2753 c-164 -86 -166 -300 -3 -384 45 -23 125 -25 176 -4 169 71 169 319 0 390 -48 19 -134 19 -173 -2z' />
        <path d='M1611 1894 c-130 -66 -157 -236 -53 -337 106 -103 272 -75 337 57 24 49 27 65 22 112 -16 152 -171 237 -306 168z' />
        <path d='M2480 1904 c-78 -34 -130 -111 -130 -194 0 -125 88 -214 210 -214 122 0 210 89 210 214 0 147 -157 252 -290 194z' />
        <path d='M3342 1909 c-19 -6 -53 -30 -77 -53 -70 -70 -83 -155 -39 -245 85 -169 336 -147 390 34 49 166 -103 313 -274 264z' />
      </g>
    </svg>
  );
};

export default CalendarReservationIcon;
