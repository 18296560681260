import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './occasionSelector.module.css';

const OccasionSelector = ({ handleSelectButton, selectedButton }) => {
  const { t } = useTranslation();
  const types = [
    {
      value: 'BIRTHDAY',
      label: t('typeOccasions.BIRTHDAY'),
    },
    {
      value: 'ANNIVERSARY',
      label: t('typeOccasions.ANNIVERSARY'),
    },
    {
      value: 'ROMANTIC_DINNER',
      label: t('typeOccasions.ROMANTIC_DINNER'),
    },
    {
      value: 'BUSSINES_MEAL',
      label: t('typeOccasions.BUSSINES_MEAL'),
    },
    {
      value: 'SPECIAL_EVENT',
      label: t('typeOccasions.SPECIAL_EVENT'),
    },
  ];

  return (
    <>
      <div className='bold'>{t('whatOccasion')}</div>
      <div className='row m-0 mt-3'>
        {types.map((p) => (
          <div className='col-auto pl-0 pr-1 mb-1' key={p.value}>
            <button
              onClick={() => handleSelectButton(p.value)}
              className={`d-flex align-items-center fade-up ${styles.occasionButton} ${
                selectedButton === p.value ? styles.selected : null
              }`}
              type='button'
            >
              {p.label}
            </button>
          </div>
        ))}
      </div>
    </>
  );
};

export default OccasionSelector;
