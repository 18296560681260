import React from 'react';

const CalendarInactiveIcon = ({ size = '25px', color = '#fff' }) => {
  return (
    <svg
      id='b'
      data-name='Capa 2'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 36.3 36.78'
      width={size}
      height={size}
    >
      <g id='c' data-name='Capa 1'>
        <g>
          <rect
            x='.75'
            y='3.15'
            width='34.8'
            height='32.88'
            rx='3.9'
            ry='3.9'
            fill='none'
            stroke={color}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='1.5'
          />
          <line
            x1='.75'
            y1='10.95'
            x2='35.55'
            y2='10.95'
            fill='none'
            stroke={color}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='1.5'
          />
          <line
            x1='7.65'
            y1='.75'
            x2='7.65'
            y2='6.87'
            fill='none'
            stroke={color}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='1.5'
          />
          <line
            x1='28.44'
            y1='.75'
            x2='28.44'
            y2='6.87'
            fill='none'
            stroke={color}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='1.5'
          />
          <line
            x1='21.24'
            y1='.75'
            x2='21.24'
            y2='6.87'
            fill='none'
            stroke={color}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='1.5'
          />
          <line
            x1='14.44'
            y1='.75'
            x2='14.44'
            y2='6.87'
            fill='none'
            stroke={color}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='1.5'
          />
          <rect
            x='5.29'
            y='16.24'
            width='4.78'
            height='4.78'
            fill='none'
            stroke={color}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='1.5'
          />
          <rect
            x='5.29'
            y='25.42'
            width='4.78'
            height='4.78'
            fill='none'
            stroke={color}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='1.5'
          />
          <rect
            x='26.01'
            y='16.24'
            width='4.78'
            height='4.78'
            fill='none'
            stroke={color}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='1.5'
          />
          <rect
            x='26.01'
            y='25.42'
            width='4.78'
            height='4.78'
            fill='none'
            stroke={color}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='1.5'
          />
          <rect
            x='15.65'
            y='25.42'
            width='4.78'
            height='4.78'
            fill='none'
            stroke={color}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='1.5'
          />
          <polyline
            points='16.13 18.75 17.74 20.39 20.92 16.75'
            fill='none'
            stroke={color}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='1.5'
          />
        </g>
      </g>
    </svg>
  );
};

export default CalendarInactiveIcon;
