import React from 'react';

const ActivateConcierge = ({ size, color }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 21 19'
      fill={color}
    >
      <path
        d='M8.77615 7.7011C10.7631 7.7011 12.3738 6.09035 12.3738 4.10342C12.3738 2.11647 10.7631 0.505737 8.77615 0.505737C6.7892 0.505737 5.17847 2.11647 5.17847 4.10342C5.17847 6.09035 6.7892 7.7011 8.77615 7.7011Z'
        stroke='black'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.8032 8.10059C11.9346 8.10059 9.60645 10.4287 9.60645 13.2973C9.60645 16.166 11.9346 18.4941 14.8032 18.4941C17.6719 18.4941 20 16.166 20 13.2973C20 10.4287 17.6719 8.10059 14.8032 8.10059Z'
        stroke='black'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.346 9.50004C10.387 9.15222 9.48187 8.98608 8.68252 8.98608C5.80825 8.98608 2.24129 11.099 1.13757 16.3001H8.68252H10.575'
        stroke='black'
        strokeLinejoin='round'
      />
      <path
        d='M12.1085 13.9229L14.2362 15.5686L17.9597 11.1802'
        stroke='black'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default ActivateConcierge;
