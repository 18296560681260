import React from 'react';

const QrIcon = ({ size, color }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={size}
    height={size}
    viewBox='0 0 32 32'
    version='1.1'
  >
    <g id='surface1' fill={color}>
      <path d='M 5.949219 4.3125 C 5.320312 4.445312 4.75 4.914062 4.445312 5.554688 L 4.28125 5.90625 L 4.28125 11.15625 L 4.488281 11.574219 C 4.726562 12.054688 5.0625 12.386719 5.554688 12.617188 L 5.90625 12.78125 L 11.15625 12.78125 L 11.574219 12.574219 C 12.054688 12.335938 12.386719 12 12.617188 11.507812 L 12.78125 11.15625 L 12.78125 5.90625 L 12.617188 5.554688 C 12.386719 5.0625 12.054688 4.726562 11.574219 4.488281 L 11.15625 4.28125 L 8.6875 4.269531 C 7.332031 4.261719 6.101562 4.289062 5.949219 4.3125 Z M 10.65625 8.53125 L 10.65625 10.65625 L 6.40625 10.65625 L 6.386719 8.59375 C 6.382812 7.460938 6.386719 6.492188 6.40625 6.449219 C 6.425781 6.386719 6.882812 6.375 8.542969 6.386719 L 10.65625 6.40625 Z M 10.65625 8.53125 ' />
      <path d='M 7.46875 7.519531 C 7.449219 7.5625 7.445312 8.050781 7.449219 8.59375 L 7.46875 9.59375 L 9.59375 9.59375 L 9.59375 7.46875 L 8.542969 7.449219 C 7.726562 7.4375 7.488281 7.449219 7.46875 7.519531 Z M 7.46875 7.519531 ' />
      <path d='M 20.804688 4.332031 C 20.164062 4.511719 19.675781 4.929688 19.382812 5.5625 L 19.21875 5.90625 L 19.21875 11.15625 L 19.425781 11.574219 C 19.664062 12.054688 20 12.386719 20.492188 12.617188 L 20.84375 12.78125 L 26.09375 12.78125 L 26.511719 12.574219 C 26.992188 12.335938 27.324219 12 27.554688 11.507812 L 27.71875 11.15625 L 27.71875 5.90625 L 27.554688 5.554688 C 27.324219 5.0625 26.992188 4.726562 26.511719 4.488281 L 26.09375 4.28125 L 23.59375 4.269531 C 21.738281 4.257812 21.019531 4.273438 20.804688 4.332031 Z M 25.59375 8.53125 L 25.59375 10.65625 L 21.34375 10.65625 L 21.324219 8.59375 C 21.320312 7.460938 21.324219 6.492188 21.34375 6.449219 C 21.363281 6.386719 21.820312 6.375 23.480469 6.386719 L 25.59375 6.40625 Z M 25.59375 8.53125 ' />
      <path d='M 22.40625 7.519531 C 22.386719 7.5625 22.382812 8.050781 22.386719 8.59375 L 22.40625 9.59375 L 24.53125 9.59375 L 24.53125 7.46875 L 23.480469 7.449219 C 22.664062 7.4375 22.425781 7.449219 22.40625 7.519531 Z M 22.40625 7.519531 ' />
      <path d='M 14.9375 7.46875 L 14.9375 8.5625 L 17.0625 8.5625 L 17.0625 6.375 L 14.9375 6.375 Z M 14.9375 7.46875 ' />
      <path d='M 14.9375 11.75 L 14.9375 12.8125 L 17.0625 12.8125 L 17.0625 10.6875 L 14.9375 10.6875 Z M 14.9375 11.75 ' />
      <path d='M 6.375 16 L 6.375 17.0625 L 8.5625 17.0625 L 8.5625 14.9375 L 6.375 14.9375 Z M 6.375 16 ' />
      <path d='M 10.6875 16 L 10.6875 17.0625 L 12.8125 17.0625 L 12.8125 14.9375 L 10.6875 14.9375 Z M 10.6875 16 ' />
      <path d='M 14.9375 16 L 14.9375 17.0625 L 17.0625 17.0625 L 17.0625 19.1875 L 14.9375 19.1875 L 14.9375 21.3125 L 17.0625 21.3125 L 17.0625 23.4375 L 14.9375 23.4375 L 14.9375 25.625 L 17.0625 25.625 L 17.0625 27.75 L 19.1875 27.75 L 19.1875 25.625 L 21.3125 25.625 L 21.324219 26.667969 L 21.34375 27.71875 L 22.394531 27.738281 L 23.4375 27.75 L 23.4375 25.625 L 21.3125 25.625 L 21.3125 23.4375 L 23.4375 23.4375 L 23.4375 21.3125 L 25.625 21.3125 L 25.625 23.4375 L 27.75 23.4375 L 27.738281 22.386719 L 27.71875 21.34375 L 26.675781 21.324219 L 25.625 21.3125 L 25.625 19.1875 L 27.75 19.1875 L 27.75 17.0625 L 25.625 17.0625 L 25.625 14.9375 L 23.4375 14.9375 L 23.4375 17.0625 L 21.3125 17.0625 L 21.3125 14.9375 L 19.1875 14.9375 L 19.1875 17.0625 L 17.0625 17.0625 L 17.0625 14.9375 L 14.9375 14.9375 Z M 21.3125 18.125 L 21.3125 19.1875 L 23.4375 19.1875 L 23.4375 17.0625 L 25.625 17.0625 L 25.625 19.1875 L 24.550781 19.199219 L 23.46875 19.21875 L 23.449219 20.261719 L 23.4375 21.3125 L 21.3125 21.3125 L 21.3125 23.4375 L 20.269531 23.449219 L 19.21875 23.46875 L 19.199219 24.550781 L 19.1875 25.625 L 17.0625 25.625 L 17.0625 23.4375 L 19.1875 23.4375 L 19.1875 21.3125 L 17.0625 21.3125 L 17.0625 19.1875 L 19.1875 19.1875 L 19.1875 17.0625 L 21.3125 17.0625 Z M 21.3125 18.125 ' />
      <path d='M 19.1875 20.25 L 19.1875 21.3125 L 21.3125 21.3125 L 21.3125 19.1875 L 19.1875 19.1875 Z M 19.1875 20.25 ' />
      <path d='M 5.867188 19.269531 C 5.226562 19.449219 4.738281 19.867188 4.445312 20.5 L 4.28125 20.84375 L 4.28125 26.09375 L 4.488281 26.511719 C 4.726562 26.992188 5.0625 27.324219 5.554688 27.554688 L 5.90625 27.71875 L 11.15625 27.71875 L 11.574219 27.511719 C 12.054688 27.273438 12.386719 26.9375 12.617188 26.445312 L 12.78125 26.09375 L 12.78125 20.84375 L 12.617188 20.492188 C 12.386719 20 12.054688 19.664062 11.574219 19.425781 L 11.15625 19.21875 L 8.65625 19.207031 C 6.804688 19.195312 6.082031 19.210938 5.867188 19.269531 Z M 10.65625 23.46875 L 10.65625 25.59375 L 6.40625 25.59375 L 6.386719 23.53125 C 6.382812 22.398438 6.386719 21.429688 6.40625 21.386719 C 6.425781 21.324219 6.882812 21.3125 8.542969 21.324219 L 10.65625 21.34375 Z M 10.65625 23.46875 ' />
      <path d='M 7.46875 22.457031 C 7.449219 22.5 7.445312 22.988281 7.449219 23.53125 L 7.46875 24.53125 L 9.59375 24.53125 L 9.59375 22.40625 L 8.542969 22.386719 C 7.726562 22.375 7.488281 22.386719 7.46875 22.457031 Z M 7.46875 22.457031 ' />
      <path d='M 23.46875 23.519531 C 23.449219 23.5625 23.445312 24.050781 23.449219 24.59375 L 23.46875 25.59375 L 25.59375 25.59375 L 25.59375 23.46875 L 24.542969 23.449219 C 23.726562 23.4375 23.488281 23.449219 23.46875 23.519531 Z M 23.46875 23.519531 ' />
    </g>
  </svg>
);

QrIcon.defaultProps = {
  size: '32px',
  color: '#000',
};

export default QrIcon;
