import React from 'react';

const AccountFilterIcon = ({ size = '20px', color = '#FFF', styles }) => {
  return (
    <svg
      version='1.0'
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 68.000000 68.000000'
      preserveAspectRatio='xMidYMid meet'
      style={styles}
    >
      <g
        transform='translate(0.000000,68.000000) scale(0.100000,-0.100000)'
        fill={color}
        stroke='none'
      >
        <path
          d='M369 640 l-38 -39 -163 -3 c-137 -3 -163 -5 -163 -18 0 -13 27 -15
173 -18 139 -2 172 -5 172 -16 0 -24 59 -66 92 -66 23 0 41 10 69 40 35 36 41
39 101 42 48 2 63 7 63 18 0 12 -15 16 -66 18 -59 3 -68 6 -80 28 -16 31 -58
54 -95 54 -19 0 -39 -13 -65 -40z m111 -20 c24 -24 25 -38 4 -68 -17 -24 -62
-30 -82 -10 -20 20 -14 65 10 82 30 21 44 20 68 -4z'
        />
        <path
          d='M190 425 c-14 -7 -32 -25 -39 -39 -12 -22 -21 -25 -80 -28 -51 -2
-66 -6 -66 -18 0 -12 15 -16 66 -18 59 -3 68 -6 80 -28 16 -31 58 -54 95 -54
19 0 39 13 65 40 l38 39 163 3 c137 3 163 5 163 18 0 13 -26 15 -163 18 l-163
3 -38 39 c-41 44 -75 51 -121 25z m88 -47 c15 -15 15 -61 0 -76 -7 -7 -26 -12
-43 -12 -24 0 -35 6 -43 25 -18 39 3 75 43 75 17 0 36 -5 43 -12z'
        />
        <path
          d='M381 174 c-17 -14 -31 -32 -31 -40 0 -11 -33 -14 -172 -16 -146 -3
-173 -5 -173 -18 0 -13 26 -15 163 -18 l163 -3 38 -39 c26 -27 46 -40 65 -40
37 0 79 23 95 54 12 22 21 25 80 28 51 2 66 6 66 18 0 11 -15 16 -63 18 -60 3
-66 6 -101 42 -46 49 -84 53 -130 14z m105 -49 c32 -50 -22 -103 -72 -70 -26
17 -33 62 -12 83 21 21 66 14 84 -13z'
        />
      </g>
    </svg>
  );
};

export default AccountFilterIcon;
