import React, { useCallback, useRef } from 'react';
import styles from './activity.module.css';

const Activity = ({
  title,
  subtitle,
  item1,
  item2,
  item3,
  onClick,
  infiniteScroll = false,
  loading,
  loadMore,
  hasMore,
  className,
}) => {
  const observer = useRef();

  const onClickHandle = (item) => {
    if (onClick) {
      onClick(item);
    }
  };

  const lastElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          loadMore();
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore],
  );

  return (
    <div className={`${styles.activityFeed} px-2`}>
      <div
        className={`${className} py-2`}
        onClick={onClickHandle}
        ref={infiniteScroll ? lastElementRef : null}
      >
        <div className='row'>
          {item1 && (
            <div className={`col-auto my-auto pr-3 ${styles.item1}`} style={{ minWidth: '72px' }}>
              {item1}
            </div>
          )}
          <div className={`${item1 ? 'col my-auto pl-0' : 'col'} `}>
            {title}
            <div className={styles.subtitle}>{subtitle}</div>
          </div>
          {item3 ? (
            <div className='col-auto my-auto'>
              <div className={styles.item2}>{item2}</div>
              {item3}
            </div>
          ) : (
            <div className='col-auto my-auto pl-0'>{item2}</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Activity;
