import React from 'react';

const MembershipInactiveIcon = ({ size = '25px', color = '#fff' }) => {
  return (
    <svg
      id='b'
      data-name='Capa 2'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 35.49 37.04'
      width={size}
      height={size}
    >
      <g id='c' data-name='Capa 1'>
        <g>
          <path
            d='M19.28,1.5l2.02,2.56c.49.62,1.31.89,2.07.67l3.14-.89c1.22-.34,2.44.54,2.49,1.81l.13,3.26c.03.79.54,1.49,1.28,1.76l3.06,1.13c1.19.44,1.65,1.87.95,2.92l-1.81,2.71c-.44.66-.44,1.52,0,2.18l1.81,2.71c.7,1.05.24,2.49-.95,2.92l-3.06,1.13c-.74.27-1.25.97-1.28,1.76l-.13,3.26c-.05,1.27-1.27,2.15-2.49,1.81l-3.14-.89c-.76-.22-1.58.05-2.07.67l-2.02,2.56c-.78,1-2.29,1-3.07,0l-2.02-2.56c-.49-.62-1.31-.89-2.07-.67l-3.14.89c-1.22.34-2.44-.54-2.49-1.81l-.13-3.26c-.03-.79-.54-1.49-1.28-1.76l-3.06-1.13c-1.19-.44-1.65-1.87-.95-2.92l1.81-2.71c.44-.66.44-1.52,0-2.18l-1.81-2.71c-.7-1.05-.24-2.49.95-2.92l3.06-1.13c.74-.27,1.25-.97,1.28-1.76l.13-3.26c.05-1.27,1.27-2.15,2.49-1.81l3.14.89c.76.22,1.58-.05,2.07-.67l2.02-2.56c.78-1,2.29-1,3.07,0Z'
            fill='none'
            stroke={color}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='1.5'
          />
          <line
            x1='10.29'
            y1='25.97'
            x2='25.2'
            y2='11.07'
            fill='none'
            stroke={color}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='1.5'
          />
          <g>
            <circle
              cx='13.58'
              cy='12.91'
              r='2.86'
              fill='none'
              stroke={color}
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth='1.5'
            />
            <circle
              cx='21.91'
              cy='24.13'
              r='2.86'
              fill='none'
              stroke={color}
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth='1.5'
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default MembershipInactiveIcon;
