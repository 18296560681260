import { useEffect, useState } from 'react';
import Select, { components } from 'react-select';
import { useTranslation } from 'react-i18next';
import SolidChevron from '../../icons/SolidChevron';

const LanguageSelector = ({ lang, handleChange }) => {
  const { t } = useTranslation();
  const [selectedOption, setSelectedOption] = useState(null);

  const options = [
    {
      value: 'en',
      label: t('languages.english'),
    },
    {
      value: 'es',
      label: t('languages.spanish'),
    },
  ];

  useEffect(() => {
    if (selectedOption === null) {
      setSelectedOption(options.find((o) => o.value === lang));
    }
  }, []);

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props} className='p-0'>
        <SolidChevron size={15} color='var(--color-primary)' />
      </components.DropdownIndicator>
    );
  };

  const handleChangeLanguage = (language) => {
    setSelectedOption(language);
    if (handleChange) handleChange(language.value);
  };

  const selectorStyles = {
    control: (provided) => ({
      ...provided,
      paddingLeft: '1rem',
      paddingRight: '1rem',
      boxShadow: 'none',
      backgroundColor: '#000',
      border: 'none',
    }),
    option: (provided) => ({
      ...provided,
      backgroundColor: '#fff',
      color: '#000',
    }),
    valueContainer: (provided) => ({
      ...provided,
      boxShadow: 'none',
      padding: '0',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#fff',
      fontWeight: '500',
      margin: '0',
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#fff',
      fontWeight: '500',
    }),
    menu: (provided) => ({
      ...provided,
      color: '#000',
    }),
  };

  return (
    <Select
      key={selectedOption?.value}
      name='language'
      className={`w-100 hide-indicator-separator`}
      components={{ DropdownIndicator }}
      isSearchable={false}
      styles={selectorStyles}
      options={options}
      onChange={(e) => handleChangeLanguage(e)}
      placeholder={t(selectedOption?.label)}
      value={selectedOption}
    />
  );
};

export default LanguageSelector;
