import React from 'react';

const HomeInactiveIcon = ({ size = '25px', color = '#fff' }) => {
  return (
    <svg
      id='b'
      data-name='Capa 2'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 31.25 31.78'
      width={size}
      height={size}
    >
      <g id='c' data-name='Capa 1'>
        <g>
          <path
            d='M26.13,31.03H5.12c-2.41,0-4.37-1.96-4.37-4.37v-12.65c0-1.36.6-2.64,1.64-3.52L12.7,1.82c1.69-1.42,4.16-1.42,5.85,0l10.31,8.67c1.04.87,1.64,2.16,1.64,3.52v12.65c0,2.41-1.96,4.37-4.37,4.37Z'
            fill='none'
            stroke={color}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='1.5'
          />
          <line
            x1='11.13'
            y1='25.77'
            x2='20.11'
            y2='25.77'
            fill='none'
            stroke={color}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='1.5'
          />
        </g>
      </g>
    </svg>
  );
};

export default HomeInactiveIcon;
