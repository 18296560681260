import { parse, eachMinuteOfInterval } from 'date-fns';

export function generateTimeIntervals(start, end) {
  const startDate = parse(start, 'h:mm a', new Date());
  const endDate = parse(end, 'h:mm a', new Date());

  const adjustedEndDate =
    endDate < startDate ? new Date(endDate.getTime() + 24 * 60 * 60 * 1000) : endDate;

  const intervals = eachMinuteOfInterval({ start: startDate, end: adjustedEndDate }, { step: 30 });

  return intervals.map((timestamp) => new Date(timestamp));
}

export const formatIntervals = (filteredIntervals) =>
  filteredIntervals.map((i) => {
    const date = new Date(i.time);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const formattedHour = `${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
    return { time: formattedHour, soldOut: i.soldOut };
  });

export function generateIntervals(schedule, selectedOption, setIntervals, setLoadingSchedule) {
  const allIntervals = schedule?.reduce(
    (intervals, entry) => [...intervals, ...generateTimeIntervals(entry.timeStart, entry.timeEnd)],
    [],
  );
  const filteredIntervals = allIntervals.filter(
    (i) => i >= new Date(selectedOption.timeStart) && i <= new Date(selectedOption.timeEnd),
  );
  const formattedIntervals = filteredIntervals.map((fechaString) => {
    const fecha = new Date(fechaString);
    const horas = fecha.getHours();
    const minutos = fecha.getMinutes();
    const horaFormateada = `${horas}:${minutos < 10 ? '0' : ''}${minutos}`;
    return horaFormateada;
  });
  setIntervals(formattedIntervals);
  setLoadingSchedule(false);
}

export function getCurrentOccasion(occasions, currentTime) {
  return parse(currentTime, 'H:mm', new Date()).getHours() >= 6 &&
    parse(currentTime, 'H:mm', new Date()).getHours() <= 10
    ? occasions.filter((o) => o.value === 'breakfast')
    : parse(currentTime, 'H:mm', new Date()).getHours() >= 10 &&
      parse(currentTime, 'H:mm', new Date()).getHours() <= 16
    ? occasions.filter((o) => o.value === 'launch')
    : occasions.filter((o) => o.value === 'dinner');
}

export function reservationsType(t) {
  return [
    {
      value: 'consumptionTable',
      label: t('consumptionTable'),
      type: 'RESTAURANT',
    },
    {
      value: 'VIPareas',
      label: t('reservationarea'),
      type: 'AREA',
    },
  ];
}
