import React from 'react';

const EditIcon = ({ size, color, styles, handleClick }) => {
  return (
    <svg
      onClick={handleClick}
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 20 20'
      fill={color}
      style={styles}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0 19.0001C0 18.4478 0.447715 18.0001 1 18.0001H19C19.5523 18.0001 20 18.4478 20 19.0001C20 19.5524 19.5523 20.0001 19 20.0001H1C0.447715 20.0001 0 19.5524 0 19.0001Z'
        fill='#D9D9D9'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.3473 0.00012209C13.6127 6.92804e-05 13.8672 0.105486 14.0548 0.29316L17.7073 3.94716C18.0976 4.33765 18.0976 4.97058 17.7073 5.36107L7.36576 15.7071C7.17821 15.8947 6.92379 16.0001 6.6585 16.0001H3C2.44772 16.0001 2 15.5524 2 15.0001V11.3601C2 11.0951 2.10519 10.8409 2.29247 10.6534L12.64 0.293442C12.8275 0.105694 13.0819 0.000174959 13.3473 0.00012209ZM13.3478 2.41491L4 11.774V14.0001H6.24416L15.5861 4.65413L13.3478 2.41491Z'
        fill='#D9D9D9'
      />
    </svg>
  );
};

export default EditIcon;
