import React, { useState } from 'react';
import styles from './InputFormNew.module.css';
import { EyeIcon } from '../../icons/EyeIcon';
import { EyeHideIcon } from '../../icons/EyeHideIcon';

const InputFormNew = ({
  value,
  onChange,
  name,
  type,
  inputmode,
  error,
  style,
  customClass = '',
  isDisabled = false,
  maxLength,
  textAlignment,
  fontSize,
  classInput = '',
  normalPlaceholder,
  placeholder,
  forceLowecase = false,
  border,
}) => {
  const className = `position-relative ${styles.field} ${error ? 'error' : ''}`;
  const [forceToShow, setForceToShow] = useState(false);

  const handleInputChange = (e) => {
    if (maxLength && e.target.value.length > maxLength) {
      e.target.value = e.target.value.slice(0, maxLength);
    }
    onChange(e);
  };

  const inputStyle = {
    ...style,
    textAlign: textAlignment || 'left',
    fontSize: fontSize || 'inherit',
    border: border,
  };

  return (
    <div className={`${className} ${customClass} `}>
      <input
        name={name}
        type={forceToShow ? 'text' : type}
        inputMode={inputmode}
        value={forceLowecase ? value?.toLowerCase() : value}
        onChange={handleInputChange}
        className={classInput}
        style={inputStyle}
        disabled={isDisabled}
        maxLength={maxLength}
        placeholder={normalPlaceholder}

        //required
      />
      {type === 'password' ? (
        !forceToShow ? (
          <span onClick={() => setForceToShow(true)} className={styles.reveal}>
            <EyeHideIcon color={'#fff'} size={'21px'} />
          </span>
        ) : (
          <span onClick={() => setForceToShow(false)} className={styles.reveal}>
            <EyeIcon color={'#fff'} size={'21px'} />
          </span>
        )
      ) : null}
      {placeholder && (
        <span
          className={`${styles.floatingPlaceholder} ${classInput}`}
          style={{ color: '#7e7e7e', fontWeight: 'bold' }}
          dangerouslySetInnerHTML={{ __html: placeholder }}
        />
      )}

      {error && error.length > 0 && (
        <div className={`${styles.errorLabel}`} style={{ color: '#fff', width: '100%' }}>
          <span></span> {error}
        </div>
      )}
    </div>
  );
};

export default InputFormNew;
